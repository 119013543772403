import { useQuery } from "@tanstack/react-query";

import { ErrorResponse } from "../../custom-fetch";
import { QueryKey } from "../../services/query-keys";
import ErrorsAlert from "../ErrorsAlert/ErrorsAlert";
import Spinner from "../ui/Spinner/Spinner";
import { useMeshService } from "../../services/mesh-service";
import IMesh from "../../shared/IMesh";
import { useState } from "react";
import Input, { EInputType } from "../ui/Input/Input";
import MeshTable from "./MeshTable";

const MeshList: React.FC = () => {
  const { fetchMeshes } = useMeshService();
  const [filterValue, setFilterValue] = useState<string>('');

  const {
    data: meshes,
    isPending,
    isError,
    error,
  } = useQuery<IMesh[], ErrorResponse>({
    queryKey: [QueryKey.meshes],
    queryFn: fetchMeshes,
    staleTime: 5000,
  });

  if (isError) {
    return <ErrorsAlert errors={error.messages} />;
  }

  if (isPending) {
    return <Spinner />;
  }

  if (!meshes || meshes.length === 0) {
    return <p>Ei verkkoja</p>;
  }

  const filteredMeshes = meshes.filter(
    (mesh) =>
      mesh.unitName?.toLowerCase().includes(filterValue) ||
      mesh.type?.toLowerCase().includes(filterValue)
  );

  return (
    <>
    <Input
      inputName="meshesFilter"
      type={EInputType.text}
      placeholder="Suodata tunnuksella tai tyypillä"
      value={filterValue}
      onChange={(value) => setFilterValue(value.toString().toLowerCase())}
      autoFocus={true}
    />
    <MeshTable meshes={filteredMeshes}/>
    </>
  );
};

export default MeshList;
