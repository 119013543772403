import Container from "../components/ui/Container/Container";
import Button, { EButtonSize } from "../components/ui/Button/Button";
import { faFile, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { Route } from "../routes";
import ConcreteTypesList from "../components/ConcreteTypes/ConcreteTypesList";

const ConcreteTypesPage: React.FC = () => {
  const navigate = useNavigate();
  return (
    <>
      <h1
        style={{
          margin: "1rem",
          display: "flex",
          gap: "1rem",
          alignItems: "center",
        }}
      >
        Massat {" "}
        <Button
          size={EButtonSize.SMALL}
          onClick={() => navigate(Route.concreteType("add"))}
          icon={faPlus}
          style={{ fontSize: "initial" }}
        >
          Lisää uusi
        </Button>
        <Button
          size={EButtonSize.SMALL}
          onClick={() => navigate(Route.concreteTypesImport)}
          icon={faFile}
          style={{ fontSize: "initial" }}
        >
          Päivitä tiedostosta
        </Button>
      </h1>
      <Container>
        <ConcreteTypesList />
      </Container>
    </>
  );
};

export default ConcreteTypesPage;
