import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMutation } from "@tanstack/react-query";
import { useCallback, useId, useState } from "react";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import { ErrorResponse } from "../../custom-fetch";
import { useDragAndDrop } from "../../hooks/useDragAndDrop";
import { useProductionLinesService } from "../../services/productionLines-service";
import IProductionLine from "../../shared/IProductionLine";
import { formatDate } from "../../utils/date-utils";
import ErrorsAlert from "../ErrorsAlert/ErrorsAlert";
import Table from "../ui/Table/Table";
import Spinner from "../ui/Spinner/Spinner";

interface IProps {
  productionLines: IProductionLine[];
  showTotalRow?: boolean
}

const ProductionLinesDragAndDropTable: React.FC<IProps> = ({ productionLines: initialProductionLines, showTotalRow }) => {
  const [productionLines, setProductionLines] = useState<IProductionLine[]>(initialProductionLines);
  const { dragEndHandler, dragUpdateHandler, dragStartHandler, items, getItemStyle, getListStyle } = useDragAndDrop(productionLines, setProductionLines);

  const { reorderMutate, isReordering, isReorderError, reorderError } = useReorder();

  const updateHandler = useCallback(async (result: DropResult) => {
    const newItems = dragEndHandler(result);
    if (newItems) {
      await reorderMutate(newItems);
    }
  }, [dragEndHandler, reorderMutate]);

  return (
    <div style={{ background: "#ccc", padding: "1rem" }}>
      {isReorderError && <ErrorsAlert errors={reorderError!.messages} />}
      <Table hover>
        <thead>
          <tr>
            <th style={{ width: "125px" }}>Valu pvm</th>
            <th style={{ width: "125px" }}>Purku pvm</th>
            <th style={{ width: "125px" }}>Järj.</th>
            <th style={{ width: "125px" }}>Peti</th>
            <th style={{ width: "125px" }}>Lankatyyppi</th>
            <th style={{ width: "125px" }}>Paksuus</th>
            <th style={{ width: "125px" }}>Harjattu</th>
            <th style={{ width: "125px" }}>Jm</th>
            <th style={{ width: "125px" }}>TM</th>
          </tr>
        </thead>
        <DragDropContext onDragUpdate={dragUpdateHandler} onDragEnd={updateHandler} onDragStart={dragStartHandler}>
          <Droppable droppableId={useId()} isDropDisabled={isReordering}>
              {(provided, snapshot) => (
                  <tbody
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                  >
                      {items.map((productionLine, index) => (
                          <Draggable
                              key={productionLine.id}
                              draggableId={productionLine.id!}
                              index={index}
                              isDragDisabled={isReordering}
                          >
                              {(provided, snapshot) => (
                                  <tr
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                      )}
                                  >
                                      <td style={{ width: "125px" }}>{formatDate(productionLine.startDate)}</td>
                                      <td style={{ width: "125px" }}>{formatDate(productionLine.endDate)}</td>
                                      <td style={{ width: "125px" }}>{productionLine.position}</td>
                                      <td style={{ width: "125px" }}>{productionLine.productionLineNumber}</td>
                                      <td style={{ width: "125px" }}>{productionLine.typeName}</td>
                                      <td style={{ width: "125px" }}>{productionLine.height}</td>
                                      <td style={{ width: "125px" }}>{productionLine.brushed && <FontAwesomeIcon icon={faCheck} />}</td>
                                      <td style={{ width: "125px" }}>{productionLine.linealMeters}</td>
                                      <td style={{ width: "125px" }}>{productionLine.projectNumbers}</td>
                                  </tr>
                              )}
                          </Draggable>
                      ))}
                      {provided.placeholder}
                  </tbody>
              )}
          </Droppable>
        </DragDropContext>
        <tfoot>
          {showTotalRow && (
              <tr>
                  <td colSpan={7}>Yhteensä</td>
                  <td>{productionLines.map(productionLine => productionLine.linealMeters).reduce((prev, cur) => cur += prev).toFixed(2)}</td>
                  <td></td>
              </tr>
          )}
          {isReordering && <tr><td colSpan={9}><Spinner center /></td></tr>}
        </tfoot>
      </Table>
    </div>
  );
};

const useReorder = () => {
  const { reorderProductionLines } = useProductionLinesService();

  const {
    mutateAsync: reorderMutate,
    isPending: isReordering,
    isError: isReorderError,
    error: reorderError,
  } = useMutation<boolean, ErrorResponse, IProductionLine[]>({
    mutationFn: (data) => reorderProductionLines(data),
  });

  return { reorderMutate, isReordering, isReorderError, reorderError };
}

export default ProductionLinesDragAndDropTable;
