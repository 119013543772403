import { useParams } from "react-router-dom";
import { useWaybillsService } from "../services/waybills-service";
import { QueryKey } from "../services/query-keys";
import { useQuery } from "@tanstack/react-query";
import IWaybill from "../shared/IWaybill";
import { ErrorResponse } from "../custom-fetch";
import ErrorsAlert from "../components/ErrorsAlert/ErrorsAlert";
import Spinner from "../components/ui/Spinner/Spinner";
import WaybillPrint from "../components/Waybills/WaybillPrint";

const WaybillQRCodePage: React.FC = () => {
  const { uuid } = useParams();
  const { getWaybillByUuid } = useWaybillsService();

  const {
    data: waybill,
    isLoading,
    isError,
    error,
  } = useQuery<IWaybill, ErrorResponse>({
    queryKey: [QueryKey.waybills, uuid],
    queryFn: ({ signal }) => getWaybillByUuid({ signal, id: uuid! }),
    staleTime: 5000,
  });

  return (
    <>
      {isError ? (
          <ErrorsAlert errors={error.messages} />
        ) : isLoading ? (
          <Spinner />
        ) : waybill && (
          <WaybillPrint waybill={waybill} />
        )}
    </>
  );
};

export default WaybillQRCodePage;
