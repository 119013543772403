import { useMutation } from "@tanstack/react-query";
import { forwardRef, useCallback, useImperativeHandle, useRef, useState } from "react";
import { ErrorResponse } from "../../../custom-fetch";
import { useAdditionalInvoicesService } from "../../../services/additionalInvoices-service";
import IAdditionalInvoice from "../../../shared/IAdditionalInvoice";
import IProject from "../../../shared/IProject";
import { RefHandle } from "../../../shared/RefHandle";
import ErrorsAlert, { combineErrors } from "../../ErrorsAlert/ErrorsAlert";
import Button from "../../ui/Button/Button";
import { TInputValue } from "../../ui/Input/Input";
import InputContextProvider, { EInputUpdateAction } from "../../ui/Input/input-context";
import { formatInputValue } from "../../ui/Input/input-utils";
import { useConfirmModal } from "../../ui/Modal/useConfirmModal";
import Table from "../../ui/Table/Table";
import { useProjectEditContext } from "../ProjectEdit/project-edit-context";
import AdditionalInvoice from "./AdditionalInvoice";

const createItem = (projectId: string, project: IProject): IAdditionalInvoice => {
  return {
    id: "",
    projectId,
    projectName: project.name,
    projectNumber: project.offer?.projectNumber,
    created: undefined,
    createdBy: undefined,
    invoiceText: "",
    invoiceSum: "",
    description: "",
  };
};

const AdditionalInvoices: React.ForwardRefRenderFunction<RefHandle<IAdditionalInvoice[]>> = (_, ref) => {
  const { id: projectId, project, additionalInvoices, setAdditionalInvoices } = useProjectEditContext();
  const openConfirmModal = useConfirmModal();
  
  const [deletingId, setDeletingId] = useState<string | null>(null);

  const { saveMutate, isSaving, isSaveError, saveError } = useSave();
  const { deleteMutate, isDeleting, isDeletingError, deletingError } = useDelete();

  const saveHandler = useCallback(async () => {
    const newInvoice = await saveMutate(createItem(projectId, project));
    if (newInvoice) {
      setAdditionalInvoices(invoices => [...invoices, newInvoice]);
    }
  }, [saveMutate, projectId, project, setAdditionalInvoices]);

  const deleteHandler = useCallback(async (id: string) => {
    const isConfirm = await openConfirmModal("Oletko varma, että haluat poistaa lisätyön?");
    if (isConfirm) {
      setDeletingId(id);
      const deleteSuccess = await deleteMutate(id);
      if (deleteSuccess) {
        setAdditionalInvoices(invoices => invoices.filter(invoice => invoice.id !== id));
      }
      setDeletingId(null);
    }
  }, [deleteMutate, openConfirmModal, setAdditionalInvoices]);

  const updateHandler = useCallback(async (inputName: string, value: TInputValue, action: string, _: any) => {
    if (action === EInputUpdateAction.ADDITIONAL_INVOICES) {
      const data = _ as IAdditionalInvoice;
      const newInvoices = [...additionalInvoices];
      const index = newInvoices.findIndex(r => r.id === data.id);
      if (index > -1) {
        newInvoices[index] = {
          ...data,
          [inputName]: formatInputValue(value),
        };
      }
      setAdditionalInvoices(newInvoices);
    }
    return Promise.resolve(true);
  }, [additionalInvoices, setAdditionalInvoices]);

  const rowsRef = useRef<RefHandle<IAdditionalInvoice>[]>([]);

  useImperativeHandle(ref, () => ({
    getData: async () => {
      const rows = rowsRef.current;
      const newRows: IAdditionalInvoice[] = [];
      for (let i = 0; i < rows.length; i++) {
        const row = rows[i];
        if (row) {
          const rowData = await row.getData();
          if (rowData) {
            newRows.push(rowData);
          }
        }
      }
      return newRows;
    },
  }));

  const errorMessages = combineErrors(
    { isError: isSaveError, error: saveError },
    { isError: isDeletingError, error: deletingError },
  );

  return (
    <div>
      <Table>
        <thead>
          <tr>
            <th style={{ width: "200px" }}>Teksti</th>
            <th style={{ width: "200px" }}>Summa</th>
            <th style={{ width: "800px" }}>Muistiinpanot</th>
          </tr>
        </thead>
        <tbody>
          <InputContextProvider initDone onAutoUpdate={updateHandler}>
            {additionalInvoices?.map((invoice, i) => (
              <AdditionalInvoice
                key={invoice.id}
                invoice={invoice}
                onDelete={() => deleteHandler(invoice.id)}
                isDeleting={isDeleting && deletingId === invoice.id}
                ref={(rowHandle) => (rowsRef.current[i] = rowHandle!)}
              />
            ))}
          </InputContextProvider>
          <tr>
            <td colSpan={11}>
              <Button onClick={saveHandler} loading={isSaving}>
                Lisää uusi
              </Button>
            </td>
          </tr>
        </tbody>
      </Table>
      {errorMessages.length > 0 && <ErrorsAlert errors={errorMessages} />}
    </div>
  );
};

const useSave = () => {
  const { saveAdditionalInvoice } = useAdditionalInvoicesService();

  const {
    mutateAsync: saveMutate,
    isPending: isSaving,
    isError: isSaveError,
    error: saveError,
  } = useMutation<IAdditionalInvoice, ErrorResponse, IAdditionalInvoice>({
    mutationFn: (data) => saveAdditionalInvoice(data),
  });

  return { saveMutate, isSaving, isSaveError, saveError };
};

const useDelete = () => {
  const { deleteAdditionalInvoice } = useAdditionalInvoicesService();

  const {
    mutateAsync: deleteMutate,
    isPending: isDeleting,
    isError: isDeletingError,
    error: deletingError,
  } = useMutation<boolean, ErrorResponse, string>({
    mutationFn: (id) => deleteAdditionalInvoice(id),
  });

  return { deleteMutate, isDeleting, isDeletingError, deletingError };
};

export default forwardRef(AdditionalInvoices);
