import Container from "../components/ui/Container/Container";
import { useNavigate } from "react-router-dom";
import { Route } from "../routes";
import PageHeading from "../components/ui/PageHeading/PageHeading";
import TransportationCostsList from "../components/TransportationCosts/TransportationCostsList";

const TransportationCostsPage: React.FC = () => {
  const navigate = useNavigate();

  const addHandler = () => {
    navigate(Route.transportationCost("add"));
  }

  return (
    <>
      <PageHeading onAdd={addHandler}>Kuljetushinnat</PageHeading>
      <Container>
        <TransportationCostsList />
      </Container>
    </>
  );
};

export default TransportationCostsPage;
