import { useNavigate } from "react-router-dom";
import Table from "../ui/Table/Table";
import { Route } from "../../routes";
import IInsulation from "../../shared/IInsulation";

interface IProps {
  insulations: IInsulation[];
}

const InsulationsTable: React.FC<IProps> = ({ insulations }) => {
  const navigate = useNavigate();
  const openHandler = (id: string) => {
    navigate(Route.insulation(id));
  };
  return (
    <Table hover>
      <thead>
        <tr>
          <th>Tunnus</th>
          <th>Kuutiohinta</th>
          <th>Päivitetty</th>
          <th>Arkistoitu</th>
        </tr>
      </thead>
      <tbody>
        {insulations.map((insulation) => (
          <tr
            key={insulation.id}
            onClick={() => openHandler(insulation.id)}
            style={{
              color: insulation.archived ? "gray" : "inherit",
              fontStyle: insulation.archived ? "italic" : "inherit",
            }}
          >
            <td>{insulation.unitName}</td>
            <td>{insulation.cubicPrice} €</td>
            <td>{insulation.updatedString}</td>
            <td>{insulation.archived && "arkistoitu"}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default InsulationsTable;
