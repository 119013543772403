import React from 'react';
import ISteelParts from '../../shared/ISteelPart';
import { useNavigate } from 'react-router-dom';
import { Route } from '../../routes';
import Table from '../ui/Table/Table';

interface IProps {
  steelParts: ISteelParts[];
}

const SteelPartsTable: React.FC<IProps> = ({ steelParts }) => {
  const navigate = useNavigate();
  const openHandler = (id: string) => {
    navigate(Route.steelPart(id));
  };
  return (
    <Table hover>
      <thead>
        <tr>
          <th>Nimikenumero</th>
          <th>Nimike</th>
          <th>Nettohinta</th>
          <th>Selite</th>
          <th>Lisätiedot</th>
          <th>Toimittaja</th>
          <th>Ryhmä</th>
          <th>Päivitetty</th>
          <th>Arkistoitu</th>
        </tr>
      </thead>
      <tbody>
        {steelParts.map((steelPart) => (
          <tr key={steelPart.id} onClick={() => openHandler(steelPart.id)} style={{ color: steelPart.archived ? 'gray' : 'inherit', fontStyle: steelPart.archived ? "italic" : 'inherit' }}>
            <td>{steelPart.unitNumber}</td>
            <td>{steelPart.unitName}</td>
            <td>{steelPart.netPrice} €</td>
            <td>{steelPart.description}</td>
            <td>{steelPart.additionalInfo}</td>
            <td>{steelPart.supplierName}</td>
            <td>{steelPart.group}</td>
            <td>{steelPart.updatedString}</td>
            <td>{steelPart.archived && "arkistoitu"}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default SteelPartsTable;