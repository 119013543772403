import { faCopy, faTrash } from "@fortawesome/free-solid-svg-icons";
import { forwardRef, useImperativeHandle, useState } from "react";
import { useProductTypeOptions } from "../../../hooks/useProductTypeOptions";
import { FactoryOptions } from "../../../shared/FactoryOptions";
import IWeeklyProductionRow from "../../../shared/IWeeklyProductionRow";
import { RefHandle } from "../../../shared/RefHandle";
import Button, { EButtonColor, EButtonSize } from "../../ui/Button/Button";
import { EInputType, IInputField, IOption } from "../../ui/Input/Input";
import { useInputs } from "../../ui/Input/useInputs";
import { EInputUpdateAction } from "../../ui/Input/input-context";

export enum EInputs {
  factory = "factory",
  phaseId = "phaseId",
  productType = "productType",
  count = "count",
  grossArea = "grossArea",
  linealMeters = "linealMeters",
  cubicMeters = "cubicMeters",
  productionWeek = "productionWeek",
  deliveryWeek = "deliveryWeek",
}

interface IProps {
  row: IWeeklyProductionRow;
  phaseOptions: IOption[];
  onDelete: () => void;
  isDeleting: boolean;
  onCopy: () => void;
  isCopying: boolean;
  onClick?: () => void;
  hidden: boolean;
}

const WeeklyProductionRow: React.ForwardRefRenderFunction<
  RefHandle<IWeeklyProductionRow>,
  IProps
> = ({ row, phaseOptions, onDelete, isDeleting, onCopy, isCopying, onClick, hidden }, ref) => {
  const { createInput, submit } = useElementInputs(row);
  const { options: productTypesOptions, loading: productTypesLoading, productTypes } =
    useProductTypeOptions();

  useImperativeHandle(ref, () => ({
    getData: async () => {
      const data = await submit();
      // console.log(data);
      if (!data) return null;
      const productType = productTypes?.find(productType => productType.id === data.productType);
      return { ...row, ...data, productType };
    },
  }));

  return (
    <>
      <tr style={hidden ? { display: "none" } : {}} onClick={onClick}>
        <td>{createInput(EInputs.factory)}</td>
        <td>{createInput(EInputs.phaseId, { options: phaseOptions })}</td>
        <td>
          {createInput(EInputs.productType, {
            options: productTypesOptions,
            loading: productTypesLoading,
          })}
        </td>
        <td>{createInput(EInputs.count)}</td>
        <td>{createInput(EInputs.grossArea)}</td>
        <td>{createInput(EInputs.linealMeters)}</td>
        <td>{createInput(EInputs.cubicMeters)}</td>
        <td>{createInput(EInputs.productionWeek)}</td>
        <td>{createInput(EInputs.deliveryWeek)}</td>
        <td style={{ borderTop: 0, display: "flex", gap: "0.1rem" }}>
          <Button onClick={(e) => { e.stopPropagation(); onDelete(); }} size={EButtonSize.SMALL} color={EButtonColor.DANGER} icon={faTrash} title="Poista" loading={isDeleting} />
          <Button onClick={(e) => { e.stopPropagation(); onCopy(); }} size={EButtonSize.SMALL} icon={faCopy} title="Kopioi" loading={isCopying} />
        </td>
      </tr>
    </>
  );
};

const useElementInputs = (data?: IWeeklyProductionRow) => {
  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.factory]: {
      type: EInputType.reactSelect,
      options: FactoryOptions,
      value: "",
      hideControls: true,
      menuPosition: "fixed",
      placeholder: "",
    },
    [EInputs.phaseId]: {
      type: EInputType.reactSelect,
      options: [],
      value: "",
      hideControls: true,
      menuPosition: "fixed",
      placeholder: "",
    },
    [EInputs.productType]: {
      type: EInputType.reactSelect,
      options: [],
      value: "",
      hideControls: true,
      menuPosition: "fixed",
      placeholder: "",
    },
    [EInputs.count]: {
      type: EInputType.number,
      value: "",
    },
    [EInputs.grossArea]: {
      type: EInputType.number,
      value: "",
    },
    [EInputs.linealMeters]: {
      type: EInputType.number,
      value: "",
    },
    [EInputs.cubicMeters]: {
      type: EInputType.number,
      value: "",
    },
    [EInputs.productionWeek]: {
      type: EInputType.number,
      value: "",
    },
    [EInputs.deliveryWeek]: {
      type: EInputType.number,
      value: "",
    },
  });

  const { createInput, submit } = useInputs({ data, inputs, setInputs, updateAction: EInputUpdateAction.WEEKLY_PRODUCTION_ROWS });

  return { createInput, submit };
};

export default forwardRef(WeeklyProductionRow);
