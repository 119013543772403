import React, { useEffect, useState } from 'react';

import * as dimensionUtils from '../../utils/dimension-utils';
import Logo from '../Logo/Logo';
import classes from './Header.module.scss';
import Menu from './Menu/Menu';
import MobileMenu from './MobileMenu/MobileMenu';
import ToggleMenu from './ToggleMenu/ToggleMenu';
import { useMenuItems } from './useMenuItems';

const Header: React.FC = () => {
  const menuItems = useMenuItems();

  const [isDesktop, setIsDesktop] = useState(dimensionUtils.isDesktop());
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenuHandler = () => setIsOpen((prevState) => !prevState);

  useEffect(() => {
    const resizeHandler = () => {
      setIsDesktop(dimensionUtils.isDesktop());
    };
    window.addEventListener("resize", resizeHandler);
    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, []);


  return (
    <React.Fragment>
      {!isDesktop && <MobileMenu menuItems={menuItems} isOpen={isOpen} onToggle={toggleMenuHandler} />}
      <header className={classes.Container}>
        <div className={classes.Wrapper}>
          <div className={classes.Logo}>
            <Logo />
          </div>
          {isDesktop && <Menu menuItems={menuItems} /> }
          {!isDesktop && <ToggleMenu onToggle={toggleMenuHandler} /> }
        </div>
      </header>
    </React.Fragment>
  );
};

export default React.memo(Header);
