import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { queryClient } from "../../App";
import { ErrorResponse } from "../../custom-fetch";
import { Route } from "../../routes";
import { QueryKey } from "../../services/query-keys";
import ErrorsAlert, { combineErrors } from "../ErrorsAlert/ErrorsAlert";
import FormButtons from "../ui/FormButtons/FormButtons";
import Input, { ECommonValue, EInputType, IInputField, IOption } from "../ui/Input/Input";
import { useInputs } from "../ui/Input/useInputs";
import { useOfferTermService } from "../../services/offerTerms-service";
import IOfferTerm, { EOfferTermType } from "../../shared/IOfferTerm";
import { useProductGroupOptions } from "../../hooks/useProductGroupOptions";
import { useProductTypeOptions } from "../../hooks/useProductTypeOptions";
import { FactoryOptions } from "../../shared/FactoryOptions";

interface IProps {
  id: string;
  offerTerm?: IOfferTerm;
}

enum EInputs {
  name = "name",
  text = "text",
  type = "type",
  factory = "factory",
  productGroup = "productGroup",
  productType = "productType",
  defaultValue = "defaultValue",
  archived = "archived"
}

const OfferTermTypeOptions: IOption[] = [
  {value:EOfferTermType.DELIVERY_TERM, label:"Toimitusehto"},
  {value:EOfferTermType.CONDITIONAL, label:"Ehdollinen"},
  {value:EOfferTermType.PAYMENT_TERM, label:"Maksuehto"},
  {value:EOfferTermType.INVOICING, label:"Maksuerät"},
  {value:EOfferTermType.ENGINEER_TERM, label:"Suunnitteluohje"},
  {value:EOfferTermType.INSTALLATIONS_TERM, label:"Asennusohje"},
  {value:EOfferTermType.FREIGHT_TERM, label:"Kuormien tilaus"},
  {value:EOfferTermType.PLANNING_DELIVERY, label:"Suunnitelmien toimitus"},
  {value:EOfferTermType.COMMON, label:"Yleiset"},
]

const OfferTermEdit: React.FC<IProps> = ({ id, offerTerm }) => {
  const isEdit = id !== "add";
  
  const navigate = useNavigate();

  const redirectHandler = () => {
    return navigate(Route.offerTerms);
  };

  const [type, setType] = useState<EOfferTermType>(offerTerm?.type ? offerTerm?.type : EOfferTermType.COMMON);

  const { saveOrUpdate, error, isError, isPending } = useSaveOrUpdate(id, isEdit, redirectHandler, type);
  const { deleteMutate, isDeleting, isDeletingError, deletingError } = useDelete(id, redirectHandler);
  const { createInput, submit } = useOfferTermInputs(offerTerm);
  const { loading:productGroupsLoading, options:productGroupsOptions, productGroups } = useProductGroupOptions();
  const { loading:productTypesLoading, options:productTypesOptions, productTypes } = useProductTypeOptions();

  const submitHandler = async () => {
    const data = await submit();
    if (data) {
      data.type = type;
      if (type === EOfferTermType.CONDITIONAL) {
        data.productGroup = productGroups?.find((p) => data.productGroup && +p.id === +data.productGroup);
        data.productType = productTypes?.find((p) => data.productType && +p.id === +data.productType);
        if (!data.factory) data.factory = undefined;
      } else {
        data.productGroup = undefined;
        data.productType = undefined;
        data.factory = undefined;
      }
      saveOrUpdate(data);
    }
  };

  const errorMessages = combineErrors({ isError, error }, { isError: isDeletingError, error: deletingError });

  return (
    <>
      <Input
        inputName="offerTermType"
        label="Tyyppi"
        type={EInputType.reactSelect}
        value={type}
        options={OfferTermTypeOptions}
        onChange={(value) => setType(value as EOfferTermType)}
        isClearable={false}
        autoFocus={true}
      />
      {createInput(EInputs.name)}
      {type === EOfferTermType.CONDITIONAL && <>
        {createInput(EInputs.factory, {options: FactoryOptions})}
        {createInput(EInputs.productGroup, {options: productGroupsOptions, loading: productGroupsLoading})}
        {createInput(EInputs.productType, {options: productTypesOptions, loading: productTypesLoading})}</>
      }
      {createInput(EInputs.text)}
      {createInput(EInputs.defaultValue)}
      {createInput(EInputs.archived)}
      {errorMessages.length > 0 && <ErrorsAlert errors={errorMessages} />}
      <FormButtons
        onSubmit={submitHandler}
        isLoading={isPending}
        onDelete={isEdit ? deleteMutate : undefined}
        isDeleting={isDeleting}
        deleteConfirmMessage="Haluatko varmasti poistaa sopimustekstin?"
      />
    </>
  );
};

const useOfferTermInputs = (data?: IOfferTerm) => {
  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.name]: {
      type: EInputType.text,
      label: "Nimi",
      value: "",
    },
    [EInputs.text]: {
      type: EInputType.textarea,
      label: "Teksti",
      value: "",
      validation: {
        required: true,
      },
    },
    [EInputs.factory]: {
      type: EInputType.reactSelect,
      label: "Tehdas",
      placeholder: "Tehdas",
      value: "",
    },
    [EInputs.productGroup]: {
      type: EInputType.reactSelect,
      label: "Tuoteryhmä",
      placeholder: "Tuoteryhmä",
      value: "",
    },
    [EInputs.productType]: {
      type: EInputType.reactSelect,
      label: "Tuote",
      placeholder: "Tuote",
      value: "",
    },
    [EInputs.defaultValue]: {
      type: EInputType.checkbox,
      label: "Oletus",
      options: [{ value: ECommonValue.YES }],
      value: [],
    },
    [EInputs.archived]: {
      type: EInputType.checkbox,
      label: "Arkistoitu",
      options: [{ value: ECommonValue.YES }],
      value: [],
    },
  });

  const { createInput, submit } = useInputs({ data, inputs, setInputs });

  return { createInput, submit };
};

const useSaveOrUpdate = (id: string, isEdit: boolean, redirectHandler: () => void, type: EOfferTermType) => {
  const { updateOfferTerm, saveOfferTerm } = useOfferTermService();

  const mutationFn = (data: IOfferTerm) => {
    return isEdit ? updateOfferTerm(id, data) : saveOfferTerm(data);
  };

  const {
    mutate: saveOrUpdate,
    isPending,
    isError,
    error,
  } = useMutation<IOfferTerm, ErrorResponse, IOfferTerm>({
    mutationFn,
    onSuccess: () => {
      queryClient.removeQueries({ queryKey: [QueryKey.offerTerms, id] });
      queryClient.removeQueries({ queryKey: [QueryKey.offerTerms] });
      queryClient.removeQueries({ queryKey: [QueryKey.offerTerms, type] });
      redirectHandler();
    },
  });

  return { saveOrUpdate, isPending, isError, error };
};

const useDelete = (id: string, redirectHandler: () => void) => {
  const { deleteOfferTerm } = useOfferTermService();

  const {
    mutate: deleteMutate,
    isPending: isDeleting,
    isError: isDeletingError,
    error: deletingError,
  } = useMutation<boolean, ErrorResponse>({
    mutationFn: () => deleteOfferTerm(id),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.offerTerms],
        refetchType: "none",
      });
      redirectHandler();
    },
  });

  return { deleteMutate, isDeleting, isDeletingError, deletingError };
};

export default OfferTermEdit;
