import { Fragment } from "react/jsx-runtime";
import IWeeklyProductionLine from "../../shared/IWeeklyProductionLine";
import ProductionLinesDragAndDropTable from "../ProductionLines/ProductionLinesDragAndDropTable";
import PageHeading from "../ui/PageHeading/PageHeading";

interface IProps {
  weeklyProductionLines: IWeeklyProductionLine[];
}

const WeeklyProductionLines: React.FC<IProps> = ({ weeklyProductionLines }) => {
  return (
    <>
      {weeklyProductionLines.map((week) => (
        <Fragment key={week.productionWeek}>
          <PageHeading variant="h2" noHorizontalMargin>
            Pedit viikolle {week.productionWeek}
          </PageHeading>
          {!week.productionLines || week.productionLines.length === 0 ? (
            <p>Ei petejä</p>
          ) : (
            <ProductionLinesDragAndDropTable productionLines={week.productionLines} showTotalRow />
          )}
          <hr />
        </Fragment>
      ))}
    </>
  );
};

export default WeeklyProductionLines;
