import { faSwatchbook } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback } from "react";

interface IProps {
  value: number;
  onChange: (value: number) => void;
}

enum Swatch {
  gray,
  green,
  orange,
  length,
}

const OfferSwatchbook: React.FC<IProps> = ({ value, onChange }) => {
  const changeHandler = useCallback(() => {
    let newValue = (value ?? 0) + 1;
    if (value >= (Swatch.length - 1)) {
        newValue = 0;
    }
    onChange(newValue);
  }, [value, onChange]);

  return <FontAwesomeIcon title="Rivin väri" icon={faSwatchbook} onClick={changeHandler} style={{ cursor: "pointer" }} />;
};

export default OfferSwatchbook;
