import { useQuery } from "@tanstack/react-query";
import { ErrorResponse } from "../../../custom-fetch";
import { QueryKey } from "../../../services/query-keys";
import { useWeeklyProductionRowsService } from "../../../services/weeklyProductionRows-service";
import { EFactory } from "../../../shared/IOfferRow";
import IWeeklyProductionRow from "../../../shared/IWeeklyProductionRow";
import ErrorsAlert, { combineErrors } from "../../ErrorsAlert/ErrorsAlert";
import PageHeading from "../../ui/PageHeading/PageHeading";
import Spinner from "../../ui/Spinner/Spinner";
import Table from "../../ui/Table/Table";
import IProductType from "../../../shared/IProductType";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { Route } from "../../../routes";

interface IProps {
  factory: EFactory;
  productionWeek: string;
}

const WeeklyProductionRowsProjectsTable: React.FC<IProps> = ({ factory, productionWeek }) => {
  const { weeklyProductionRowsByProject, isFetchingByProject, isFetchByProjectError, fetchByProjectError } = useFetchByProject(factory, productionWeek);
  const { weeklyProductionRows, isFetching, isFetchError, fetchError } = useFetch(factory, productionWeek);

  const errorMessages = combineErrors(
    { isError: isFetchError, error: fetchError },
    { isError: isFetchByProjectError, error: fetchByProjectError }
  );

  const navigate = useNavigate();  
  
  const openHandler = useCallback((id: string) => {
    navigate(Route.project(id));
  }, [navigate]);

  return (
    <>
      {errorMessages?.length > 0 && <ErrorsAlert errors={errorMessages} />}
      <PageHeading variant="h3">
        Työmaat suunnittelusta viikolle {productionWeek}
      </PageHeading>
      {isFetchingByProject ? (
        <Spinner />
      ) : weeklyProductionRowsByProject &&
        weeklyProductionRowsByProject?.length > 0 ? (
        <Table>
          <thead>
            <tr>
              <th style={{ width: "80px" }}>tehdas</th>
              <th style={{ width: "80px" }}>työmaa</th>
              <th style={{ width: "80px", maxWidth: "80px" }}>työmaanimi</th>
              <th style={{ width: "80px" }}></th>
              <th style={{ width: "80px" }}>a²</th>
              <th style={{ width: "80px" }}>jm</th>
              <th style={{ width: "80px" }}>skpl</th>
              <th style={{ width: "80px" }}>sm²</th>
            </tr>
          </thead>
          <tbody>
            {weeklyProductionRowsByProject?.map((row) => (
              <tr key={`weeklyProductionRowsByProject-${row.id}`} onClick={() => openHandler(row.projectId)} style={{ cursor: "pointer" }}>
                <td>{row.factory}</td>
                <td>{row.projectNumber}</td>
                <td>{row.projectName}</td>
                <td></td>
                <td>{row.grossArea}</td>
                <td>{row.linealMeters}</td>
                <td></td>
                <td></td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : null}
      <PageHeading variant="h3">Suunnittelu viikko a2 LT</PageHeading>
      {isFetching ? (
        <Spinner />
      ) : weeklyProductionRows && weeklyProductionRows.length > 0 ? (
        <Table>
          <thead>
            <tr>
              <th style={{ width: "80px" }}>tehdas</th>
              <th style={{ width: "80px" }}>työmaa</th>
              <th style={{ width: "80px", maxWidth: "80px" }}>työmaanimi</th>
              <th style={{ width: "80px" }}>tyyppi</th>
              <th style={{ width: "80px" }}>a²</th>
              <th style={{ width: "80px" }}>jm</th>
              <th style={{ width: "80px" }}>skpl</th>
              <th style={{ width: "80px" }}>sm²</th>
            </tr>
          </thead>
          <tbody>
            {weeklyProductionRows?.map((row) => (
              <tr key={`weeklyProductionRows-${row.id}`} onClick={() => openHandler(row.projectId)} style={{ cursor: "pointer" }}>
                <td>{row.factory}</td>
                <td>{row.projectNumber}</td>
                <td>{row.projectName}</td>
                <td>{(row.productType as IProductType)?.name}</td>
                <td>{row.grossArea}</td>
                <td>{row.linealMeters}</td>
                <td></td>
                <td></td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : null}
    </>
  );
};

const useFetch = (factory: string, productionWeek: string) => {
  const { fetchWeeklyProductionRows } = useWeeklyProductionRowsService();
  const queryKey = [QueryKey.weeklyProductionRows, factory, productionWeek];
  const {
    data: weeklyProductionRows,
    isPending: isFetching,
    isError: isFetchError,
    error: fetchError,
  } = useQuery<IWeeklyProductionRow[], ErrorResponse>({
    queryKey,
    queryFn: ({ signal }) => fetchWeeklyProductionRows({ signal, projectId: "", jsonBody: { factory, productionWeekEquals: productionWeek } }),
    staleTime: 5000,
  });

  return { weeklyProductionRows, isFetching, isFetchError, fetchError };
};

const useFetchByProject = (factory: string, productionWeek: string) => {
  const { fetchWeeklyProductionRowsByProject } = useWeeklyProductionRowsService();
  const queryKey = [QueryKey.weeklyProductionRowsByProject, factory, productionWeek];
  const {
    data: weeklyProductionRowsByProject,
    isPending: isFetchingByProject,
    isError: isFetchByProjectError,
    error: fetchByProjectError,
  } = useQuery<IWeeklyProductionRow[], ErrorResponse>({
    queryKey,
    queryFn: ({ signal }) => fetchWeeklyProductionRowsByProject({ signal, projectId: "", jsonBody: { factory, productionWeekEquals: productionWeek } }),
    staleTime: 5000,
  });

  return { weeklyProductionRowsByProject, isFetchingByProject, isFetchByProjectError, fetchByProjectError };
};

export default WeeklyProductionRowsProjectsTable;
