import { useSteelPartsService } from "../../services/steelParts-service";
import ISteelParts from "../../shared/ISteelPart";
import { useQuery } from "@tanstack/react-query";
import { QueryKey } from "../../services/query-keys";
import ErrorsAlert from "../ErrorsAlert/ErrorsAlert";
import Spinner from "../ui/Spinner/Spinner";
import SteelPartsTable from "./SteelPartsTable";
import { ErrorResponse } from "../../custom-fetch";
import { useState } from "react";
import Input, { EInputType } from "../ui/Input/Input";

const SteelPartsList: React.FC = () => {
  const { fetchSteelParts } = useSteelPartsService();
  const [filterValue, setFilterValue] = useState<string>('');

  const {
    data: steelParts,
    isPending,
    isError,
    error,
  } = useQuery<ISteelParts[], ErrorResponse>({
    queryKey: [QueryKey.steelParts],
    queryFn: fetchSteelParts,
    staleTime: 5000,
  });

  if (isError) {
    return <ErrorsAlert errors={error.messages} />
  }

  if (isPending) {
    return <Spinner />
  }

  if (!steelParts || steelParts.length === 0) {
    return <p>Ei teräsosia</p>
  }

  const filteredSteelParts = steelParts.filter(
    (steelPart) =>
      steelPart.unitNumber?.toLowerCase().includes(filterValue) ||
      steelPart.unitName?.toLowerCase().includes(filterValue) ||
      steelPart.additionalInfo?.toLowerCase().includes(filterValue) ||
      steelPart.supplierName?.toLowerCase().includes(filterValue) ||
      steelPart.group?.toLowerCase().includes(filterValue)
  );

  return (
    <>
      <Input
        inputName="steelPartsFilter"
        type={EInputType.text}
        placeholder="Suodata nimike numerolla, nimikkeellä, lisätiedoilla, toimittajan nimellä tai ryhmällä"
        value={filterValue}
        onChange={(value) => setFilterValue(value.toString().toLowerCase())}
        autoFocus={true}
      />
      <SteelPartsTable steelParts={filteredSteelParts}/>
    </>
  );
};

export default SteelPartsList;
