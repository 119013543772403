import { faCopy, faEdit, faSave, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useMutation } from "@tanstack/react-query";
import { forwardRef, useCallback, useImperativeHandle, useState } from "react";
import { ErrorResponse } from "../../../custom-fetch";
import { useProductTypeOptions } from "../../../hooks/useProductTypeOptions";
import { useElementsService } from "../../../services/elements-service";
import { FactoryOptions } from "../../../shared/FactoryOptions";
import IElement from "../../../shared/IElement";
import IProvision from "../../../shared/IProvision";
import { RefHandle } from "../../../shared/RefHandle";
import { dateIsFuture, formatDate } from "../../../utils/date-utils";
import { useProvisionsEditModal } from "../../ProvisionsEditModal/useProvisionsEditModal";
import Button, { EButtonColor, EButtonSize } from "../../ui/Button/Button";
import { EInputType, IInputField, IOption } from "../../ui/Input/Input";
import { EInputUpdateAction } from "../../ui/Input/input-context";
import { useInputs } from "../../ui/Input/useInputs";
import { EModalId, useModalContext } from "../../ui/Modal/modal-context";
import Spinner from "../../ui/Spinner/Spinner";

enum EInputs {
  // elementId
  length = "length",
  width = "width",
  height = "height",
  weightTons = "weightTons",
  name = "name",
  productType = "productType",
  productionStatus = "productionStatus",
  measurements = "measurements",
  provisions = "provisions",
  finishing = "finishing",
  // projectId
  phaseId = "phaseId",
  factory = "factory",
  tendonCountThick = "tendonCountThick",
  tendonCountThin = "tendonCountThin",
  productionWeek = "productionWeek",
  deliveryWeek = "deliveryWeek",
}

interface IProps {
  element: IElement;
  hidden: boolean;
  phaseOptions: IOption[];
  onDelete: () => void;
  isDeleting: boolean;
  onCopy: () => void;
  isCopying: boolean;
  onUpdate: (element: IElement) => void;
}

const Element: React.ForwardRefRenderFunction<RefHandle<IElement>, IProps> = ({ element, hidden, phaseOptions, onDelete, isDeleting, onCopy, isCopying, onUpdate }, ref) => {
  const { createInput, submit } = useElementInputs(element);
  const { options: productTypesOptions, loading: productTypesLoading, productTypes } = useProductTypeOptions();
  const { updateElementMutate, isUpdatingElements } = useUpdateElements();

  const [provisions, setProvisions] = useState<IProvision[]>(element.provisions ?? []);
  const openProvisionsModal = useProvisionsEditModal(provisions);
  const { closeModal } = useModalContext();

  const getData = useCallback(async () => {
    const data = await submit();
    // console.log(data);
    if (!data) return null;
    const selectedProductType = productTypes?.find(productType => productType.id === data.productType);
    data.productType = selectedProductType ?? undefined;
    const selectedPhase = phaseOptions.find(phase => phase.value === data.phaseId);
    data.phaseId = selectedPhase?.value ?? undefined;
    // console.log(data);
    data.provisions = provisions;
    return { ...element, ...data };
  }, [element, phaseOptions, productTypes, provisions, submit]);

  const editProvisionsHandler = useCallback(async () => {
    const data = await openProvisionsModal();
    if (data) {
      console.log(data);
      setProvisions(data);
      const newData = await getData();
      if (newData) {
        const newElement = await updateElementMutate([{ ...newData, provisions: data }]);
        if (newElement) {
          onUpdate(newElement[0]);
        }
      }
    }
    closeModal(EModalId.PROVISIONS_EDIT);
  }, [openProvisionsModal, closeModal, getData, updateElementMutate, onUpdate]);

  const saveHandler = useCallback(async () => {
    const data = await getData();
    if (data) {
      const newElement = await updateElementMutate([{ ...data }]);
      if (newElement && newElement.length === 1) {
        onUpdate(newElement[0]);
      }
    }
  }, [getData, onUpdate, updateElementMutate]);

  useImperativeHandle(ref, () => ({ getData }));

  if (isUpdatingElements) {
    return <tr><td colSpan={21}><Spinner /></td></tr>;
  }
  return (
    <tr style={hidden ? { display: "none" } : {}}>
      <td>{createInput(EInputs.phaseId, { options: phaseOptions })}</td>
      <td>
        {createInput(EInputs.productType, {
          options: productTypesOptions,
          loading: productTypesLoading,
        })}
      </td>
      <td>{createInput(EInputs.name)}</td>
      <td>{createInput(EInputs.tendonCountThick)}</td>
      <td>{createInput(EInputs.tendonCountThin)}</td>
      <td>{createInput(EInputs.length)}</td>
      <td>{createInput(EInputs.width)}</td>
      <td>{createInput(EInputs.height)}</td>
      <td>{element.weightTons}</td>
      <td>
        <div style={{ display: "flex", alignItems: "flex-start", gap: "0.25rem" }}>
          <span title={element.provisionAmountLong} style={{ alignSelf: "center" }}>{element.provisionAmountShort}</span>
          <Button onClick={(e) => { e.stopPropagation(); editProvisionsHandler(); }} size={EButtonSize.SMALL} icon={faEdit} title="Muokkaa varauksia" style={{ minWidth: "45px" }} />
        </div>
      </td>
      <td style={{ alignContent: "flex-start" }}>
        <Button style={{ marginRight: "0.25rem" }} onClick={(e) => { e.stopPropagation(); onDelete(); }} size={EButtonSize.SMALL} color={EButtonColor.DANGER} icon={faTrash} title="Poista" loading={isDeleting} />
        <Button onClick={(e) => { e.stopPropagation(); onCopy(); }} size={EButtonSize.SMALL} icon={faCopy} title="Kopioi" loading={isCopying} />
        <Button style={{ marginTop: "0.25rem" }} onClick={(e) => { e.stopPropagation(); saveHandler(); }} size={EButtonSize.SMALL} icon={faSave} title="Tallenna" loading={isCopying} />
      </td>
      <td>{createInput(EInputs.productionWeek)}</td>
      <td>→</td>
      <td>{createInput(EInputs.deliveryWeek)}</td>
      <td>{element.productionLineNumber}</td>
      <td style={dateIsFuture(element.productionLineStartDate) ? { fontFamily: "cursive" } : {}}>{formatDate(element.productionLineStartDate)}</td>
      <td>{element.productionLineFactory}</td>
      <td>varasto</td>
      <td>{element.waybillNumber}</td>
      {/* TODO: toimitus cursive */}
      <td>toimitus</td>
    </tr>
  );
};

const useElementInputs = (data?: IElement) => {
  const [inputs, setInputs] = useState<IInputField>({
    // [EInputs.elementId]: {
    //   type: EInputType.text,
    //   value: "",
    // },
    [EInputs.length]: {
      type: EInputType.number,
      value: "",
    },
    [EInputs.width]: {
      type: EInputType.number,
      value: "",
    },
    [EInputs.height]: {
      type: EInputType.number,
      value: "",
    },
    [EInputs.weightTons]: {
      type: EInputType.number,
      value: "",
    },
    [EInputs.name]: {
      type: EInputType.text,
      value: "",
    },
    [EInputs.productType]: {
      type: EInputType.reactSelect,
      options: [],
      value: "",
      hideControls: true,
      menuPosition: "fixed",
      placeholder: "",
    },
    [EInputs.productionStatus]: {
      type: EInputType.text,
      value: "",
    },
    [EInputs.measurements]: {
      type: EInputType.text,
      value: "",
    },
    [EInputs.provisions]: {
      type: EInputType.text,
      value: "",
    },
    [EInputs.finishing]: {
      type: EInputType.text,
      value: "",
    },
    // [EInputs.projectId]: {
    //   type: EInputType.text,
    //   value: "",
    // },
    [EInputs.phaseId]: {
      type: EInputType.reactSelect,
      options: [],
      value: "",
      hideControls: true,
      menuPosition: "fixed",
      placeholder: "",
    },
    [EInputs.factory]: {
      type: EInputType.reactSelect,
      options: FactoryOptions,
      value: "",
      hideControls: true,
      menuPosition: "fixed",
      placeholder: "",
    },
    [EInputs.tendonCountThick]: {
      type: EInputType.number,
      value: "",
    },
    [EInputs.tendonCountThin]: {
      type: EInputType.number,
      value: "",
    },
    [EInputs.productionWeek]: {
      type: EInputType.text,
      value: "",
    },
    [EInputs.deliveryWeek]: {
      type: EInputType.text,
      value: "",
    },
  });

  const { createInput, submit } = useInputs({ data, inputs, setInputs, updateAction: EInputUpdateAction.ELEMENTS });

  return { createInput, submit };
};

const useUpdateElements = () => {
  const { updateElements } = useElementsService();

  const {
    mutateAsync: updateElementMutate,
    isPending: isUpdatingElements,
    isError: isUpdateElementsError,
    error: updateElementsError,
  } = useMutation<IElement[], ErrorResponse, IElement[]>({
    mutationFn: (data) => updateElements(data),
  });

  return { updateElementMutate, isUpdatingElements, isUpdateElementsError, updateElementsError };
};

export default forwardRef(Element);
