import { IOption } from "../components/ui/Input/Input";

export enum EProvisionType {
  circle = "circle",
  rectangle = "rectangle",
  other = "other",
  slot = "slot",
  liftingLoop = "liftingLoop",
  wireLoop = "wireLoop",
  fill = "fill",
  split = "split",
}

export const provisionOptions: IOption[] = [
  { value: EProvisionType.circle, label: "Ympyrä" },
  { value: EProvisionType.rectangle, label: "Suorakaide" },
  { value: EProvisionType.other, label: "Muu leikkaus" },
  { value: EProvisionType.slot, label: "Ura" },
  { value: EProvisionType.liftingLoop, label: "Nostolenkki" },
  { value: EProvisionType.wireLoop, label: "Pasilenkki" },
  { value: EProvisionType.fill, label: "Kolo" },
  { value: EProvisionType.split, label: "Halkaistu" },
];

interface IProvision {
  id: string;
  type?: EProvisionType;
  edge1: string;
  edge2: string;
  count: string;
  grossAreaPerCount: string;
  provisionNumber?: number;
}

export default IProvision;
