import { useCallback } from 'react';
import { ISignal, useCustomFetch } from "../custom-fetch";
import ISteel from '../shared/ISteel';

interface IGetSteel extends ISignal {
  id: string;
}

export const useSteelService = () => {
  const customFetch = useCustomFetch();

  const fetchSteels = useCallback(async ({ signal }: ISignal, archived?: boolean) => {
    const [steels] = await customFetch<ISteel[]>(`/api/steels/list${archived !== undefined ? `?archived=${archived}`:""}`, { signal });
    return steels;
  }, [customFetch]);
  
  const getSteel = useCallback(async ({ signal, id }: IGetSteel) => {
    const [steel] = await customFetch<ISteel>("/api/steels/get/" + id, { signal });
    return steel;
  }, [customFetch]);
  
  const saveSteel = useCallback(async (data: ISteel) => {
    const [steel] = await customFetch<ISteel>("/api/steels/add", {
      method: "POST",
      body: JSON.stringify(data),
    });
    return steel;
  }, [customFetch]);
  
  const updateSteel = useCallback(async (id: string, data: ISteel) => {
    const [steel] = await customFetch<ISteel>("/api/steels/update/" + id, {
      method: "PUT",
      body: JSON.stringify(data),
    });
    return steel;
  }, [customFetch]);
  
  const deleteSteel = useCallback(async (id: string) => {
    await customFetch<ISteel>("/api/steels/delete/" + id, {
      method: "DELETE"
    });
    return true;
  }, [customFetch]);
  
  return { fetchSteels, getSteel, saveSteel, updateSteel, deleteSteel};
}