import { useQuery } from "@tanstack/react-query";
import IProject from "../../shared/IProject";
import Spinner from "../ui/Spinner/Spinner";
import Table from "../ui/Table/Table";
import { ErrorResponse } from "../../custom-fetch";
import { QueryKey } from "../../services/query-keys";
import { useProjectsService } from "../../services/projects-service";
import ErrorsAlert from "../ErrorsAlert/ErrorsAlert";
import { useNavigate } from "react-router-dom";
import { Route } from "../../routes";
import { formatDate } from "../../utils/date-utils";

const ProjectsList: React.FC = () => {
  const { fetchProjects } = useProjectsService();

  const navigate = useNavigate();
  const {
    data: projects,
    isPending,
    isError,
    error,
  } = useQuery<IProject[], ErrorResponse>({
    queryKey: [QueryKey.projects],
    queryFn: fetchProjects,
    staleTime: 5000,
  });

  if (isError) {
    return <ErrorsAlert errors={error.messages} />;
  }

  if (isPending) {
    return <Spinner />;
  }

  if (!projects || projects.length === 0) {
    return <p>Ei työmaita</p>;
  }

  const openHandler = (id: string) => {
    navigate(Route.project(id));
  };

  return (
    <>
      <Table hover>
        <thead>
          <tr>
            <th>Luontipvm</th>
            <th>Työmaan nro</th>
            <th>Työmaan nimi</th>
            <th>Kohteen nimi</th>
            <th>Asiakkaan nimi</th>
            <th>Myyjä</th>
          </tr>
        </thead>
        <tbody>
          {projects.map((project) => (
            <tr key={project.id} onClick={() => openHandler(project.id)}>
              <td>{formatDate(project.created)}</td>
              <td>{project.offer?.projectNumber} / {project.offer?.offerNumber}</td>
              <td>{project.name}</td>
              <td>{project.offer?.targetName}</td>
              <td>{project.offer?.customer?.name}</td>
              <td>{project.offer?.seller?.name}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default ProjectsList;
