import { useNavigate } from "react-router-dom";
import Table from "../ui/Table/Table";
import { Route } from "../../routes";
import IMesh from "../../shared/IMesh";

interface IProps {
  meshes: IMesh[];
}

const MeshTable: React.FC<IProps> = ({ meshes }) => {
  const navigate = useNavigate();
  const openHandler = (id: string) => {
    navigate(Route.mesh(id));
  };
  return (
    <Table hover>
      <thead>
        <tr>
          <th>Tunnus</th>
          <th>Tyyppi</th>
          <th>Neliöpaino</th>
          <th>Painohinta</th>
          <th>Päivitetty</th>
          <th>Arkistoitu</th>
        </tr>
      </thead>
      <tbody>
        {meshes.map((mesh) => (
          <tr key={mesh.id} onClick={() => openHandler(mesh.id)} style={{ color: mesh.archived ? 'gray' : 'inherit', fontStyle: mesh.archived ? "italic" : 'inherit' }}>
            <td>{mesh.unitName}</td>
            <td>{mesh.type}</td>
            <td>{mesh.weightSquare}</td>
            <td>{mesh.weightPrice} €</td>
            <td>{mesh.updatedString}</td>
            <td>{mesh.archived && "arkistoitu"}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default MeshTable;
