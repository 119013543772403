import { useCallback } from 'react';
import { ISignal, useCustomFetch } from "../custom-fetch";
import IOfferTerm, { EOfferTermType } from '../shared/IOfferTerm';
import IOfferRow from '../shared/IOfferRow';

interface IGetOfferTerm extends ISignal {
  id: string;
}

export const useOfferTermService = () => {
  const customFetch = useCustomFetch();

  const fetchOfferTerms = useCallback(async ({ signal }: ISignal, archived?: boolean, type?: EOfferTermType, defaultValue?: boolean) => {
    let url = '/api/offer-terms/list';
    const params = new URLSearchParams();
    if (archived !== undefined) {
      params.append('archived', String(archived));
    }
    if (type) {
      params.append('type', type);
    }
    if (defaultValue !== undefined) {
      params.append('defaultValue', String(defaultValue));
    }
    if (params.toString()) {
      url += `?${params.toString()}`;
    }
    const [offerTerms] = await customFetch<IOfferTerm[]>(url, { signal });
    return offerTerms;
}, [customFetch]);
  
  const getOfferTerm = useCallback(async ({ signal, id }: IGetOfferTerm) => {
    const [offerTerm] = await customFetch<IOfferTerm>("/api/offer-terms/get/" + id, { signal });
    return offerTerm;
  }, [customFetch]);
  
  const saveOfferTerm = useCallback(async (data: IOfferTerm) => {
    const [offerTerm] = await customFetch<IOfferTerm>("/api/offer-terms/add", {
      method: "POST",
      body: JSON.stringify(data),
    });
    return offerTerm;
  }, [customFetch]);
  
  const updateOfferTerm = useCallback(async (id: string, data: IOfferTerm) => {
    const [offerTerm] = await customFetch<IOfferTerm>("/api/offer-terms/update/" + id, {
      method: "PUT",
      body: JSON.stringify(data),
    });
    return offerTerm;
  }, [customFetch]);
  
  const deleteOfferTerm = useCallback(async (id: string) => {
    await customFetch<IOfferTerm>("/api/offer-terms/delete/" + id, {
      method: "DELETE"
    });
    return true;
  }, [customFetch]);

  interface IGetConditionalTermsForOfferRows {
    offerRows: IOfferRow[];
    existingConditionalTerms: IOfferTerm[];
  }
  const getConditionalTermsForOfferRows = useCallback(async (data: IGetConditionalTermsForOfferRows) => {
    const [offerTerms] = await customFetch<IOfferTerm[]>("/api/offer-terms/conditional", {
      method: "POST",
      body: JSON.stringify(data),
    });
    return offerTerms;
  }, [customFetch]);
  
  return { fetchOfferTerms, getOfferTerm, saveOfferTerm, updateOfferTerm, deleteOfferTerm, getConditionalTermsForOfferRows};
}