import React, { ReactNode } from "react";
import Button, { EButtonSize } from "../Button/Button";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

interface IProps {
  variant?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  children: ReactNode;
  onAdd?: () => void;
  noHorizontalMargin?: boolean;
  style?: React.CSSProperties;
}

const PageHeading: React.FC<IProps> = ({ variant = "h1", children, onAdd, noHorizontalMargin, style }) => {
  const Variant = variant;
  return (
    <Variant
      style={{
        margin: noHorizontalMargin ? "1rem 0" : "1rem",
        display: "flex",
        gap: "1rem",
        alignItems: "center",
        ...style,
      }}
    >
      {children}{" "}
      {onAdd && (
        <Button
          size={EButtonSize.SMALL}
          onClick={onAdd}
          icon={faPlus}
          style={{ fontSize: "initial" }}
        >
          Lisää uusi
        </Button>
      )}
    </Variant>
  );
};

export default PageHeading;
