import { useQuery } from "@tanstack/react-query";
import { useCallback, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ErrorsAlert, {
  combineErrors,
} from "../components/ErrorsAlert/ErrorsAlert";
import ProductionLineEdit, { IProductionLineHandle } from "../components/ProductionLines/ProductionLineEdit/ProductionLineEdit";
import Container from "../components/ui/Container/Container";
import PageHeading from "../components/ui/PageHeading/PageHeading";
import Spinner from "../components/ui/Spinner/Spinner";
import { ErrorResponse } from "../custom-fetch";
import { useProductionLinesService } from "../services/productionLines-service";
import { QueryKey } from "../services/query-keys";
import IProductionLine from "../shared/IProductionLine";
import IElement from "../shared/IElement";
import { useElementsService } from "../services/elements-service";
import Button, { EButtonColor } from "../components/ui/Button/Button";

const ProductionLinePage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const isEdit = useMemo(() => id !== "add", [id]);
  const navigate = useNavigate();

  const { productionLine, isPending, isError, error } = useGetProductionLine(id!, isEdit);
  const { elements, isElementsPending, isElementsError, elementsError } = useFetchProductionLineElements(id!, isEdit, isPending);

  const errorMessages = combineErrors({ isError, error }, { isError: isElementsError, error: elementsError });
  const productionLineEditRef = useRef<IProductionLineHandle>(null);
  const [loading, setLoading] = useState(false);

  const submitHandler = useCallback(async () => {
    setLoading(true);
    try {
      await productionLineEditRef.current?.submitHandler();
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  }, []);

  if (errorMessages.length > 0) {
    return <ErrorsAlert errors={errorMessages} />;
  }

  if (isEdit && (isPending || isElementsPending)) {
    return <Spinner />;
  }

  return (
    <>
      <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
        <PageHeading>Peti {productionLine?.productionLineNumber}</PageHeading>
        <Button onClick={submitHandler} loading={loading}>Tallenna</Button>
        <Button onClick={() => navigate(-1)} color={EButtonColor.SECONDARY}>Palaa</Button>
      </div>
      <Container>
        <ProductionLineEdit
          id={id!}
          isEdit={isEdit}
          productionLine={productionLine}
          productionLineElements={elements ?? []}
          ref={productionLineEditRef}
        />
      </Container>
    </>
  );
};

const useGetProductionLine = (id: string, isEdit: boolean) => {
  const { getProductionLine } = useProductionLinesService();

  const {
    data: productionLine,
    isPending,
    isError,
    error,
  } = useQuery<IProductionLine, ErrorResponse>({
    queryKey: [QueryKey.productionLines, id],
    queryFn: ({ signal }) => getProductionLine({ signal, id: id! }),
    enabled: isEdit,
  });

  return { productionLine, isPending, isError, error };
};

const useFetchProductionLineElements = (
  productionLineId: string,
  isEdit: boolean,
  isGetPending: boolean,
) => {
  const { fetchElements } = useElementsService();

  const {
    data: elements,
    isPending: isElementsPending,
    isError: isElementsError,
    error: elementsError,
  } = useQuery<IElement[], ErrorResponse>({
    queryKey: [QueryKey.elements, productionLineId],
    queryFn: ({ signal }) =>
      fetchElements({ signal, search: { productionLineId } }),
    enabled: isEdit && !isGetPending,
  });

  return { elements, isElementsPending, isElementsError, elementsError };
};

export default ProductionLinePage;
