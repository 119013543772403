import { useCallback } from 'react';
import { ISignal, useCustomFetch } from "../custom-fetch";
import IMesh from '../shared/IMesh';

interface IGetMesh extends ISignal {
  id: string;
}

export const useMeshService = () => {
  const customFetch = useCustomFetch();

  const fetchMeshes = useCallback(async ({ signal }: ISignal, archived?: boolean) => {
    const [meshes] = await customFetch<IMesh[]>(`/api/meshes/list${archived !== undefined ? `?archived=${archived}`:""}`, { signal });
    return meshes;
  }, [customFetch]);
  
  const getMesh = useCallback(async ({ signal, id }: IGetMesh) => {
    const [mesh] = await customFetch<IMesh>("/api/meshes/get/" + id, { signal });
    return mesh;
  }, [customFetch]);
  
  const saveMesh = useCallback(async (data: IMesh) => {
    const [mesh] = await customFetch<IMesh>("/api/meshes/add", {
      method: "POST",
      body: JSON.stringify(data),
    });
    return mesh;
  }, [customFetch]);
  
  const updateMesh = useCallback(async (id: string, data: IMesh) => {
    const [mesh] = await customFetch<IMesh>("/api/meshes/update/" + id, {
      method: "PUT",
      body: JSON.stringify(data),
    });
    return mesh;
  }, [customFetch]);
  
  const deleteMesh = useCallback(async (id: string) => {
    await customFetch<IMesh>("/api/meshes/delete/" + id, {
      method: "DELETE"
    });
    return true;
  }, [customFetch]);
  
  return { fetchMeshes, getMesh, saveMesh, updateMesh, deleteMesh};
}