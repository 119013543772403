export class QueryKey {
  static users = '/users'
  static customers = '/customers'
  static engineers = '/engineers'
  static steelParts = '/steel-parts'
  static offers = '/offers'
  static organizations = '/organizations'
  static insulations = '/insulations'
  static meshes = '/meshes'
  static steels = '/steels'
  static concreteTypes = '/concrete-types'
  static transportationCosts = '/transportation-costs'
  static slabTypes = '/slab-types'
  static productGroups = '/product-groups'
  static productTypes = '/product-types'
  static offerTerms = '/offer-terms'
  static latest5OfferNumbers = '/latest-5-offer-numbers'
  static instalmentTables = '/instalment-tables'
  static customerOffers = '/customer-offers'
  static factories = '/factories'
  static claims = '/claims'
  static projects = '/projects'
  static waybills = '/waybills'
  static weeklyProductionRows = '/weeklyProductionRows'
  static weeklyProductionRowsByWeek = '/weeklyProductionRowsByWeek'
  static weeklyProductionRowsByProject = '/weeklyProductionRowsByProject'
  static additionalInvoices = '/additionalInvoices'
  static elements = '/elements'
  static productionLines = '/production-lines'
  static weeklyProductionLines = '/weekly-production-lines'

  private static options = '/options'
  static steelPartOptions = this.options + this.steelParts
  static insulationOptions = this.options + this.insulations
  static meshOptions = this.options + this.meshes
  static steelOptions = this.options + this.steels
  static concreteTypeOptions = this.options + this.concreteTypes
  static slabTypeOptions = this.options + this.slabTypes
  static productGroupOptions = this.options + this.productGroups
  static productTypeOptions = this.options + this.productTypes
  static offerOptions = this.options + this.offers
  static projectOptions = this.options + this.projects
  static elementOptions = this.options + this.elements
}
