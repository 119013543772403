import { useQuery } from '@tanstack/react-query';
import { useFactoryService } from '../../services/factory-service';
import IWaybill from '../../shared/IWaybill';
import IFactory from '../../shared/IFactory';
import { ErrorResponse } from '../../custom-fetch';
import { QueryKey } from '../../services/query-keys';
import classes from './WaybillPrint.module.scss';
import OfferHeader from '../Offers/OfferHeader/OfferHeader';
import imgLogo from '../../assets/logo.png';
import SquareFI from '../SquareLogos/SquareFI';
import SquareSFS from '../SquareLogos/SquareSFS';

interface IProps {
  waybill: IWaybill;
  logoSfs?: boolean
}
const WaybillPrintHeader: React.FC<IProps> = ({ waybill, logoSfs }) => {
  const { getFactoryByFactoryId } = useFactoryService();
  
  const {
    data: factory,
    isLoading,
  } = useQuery<IFactory, ErrorResponse>({
    queryKey: [QueryKey.factories, waybill.factory],
    queryFn: ({ signal }) => getFactoryByFactoryId({ signal, id: waybill.factory }),
    enabled: !!waybill.factory,
  });

  return (
    <>
      <OfferHeader
        onSubmit={() => window.print()}
        submitText="Tulosta"
        isLoading={isLoading}
        title="Tulosta"
      />
      <div className={classes.formRow}>
        <div style={{width: "25%"}}>
          <div>
            <img src={imgLogo} alt="Pielisen Betoni" style={{ maxHeight: "3rem" }} />
          </div>
          <div>{factory?.streetAddress}</div>
          <div>{factory?.zip} {factory?.city}</div>
        </div>
        <div style={{width: "35%"}}>
          <div>Puh: {factory?.phoneNumber}</div>
          <div>Lähettämö: {factory?.phoneNumberShipping}</div>
          <div>Fax: {factory?.fax}</div>
          <div>Email: {factory?.email}</div>
          <div>Y-tunnus: 2872212-8 ALV rek.</div>
        </div>
        <div style={{width: "30%"}}>
          <div>Lähete: {waybill.waybillId}</div>
          <div>Työmaa: {waybill.projectNumber}</div>
          <div>{waybill.destination}</div>
          <div>Tilausnumero: {waybill.orderNumber}</div>
        </div>
        <div style={{width: "10%"}}>
          <div style={{marginTop: "1rem"}}>{logoSfs ? <SquareSFS/> : <SquareFI/>}</div>
          <br/>
          <div>{factory?.factoryId}</div>
        </div>
      </div>
    </>
  )
}

export default WaybillPrintHeader;