import { useQuery } from "@tanstack/react-query";
import { useParams, useSearchParams } from "react-router-dom";
import { QueryKey } from "../services/query-keys";
import ErrorsAlert, { combineErrors } from "../components/ErrorsAlert/ErrorsAlert";
import { ErrorResponse } from "../custom-fetch";
import Spinner from "../components/ui/Spinner/Spinner";
import Container from "../components/ui/Container/Container";
import { useWaybillsService } from "../services/waybills-service";
import IWaybill from "../shared/IWaybill";
import WaybillEdit from "../components/Waybills/WaybillEdit";
import { useMemo } from "react";

type Params = {
  id: string;
};

const WaybillPage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const projectId = useMemo(() => searchParams.get("projectId"), [searchParams]);
  const { id } = useParams<Params>();
  const { getWaybill } = useWaybillsService();
  
  const {
    data: waybill,
    isLoading,
    isError,
    error,
  } = useQuery<IWaybill, ErrorResponse>({
    queryKey: [QueryKey.waybills, id],
    queryFn: ({ signal }) => getWaybill({ signal, id: id! }),
    enabled: id !== 'add',
  });

  const errorMessages = combineErrors({ isError, error });
  return (
    <>
      <h1 style={{ margin: "1rem" }}>Rahtikirja</h1>
      <Container>
        {errorMessages.length > 0 ? (
          <ErrorsAlert errors={errorMessages} />
        ) : isLoading ? (
          <Spinner />
        ) : (
          <WaybillEdit id={id!} waybill={waybill} projectId={projectId} />
        )}
      </Container>
    </>
  );
};

export default WaybillPage;
