import Container from "../components/ui/Container/Container";
import Button, { EButtonSize } from "../components/ui/Button/Button";
import { faFile, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { Route } from "../routes";
import InsulationsList from "../components/Insulations/InsulationsList";

const InsulationsPage: React.FC = () => {
  const navigate = useNavigate();
  return (
    <>
      <h1
        style={{
          margin: "1rem",
          display: "flex",
          gap: "1rem",
          alignItems: "center",
        }}
      >
        Eristeet{" "}
        <Button
          size={EButtonSize.SMALL}
          onClick={() => navigate(Route.insulation("add"))}
          icon={faPlus}
          style={{ fontSize: "initial" }}
        >
          Lisää uusi
        </Button>
        <Button
          size={EButtonSize.SMALL}
          onClick={() => navigate(Route.insulationsImport)}
          icon={faFile}
          style={{ fontSize: "initial" }}
        >
          Päivitä tiedostosta
        </Button>
      </h1>
      <Container>
        <InsulationsList />
      </Container>
    </>
  );
};

export default InsulationsPage;
