import { forwardRef, useCallback, useImperativeHandle, useRef } from "react";
import { v4 as uuid } from "uuid";
import { useSteelOptions } from "../../../../hooks/useSteelOptions";
import { useOfferService } from "../../../../services/offers-service";
import IOfferRow from "../../../../shared/IOfferRow";
import Button from "../../../ui/Button/Button";
import { TInputValue } from "../../../ui/Input/Input";
import InputContextProvider, {
  EInputUpdateAction,
} from "../../../ui/Input/input-context";
import { formatInputValue } from "../../../ui/Input/input-utils";
import Table from "../../../ui/Table/Table";
import { useOfferRowCalculationContext } from "../offer-row-calculation-context";
import OfferRowCalculationSteel, {
  EInputs,
  IOfferRowCalculationSteelRow,
} from "./OfferRowCalculationSteel";
import SteelTotalTable from "./SteelTotalTable";
import { RefHandle } from "../../../../shared/RefHandle";
import { useOfferProductsContext } from "../../OfferProducts/offer-products-context";

export interface IOfferRowCalculationSteel {
  price: string;
  weight: string;
  rows: IOfferRowCalculationSteelRow[];
  // tendon: IOfferRowCalculationSteelTendon;
  // elementLength?: string; // only for calculation
}

export const createSteelRow = (): IOfferRowCalculationSteelRow => {
  const id = uuid();
  return { id, unitName: "", steelId: "", weight: "0", price: "0", quantity: "0", length: "0", diameter: "0", steelWeight: "0", steelPrice: "0" };
};

const OfferRowCalculationSteels: React.ForwardRefRenderFunction<RefHandle<IOfferRowCalculationSteel>> = (_, ref) => {
  const { offerRow, updateOfferRow } = useOfferRowCalculationContext();
  const { offerRowCalculationSteel: steel } = offerRow;
  const { addHandler, deleteHandler, updateHandler } = useHandlers(steel, updateOfferRow);

  const { showControls } = useOfferProductsContext();
  const steelRowsRef = useRef<RefHandle<IOfferRowCalculationSteelRow>[]>([]);

  useImperativeHandle(ref, () => ({
    getData: async () => {
      const steelRows = steelRowsRef.current;
      const newRows: IOfferRowCalculationSteelRow[] = [];
      for (let i = 0; i < steelRows.length; i++) {
        const row = steelRows[i];
        if (row) {
          const rowData = await row.getData();
          if (rowData) {
            newRows.push(rowData);
          }
        }
      }
      return { ...steel, rows: [...newRows] };
    },
  }), [steel]);

  return (
    <InputContextProvider initDone onAutoUpdate={updateHandler}>
      <SteelTotalTable calculation={steel} total={offerRow.offerRowCalculationTotal} />
      <Table removeGap>
        <thead>
          <tr>
            <th>Nimike</th>
            <th>Halk</th>
            <th>kpl</th>
            <th>Pit/m</th>
            <th>€/kg</th>
            <th>kg/m</th>
            <th>Kg</th>
            <th>€</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {steel.rows?.map((calculation, i) => (
            <OfferRowCalculationSteel
              key={calculation.id}
              calculation={calculation}
              onDelete={() => deleteHandler(calculation.id)}
              ref={rowHandle => steelRowsRef.current[i] = rowHandle!}
              showControls={showControls}
            />
          ))}
          {showControls && (
            <tr>
              <td colSpan={9}>
                <Button onClick={addHandler}>Uusi</Button>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </InputContextProvider>
  );
};

const useHandlers = (calculation: IOfferRowCalculationSteel, updateOfferRow: (newRow: Partial<IOfferRow>) => void) => {
  const { calculateOfferSteel } = useOfferService();
  const { steels } = useSteelOptions();

  const addHandler = useCallback(() => {
    const offerRowCalculationSteel = {
      ...calculation,
      rows: [...calculation.rows, createSteelRow()],
    };
    updateOfferRow({ offerRowCalculationSteel });
  }, [calculation, updateOfferRow]);

  const deleteHandler = useCallback((id: string) => {
    const offerRowCalculationSteel = {
      ...calculation,
      rows: calculation.rows.filter((i) => i.id !== id),
    };
    updateOfferRow({ offerRowCalculationSteel });    
  }, [calculation, updateOfferRow]);

  const updateHandler = useCallback(
    async (inputName: string, value: TInputValue, action: string, _: any) => {
      if (action === EInputUpdateAction.STEEL) {
        const data = _ as IOfferRowCalculationSteelRow;
        if (inputName === EInputs.steelId) {
          const selectedSteel = steels?.find((s) => s.id === value);
          data.steelWeight = selectedSteel?.weight ?? "0";
          data.steelPrice = selectedSteel?.price ?? "0";
        }
        const index = calculation.rows.findIndex((r) => r.id === data.id);
        if (index > -1) {
          calculation.rows[index] = {
            ...data,
            [inputName]: formatInputValue(value),
          };
        }
        const offerRowCalculationSteel = await calculateOfferSteel(calculation);
        updateOfferRow({ offerRowCalculationSteel });
      }
      return Promise.resolve(true);
    },
    [calculateOfferSteel, calculation, steels, updateOfferRow]
  );

  return { addHandler, deleteHandler, updateHandler };
};

export default forwardRef(OfferRowCalculationSteels);
