import { useNavigate } from "react-router-dom";
import Table from "../ui/Table/Table";
import { Route } from "../../routes";
import IConcreteType from "../../shared/IConcreteType";

interface IProps {
  concreteTypes: IConcreteType[];
}

const ConcreteTypesTable: React.FC<IProps> = ({ concreteTypes }) => {
  const navigate = useNavigate();
  const openHandler = (id: string) => {
    navigate(Route.concreteType(id));
  };
  return (
    <Table hover>
      <thead>
        <tr>
          <th>Tunnus</th>
          <th>Paino kg /m³</th>
          <th>Puristuslujuus</th>
          <th>Hinta € /m³</th>
          <th>Päivitetty</th>
          <th>Arkistoitu</th>
        </tr>
      </thead>
      <tbody>
        {concreteTypes.map((concreteType) => (
          <tr
            key={concreteType.id}
            onClick={() => openHandler(concreteType.id)}
            style={{
              color: concreteType.archived ? "gray" : "inherit",
              fontStyle: concreteType.archived ? "italic" : "inherit",
            }}
          >
            <td>{concreteType.unitName}</td>
            <td>{concreteType.weight}</td>
            <td>{concreteType.compressionStrength}</td>
            <td>{concreteType.price} €</td>
            <td>{concreteType.updatedString}</td>
            <td>{concreteType.archived && "arkistoitu"}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default ConcreteTypesTable;
