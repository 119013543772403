import { useQuery } from "@tanstack/react-query";

import { ErrorResponse } from "../../custom-fetch";
import { QueryKey } from "../../services/query-keys";
import ISlabType from "../../shared/ISlabType";
import ErrorsAlert from "../ErrorsAlert/ErrorsAlert";
import Spinner from "../ui/Spinner/Spinner";
import { useState } from "react";
import Input, { EInputType } from "../ui/Input/Input";
import { useSlabTypeService } from "../../services/slabTypes-service";
import Table from "../ui/Table/Table";
import { Route } from "../../routes";
import { useNavigate } from "react-router-dom";

const SlabTypesList: React.FC = () => {
  const { fetchSlabTypes } = useSlabTypeService();
  const [filterValue, setFilterValue] = useState<string>('');
  const navigate = useNavigate();

  const openHandler = (id: string) => {
    navigate(Route.slabType(id));
  };

  const {
    data: slabTypes,
    isPending,
    isError,
    error,
  } = useQuery<ISlabType[], ErrorResponse>({
    queryKey: [QueryKey.slabTypes],
    queryFn: fetchSlabTypes,
    staleTime: 5000,
  });

  if (isError) {
    return <ErrorsAlert errors={error.messages} />;
  }

  if (isPending) {
    return <Spinner />;
  }

  if (!slabTypes || slabTypes.length === 0) {
    return <p>Ei laattoja</p>;
  }

  const filteredSlabTypes = slabTypes.filter(
    (slabType) =>
      slabType.typeName?.toLowerCase().includes(filterValue)
  );

  return (
    <>
      <Input
        inputName="slabTypesFilter"
        type={EInputType.text}
        placeholder="Suodata nimellä"
        value={filterValue}
        onChange={(value) => setFilterValue(value.toString().toLowerCase())}
        autoFocus={true}
      />
      <Table hover>
        <thead>
          <tr>
            <th>Nimi</th>
            <th>Neliöpaino</th>
            <th>Juoksumetripaino</th>
            <th>Paksuus</th>
            <th>Neliöhinta</th>
            <th>Päivitetty</th>
            <th>Arkistoitu</th>
          </tr>
        </thead>
        <tbody>
          {filteredSlabTypes.map((slabType) => (
            <tr
              key={slabType.id}
              onClick={() => openHandler(slabType.id)}
              style={{
                color: slabType.archived ? "gray" : "inherit",
                fontStyle: slabType.archived ? "italic" : "inherit",
              }}
            >
              <td>{slabType.typeName}</td>
              <td>{slabType.netWeight}</td>
              <td>{slabType.meterWeight}</td>
              <td>{slabType.thickness}</td>
              <td>{slabType.squarePrice} €</td>
              <td>{slabType.updatedString}</td>
              <td>{slabType.archived && "arkistoitu"}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default SlabTypesList;
