import { useQuery } from '@tanstack/react-query';
import { useFactoryService } from '../../services/factory-service';
import IFactory from '../../shared/IFactory';
import { ErrorResponse } from '../../custom-fetch';
import { QueryKey } from '../../services/query-keys';
import classes from './ElementPrint.module.scss';
import imgLogo from '../../assets/logo.png';
import imgCeLogo from '../../assets/ce-logo.svg';
import IElement from '../../shared/IElement';
import QRCode from 'react-qr-code';
import { adminUiUrl } from '../../config';
import SquareFI from '../SquareLogos/SquareFI';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faSquare, faSquareCheck } from '@fortawesome/free-solid-svg-icons';
import IProductType from '../../shared/IProductType';
import { HOLLOWCORE_PRODUCT_GROUP_NAME } from '../../shared/IProductGroup';
import { useProjectsService } from '../../services/projects-service';
import IProject from '../../shared/IProject';

interface IProps {
  element: IElement;
}
const ElementPrintExample: React.FC<IProps> = ({ element }) => {
  const { getFactoryByFactoryId } = useFactoryService();
  const { getProject } = useProjectsService();
  
  const {
    data: factory,
    //isLoading,
  } = useQuery<IFactory, ErrorResponse>({
    queryKey: [QueryKey.factories, element.factory],
    queryFn: ({ signal }) => getFactoryByFactoryId({ signal, id: element.factory?.toString()! }),
    enabled: !!element.factory,
  });

  const {
    data: project,
    //isLoading,
  } = useQuery<IProject, ErrorResponse>({
    queryKey: [QueryKey.projects, element.projectId],
    queryFn: ({ signal }) => getProject({ signal, id: element.projectId! }),
    enabled: !!element.projectId,
  });

  return (
    <>
      <div className={classes.container}>
        <div className={classes.row}>
          <div style={{width: "40%"}}>
            <p>Pituus:</p>
            <p>Tunnus:</p>
          </div>
          <div style={{width: "60%"}}>
            <p>{element.length}</p>
            <p>{element.name}</p>
          </div>
        </div>
        { (element.productType as IProductType).productGroup.name === HOLLOWCORE_PRODUCT_GROUP_NAME &&
        <div style={{borderStyle: "solid", borderWidth: "1px", marginBottom: "0.5rem"}}>
          <div className={classes.smallFont} style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", paddingTop: "0.5rem"}}>
            <div><img src={imgCeLogo} alt="CE" style={{ maxHeight: "3rem" }} /></div>
            <p>?15?</p>
            <div style={{ width: "100%", border: "none", borderTop: "1px solid" }} />
            <p>Pielisen Betoni Oy</p>
            <p>{factory?.streetAddress} {factory?.zip} {factory?.name}</p>
            <p>www.pielisenbetoni.fi</p>
            <p>
              <span>{element?.projectNumber}{element?.phaseName ? '/' +element?.phaseName : "" }:{element.name}</span>
              <span style={{marginLeft: "2rem"}}>?Dop 56.1?</span>
            </p>
            <p style={{marginTop: "1rem"}}>{factory?.ceId}{factory?.cett2}</p>
            <div style={{ width: "100%", border: "none", borderTop: "1px solid" }} />
            <p>{factory?.hollowStandardCett1}</p>
          </div>
        </div>
        }
        <div className={classes.row}>
          <div style={{width: "50%"}}>
            <img src={imgLogo} alt="Pielisen Betoni" style={{ maxHeight: "3rem" }} />
          </div>
          <div style={{width: "20%"}}>
            <div style={{marginLeft: "1rem", marginTop: "0.3rem"}}><SquareFI/></div>
          </div>
          <div style={{width: "30%"}}>
            <p className={classes.smallFont}>?24069 / 13?</p>
          </div>
        </div>
        <p className={classes.smallFont} style={{marginTop: "0.5rem"}}>{factory?.zip} {factory?.name} puh {factory?.phoneNumber}</p>
        <div className={classes.row}>
          <div style={{width: "40%"}}>
            <p>Paino:</p>
            <p>Pituus:</p>
            <p>Halkaisu:</p>
            <p>Varauksia:</p>
            <p>Syvenn.:</p>
            <p>Vino O/V:</p>
          </div>
          <div style={{width: "30%"}}>
            <p>{element.weightTons}</p>
            <p>{element.length}</p>
            <p>{element.width}</p>
            <p>todo</p>
            <p>todo</p>
            <p>todo
              {/*<FontAwesomeIcon icon={faSquare}/> <FontAwesomeIcon icon={faSquareCheck}/>*/}</p>
          </div>
          <div style={{width: "30%"}}>
            <p>t</p>
            <p>mm</p>
            <p>mm</p>
            <p>kpl</p>
            <p>kpl</p>
            <p></p>
          </div>
        </div>

        <div className={classes.row} style={{fontSize: "1.5rem", background: "darkgray"}}>
          <p>{element.name}</p>
        </div>

        <div className={classes.row}>
          <div style={{width: "70%"}}>
            <div className={classes.row}>
              <div style={{width: "55%"}}>
                <p>Työmaa:</p>
              </div>
              <div style={{width: "45%"}}>
                <p>{element?.projectNumber}{element?.phaseName ? '/' +element?.phaseName : "" }</p>
              </div>
            </div>
            <p>{project?.name}</p>
            <div className={classes.row}>
              <div style={{width: "55%"}}>
                <p>Valu pvm:</p>
                <p>Tehdas:</p>
              </div>
              <div style={{width: "45%"}}>
                <p>{element.productionLineStartDate}</p>
                <p>{factory?.factoryId}</p>
              </div>
            </div>
            <p>P {element.length} / {element.weightTons} t</p>
          </div>
          <div style={{width: "30%"}}>
            <QRCode style={{height: "96px", width: "96px", marginTop: "1rem"}} value={`${adminUiUrl}/qr/element/example`}></QRCode>
          </div>
        </div>
      </div>
    </>
  )
}

export default ElementPrintExample;