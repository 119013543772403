import Container from "../components/ui/Container/Container";
import { useNavigate } from "react-router-dom";
import { Route } from "../routes";
import PageHeading from "../components/ui/PageHeading/PageHeading";
import SteelsList from "../components/Steels/SteelsList";

const SteelsPage: React.FC = () => {
  const navigate = useNavigate();

  const addHandler = () => {
    navigate(Route.steel("add"));
  }

  return (
    <>
      <PageHeading onAdd={addHandler}>Teräkset</PageHeading>
      <Container>
        <SteelsList />
      </Container>
    </>
  );
};

export default SteelsPage;
