import { useMutation } from "@tanstack/react-query";
import { forwardRef, useCallback, useImperativeHandle, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { queryClient } from "../../../App";
import { ErrorResponse } from "../../../custom-fetch";
import { Route } from "../../../routes";
import { useElementsService } from "../../../services/elements-service";
import { useProductionLinesService } from "../../../services/productionLines-service";
import { QueryKey } from "../../../services/query-keys";
import { FactoryOptions } from "../../../shared/FactoryOptions";
import IElement from "../../../shared/IElement";
import IProductionLine from "../../../shared/IProductionLine";
import IProductType from "../../../shared/IProductType";
import ElementsDragAndDropTable, { calculateTotalLength } from "../../Elements/ElementsDragAndDropTable";
import ElementsList from "../../Elements/ElementsList";
import ElementsSearch, { IElementsSearch } from "../../Elements/ElementsSearch";
import ErrorsAlert, { combineErrors } from "../../ErrorsAlert/ErrorsAlert";
import Button, { EButtonColor } from "../../ui/Button/Button";
import { ECommonValue, EInputType, IInputField } from "../../ui/Input/Input";
import { useInputs } from "../../ui/Input/useInputs";
import InputGroup from "../../ui/InputGroup/InputGroup";
import PageHeading from "../../ui/PageHeading/PageHeading";

enum EInputs {
  productionLineLength = "productionLineLength",
  startDate = "startDate",
  endDate = "endDate",
  status = "status",
  factory = "factory",
  position = "position",
  brushed = "brushed",
}

interface IProps {
  id: string;
  isEdit: boolean;
  productionLine?: IProductionLine;
  productionLineElements: IElement[];
}

export interface IProductionLineHandle {
  submitHandler: () => Promise<void>;
};

const ProductionLineEdit: React.ForwardRefRenderFunction<IProductionLineHandle, IProps> = ({ id, isEdit, productionLine, productionLineElements }, ref) => {
  const { createInput, submit, inputs } = useProductionLineInputs(productionLine);

  const [elements, setElements] = useState<IElement[]>(productionLineElements);
  const [search, setSearch] = useState<IElementsSearch | null | undefined>(null);

  const hasElements = useMemo(() => elements.length > 0, [elements.length]);
  const typeName = useMemo(() => hasElements ? (elements[0].productType as IProductType).name : "", [hasElements, elements]);
  const height = useMemo(() => hasElements ? elements[0].height : "", [hasElements, elements]);
  // const deliveryWeek = useMemo(() => hasElements && elements[0].deliveryWeek, [hasElements, elements]);
  
  const projectNumbers = useMemo(() => hasElements ? [...elements].map(element => element.projectNumber).filter((value, index, array) => array.indexOf(value) === index).join(", ") : "", [hasElements, elements]);
  const lineLength = useMemo(() => inputs[EInputs.productionLineLength].value as string, [inputs]);
  const factory = useMemo(() => inputs[EInputs.factory].value as string, [inputs]);

  const maxTypeName = useMemo(() => {
    if (!elements.length) return "";
    if (elements.length === 1) return elements[0].typeName ?? "";
    let newElements = [...elements];
    newElements = newElements.sort((e1, e2) => (e2.typeName ?? "").localeCompare(e1.typeName ?? ""));
    console.log("maxTypeName", newElements[0].typeName);
    return newElements[0].typeName ?? "";
  }, [elements]);

  // const minTypeName = useMemo(() => {
  //   if (!elements.length) return "";
  //   let newElements = [...elements];
  //   newElements = newElements.sort((e1, e2) => (e1.typeName ?? "").localeCompare(e2.typeName ?? ""));
  //   console.log("minTypeName", newElements[0].typeName);
  // }, [elements]);

  const onElementClick = useCallback((element: IElement) => {
    setElements((elements) => {
      let newElements = [...elements];
      const index = newElements.findIndex((el) => el.id === element.id);
      if (index > -1) {
        return [
          ...newElements
            .filter((el) => el.id !== element.id)
            .map((el, i) => ({ ...el, position: i + 1 })),
        ];
      }
      return [...newElements, { ...element, position: index + 1 }];
    });
  }, []);

  const searchHandler = useCallback((data?: IElementsSearch | null) => {
    // console.log("search", data);
    setSearch(data);
  }, []);

  const navigate = useNavigate();

  const redirectHandler = useCallback((id: string) => {
    return navigate(Route.productionLine(id));
  }, [navigate]);

  const { saveOrUpdate, isUpdating, isUpdateError, updateError, saveOrUpdateReset } = useSaveOrUpdate(id, isEdit, redirectHandler);
  const { updateElementsMutate, isUpdatingElements, isUpdateElementsError, updateElementsError } = useUpdateElements();
  const isLoading = useMemo(() => isUpdating || isUpdatingElements, [isUpdating, isUpdatingElements]);

  const submitHandler = useCallback(async () => {
    const data = await submit();
    if (!data) return;
    const linealMeters = calculateTotalLength(elements) / 1000;
    const line = await saveOrUpdate({ ...productionLine, ...data, id: isEdit ? id : undefined, typeName: maxTypeName, height, projectNumbers, linealMeters });
    if (line) {
      let deleteProductionLineElements: IElement[] = [];
      // let elementIds = [...elements].map(element => element.id);
      if (isEdit && productionLineElements.length > 0) {
        deleteProductionLineElements = [...productionLineElements]
          .filter((el1) => !elements.find((el2) => el1.id === el2.id))
          .map((element) => ({
            ...element,
            position: undefined,
            productionLineId: undefined,
            productionLineNumber: undefined,
            productionLineStartDate: undefined,
            productionLineFactory: undefined,
          }));
      }
      const updatedElements = await updateElementsMutate({
        productionLineId: line.id!,
        data: [
          ...[...elements].map((element, index) => ({
            ...element,
            position: element.position || index + 1,
            productionLineId: line.id,
          })),
          ...deleteProductionLineElements,
        ],
      });
      if (updatedElements) {
        // setElements(updatedElements.filter(element => elementIds.includes(element.id)));
      }
    }
  }, [elements, id, isEdit, productionLine, productionLineElements, saveOrUpdate, updateElementsMutate, submit, maxTypeName, height, projectNumbers]);

  const resetHandler = useCallback(() => {
    saveOrUpdateReset();
    setElements([]);
  }, [saveOrUpdateReset]);

  const reverseHandler = useCallback(() => {
    setElements(elements => [...elements].reverse().map((element, index) => ({...element, position: index + 1})));
  }, []);

  const errorMessages = combineErrors({ isError: isUpdateError, error: updateError }, { isError: isUpdateElementsError, error: updateElementsError });
  
  // lankatyyppi, sama tehdas
  const autofillHandler = useCallback(() => {
    const elementsData = queryClient.getQueryData([QueryKey.elements]) as IElement[];
    if (elementsData && elementsData.length > 0) {
      // console.log("typeName", typeName);
      // console.log("ex typeName", elementsData[0]?.typeName);
      // console.log("eq typeName", elementsData[0]?.typeName?.toLowerCase().includes(typeName.toLowerCase()));
      const potentialElements = [...elementsData].filter(element => (!element.productionLineId || element.productionLineId === id)
        && (factory ? element.factory === factory : true) 
        // TODO: vko gte eikä eq
        // && (deliveryWeek ? element.deliveryWeek === deliveryWeek : true)
        && (typeName ? element?.typeName?.toLowerCase().includes(typeName?.toLowerCase()) : true)
        && elements.findIndex(el => element.id === el.id) === -1
      );
      setElements(elements => [...elements, ...potentialElements].map((element, index) => ({ ...element, position: index + 1 })));
    }
  }, [factory, typeName, elements, id]);

  useImperativeHandle(ref, () => ({ submitHandler }), [submitHandler]);

  return (
    <>
      <div style={{ display: "flex", gap: "1rem" }}>
        <div>
          <PageHeading variant="h2" noHorizontalMargin>Pedin tiedot</PageHeading>
          <div>
            <InputGroup>
              {createInput(EInputs.factory, { containerStyles: { minWidth: "80px" } })}
              {createInput(EInputs.productionLineLength)}
              {createInput(EInputs.status)}
              {createInput(EInputs.startDate)}
              {createInput(EInputs.endDate)}
              {createInput(EInputs.position)}
              {createInput(EInputs.brushed)}
            </InputGroup>
            <InputGroup>
              {!!typeName && <p>Lankatyyppi: {maxTypeName}</p>}
              {!!height && <p>Paksuus: {height}</p>}
              {!!projectNumbers && <p>Työmaat: {projectNumbers}</p>}
            </InputGroup>
          </div>
          <hr />
          <div
          style={{
            // width: "1000px",
            // maxWidth: "1000px",
            background: "#ccc",
            padding: "1rem",
            height: "fit-content",
            // zIndex: "99",
            position: "sticky",
            top: "1rem",
            right: "0",
          }}
        >
          {errorMessages.length > 0 && <ErrorsAlert errors={errorMessages} />}
          <div style={{ display: "flex" }}>
              <PageHeading variant="h3" style={{ marginLeft: "0" }}>Valitut elementit</PageHeading>
              <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
              {hasElements && (
                <>
                  <Button onClick={autofillHandler} disabled={isLoading}>Täytä automaattisesti</Button>
                  <Button onClick={resetHandler} color={EButtonColor.SECONDARY} disabled={isLoading}>Tyhjennä</Button>
                  <Button onClick={reverseHandler} disabled={isLoading}>Käännä järjestys</Button>
                </>
              )}
              </div>
            </div>
            {hasElements ? (
              <ElementsDragAndDropTable
                elements={elements}
                setElements={setElements}
                showTotalRow
                onDelete={isLoading ? undefined : onElementClick}
                lineLength={lineLength}
              />
            ) : (
              <p>Ei valittuja elementtejä</p>
            )}
          </div>
        </div>
        <span style={{ borderLeft: "thin solid gray", height: "auto" }} />
        <div style={{ width: "800px", maxWidth: "800px" }}>
          <PageHeading variant="h2" noHorizontalMargin>Elementtien haku</PageHeading>
          <div>
            <ElementsSearch searchHandler={searchHandler} />
          </div>
          <hr />
          <ElementsList
            onClick={onElementClick}
            selectedElements={elements}
            search={search}
            productionLineId={id}
            typeName={typeName}
            height={height}
          />
        </div>
      </div>
    </>
  );
};

const useProductionLineInputs = (data?: IProductionLine) => {
  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.productionLineLength]: {
      type: EInputType.number,
      value: "",
      label: "Linjan pituus (mm)",
    },
    [EInputs.startDate]: {
      type: EInputType.date,
      value: "",
      label: "Valu pvm",
    },
    [EInputs.endDate]: {
      type: EInputType.date,
      value: "",
      label: "Purku pvm",
    },
    [EInputs.status]: {
      type: EInputType.reactSelect,
      value: "",
      label: "Tila",
    },
    [EInputs.factory]: {
      type: EInputType.reactSelect,
      options: FactoryOptions,
      value: "",
      hideControls: true,
      menuPosition: "fixed",
      placeholder: "",
      label: "Tehdas",
    },
    [EInputs.position]: {
      type: EInputType.number,
      value: "",
      label: "Järjestysnumero",
    },
    [EInputs.brushed]: {
      type: EInputType.checkbox,
      label: "Harjattu",
      options: [{ value: ECommonValue.YES }],
      value: [],
    },
  });

  const { createInput, submit } = useInputs({ data, inputs, setInputs });

  return { createInput, submit, inputs };
}

const useSaveOrUpdate = (
  id: string,
  isEdit: boolean,
  redirectHandler: (id: string) => void
) => {
  const { updateProductionLine, saveProductionLine } =
    useProductionLinesService();

  const mutationFn = (data: IProductionLine) => {
    return isEdit ? updateProductionLine(id, data) : saveProductionLine(data);
  };

  const {
    mutateAsync: saveOrUpdate,
    isPending,
    isError,
    error,
    reset,
  } = useMutation<IProductionLine, ErrorResponse, IProductionLine>({
    mutationFn,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: [QueryKey.productionLines] });
      if (data.id) {
        if (!isEdit) {
          redirectHandler(data.id);
        }
      }
    },
  });

  return {
    saveOrUpdate,
    isUpdating: isPending,
    isUpdateError: isError,
    updateError: error,
    saveOrUpdateReset: reset,
  };
};

interface IUpdateElements {
  productionLineId: string; 
  data: IElement[];
}

const useUpdateElements = () => {
  const { updateProductionLineElements } = useElementsService();

  const {
    mutateAsync: updateElementsMutate,
    isPending: isUpdatingElements,
    isError: isUpdateElementsError,
    error: updateElementsError,
  } = useMutation<IElement[], ErrorResponse, IUpdateElements>({
    mutationFn: ({ productionLineId, data }) => updateProductionLineElements(productionLineId, data),
    onSuccess: (data) => {
      const productionLineId = data[0]?.productionLineId;
      if (productionLineId) {
        queryClient.setQueryData(
          [QueryKey.elements, productionLineId],
          [
            ...data.filter(
              (element) => element.productionLineId === productionLineId
            ),
          ]
        );
      }
    },
  });

  return { updateElementsMutate, isUpdatingElements, isUpdateElementsError, updateElementsError };
};

export default forwardRef(ProductionLineEdit);
