import { useCallback } from 'react';
import { ISignal, useCustomFetch } from "../custom-fetch";
import IProject, { IProjectPhase } from '../shared/IProject';

interface IGetProject extends ISignal {
  id: string;
}

export const useProjectsService = () => {
  const customFetch = useCustomFetch();

  const fetchProjects = useCallback(async ({ signal }: ISignal) => {
    let url = "/api/projects/list";
    const [projects] = await customFetch<IProject[]>(url, { signal });
    return projects;
  }, [customFetch]);
  
  const getProject = useCallback(async ({ signal, id }: IGetProject) => {
    const [project] = await customFetch<IProject>("/api/projects/get/" + id, { signal });
    return project;
  }, [customFetch]);

  const saveProject = useCallback(async (data: IProject) => {
    const [project] = await customFetch<IProject>("/api/projects/add", {
      method: "POST",
      body: JSON.stringify(data),
    });
    return project;
  }, [customFetch]);
  
  const updateProject = useCallback(async (id: string, data: IProject) => {
    const [project] = await customFetch<IProject>("/api/projects/update/" + id, {
      method: "PUT",
      body: JSON.stringify(data),
    });
    return project;
  }, [customFetch]);
  
  const deleteProject = useCallback(async (id: string) => {
    await customFetch<IProject>("/api/projects/delete/" + id, {
      method: "DELETE"
    });
    return true;
  }, [customFetch]);

  const saveProjectPhase = useCallback(async (id: string, data: IProjectPhase) => {
    const [phase] = await customFetch<IProjectPhase>("/api/projects/phases/add/" + id, {
      method: "POST",
      body: JSON.stringify(data),
    });
    return phase;
  }, [customFetch]);
  
  return { fetchProjects, getProject, saveProject, updateProject, deleteProject, saveProjectPhase };
}
