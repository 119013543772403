import { useCallback, useRef, useState } from "react";
import { v4 as uuid } from "uuid";
import IProvision from "../../shared/IProvision";
import { RefHandle } from "../../shared/RefHandle";
import Button, { EButtonColor } from "../ui/Button/Button";
import ModalBody from "../ui/Modal/ModalBody/ModalBody";
import ModalFooter from "../ui/Modal/ModalFooter/ModalFooter";
import Table from "../ui/Table/Table";
import ProvisionEdit from "./ProvisionEdit";

const createProvision = (provisionNumber?: number): IProvision => {
  const id = uuid();
  return {
    id,
    edge1: "",
    edge2: "",
    count: "",
    grossAreaPerCount: "",
    provisionNumber,
  };
};

interface IProps {
  onAccept: (data: IProvision[]) => Promise<void>;
  onCancel: () => void;
  initialProvisions: IProvision[];
}

const ProvisionsEditModal: React.FC<IProps> = ({ onAccept, onCancel, initialProvisions }) => {
  const [loading, setLoading] = useState(false);
  const [provisions, setProvisions] = useState<IProvision[]>(initialProvisions.length > 0 ? initialProvisions : [createProvision(1)]);

  const provisionsRef = useRef<RefHandle<IProvision>[]>([]);

  const submitHandler = async () => {
    setLoading(true);
    const rows = provisionsRef.current;
    const newRows: IProvision[] = [];
    if (rows?.length > 0)  {
      for (let i = 0; i < rows.length; i++) {
        const row = rows[i];
        if (row) {
          const rowData = await row.getData();
          if (rowData) {
            newRows.push(rowData);
          }
        }
      }
    }
    await onAccept(newRows);
    setLoading(false);
  };

  const addHandler = useCallback(async () => {
    setProvisions((provisions) => [...provisions, createProvision(provisions?.length + 1)]);
  }, []);

  const deleteHandler = useCallback((id: string) => {
    setProvisions(provisions => provisions.filter(provision => provision.id !== id));
  }, []);

  const copyHandler = useCallback((id: string) => {
    const newId = uuid();
    setProvisions(provisions => {
      const newProvisions = [...provisions];
      const provision = provisions.find(provision => provision.id === id);
      if (provision) {
        newProvisions.push({ ...provision, id: newId, provisionNumber: provisions.length + 1 });
      }
      return newProvisions;
    });
  }, []);

  return (
    <>
      <ModalBody style={{ display: "flex", gap: "1rem", flexDirection: "column" }}>
        <div style={{ display: "flex", gap: "1rem" }}>
          {provisions.length > 0 && (
            <div>
              <Table>
                <thead>
                  <tr>
                    <th style={{ width: "125px" }}>Varausnumero</th>
                    <th style={{ width: "125px" }}>Varaustyyppi</th>
                    <th style={{ width: "80px" }}>Kpl</th>
                    <th style={{ width: "125px" }}>Kantti1</th>
                    <th style={{ width: "125px" }}>Kantti2</th>
                    <th style={{ width: "125px" }}>Varauksen A</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {provisions?.map((provision, i) => (
                    <ProvisionEdit
                      key={provision.id}
                      ref={(rowHandle) => (provisionsRef.current[i] = rowHandle!)}
                      provision={provision}
                      onDelete={() => deleteHandler(provision.id)}
                      onCopy={() => copyHandler(provision.id)}
                    />
                  ))}
                  {provisions.length > 0 && (
                    <tr>
                      <td colSpan={7}>Varausten summa:</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          )}
        </div>
        <div>
          <Button onClick={addHandler}>Lisää uusi varaus</Button>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button onClick={submitHandler} style={{ marginRight: "0.5rem" }} loading={loading}>
          Tallenna
        </Button>
        <Button onClick={onCancel} color={EButtonColor.DANGER}>
          Peruuta
        </Button>
      </ModalFooter>
    </>
  );
};

export default ProvisionsEditModal;
