import { useQuery } from "@tanstack/react-query";

import { ErrorResponse } from "../../custom-fetch";
import { QueryKey } from "../../services/query-keys";
import IInsulation from "../../shared/IInsulation";
import ErrorsAlert from "../ErrorsAlert/ErrorsAlert";
import Spinner from "../ui/Spinner/Spinner";
import { useState } from "react";
import Input, { EInputType } from "../ui/Input/Input";
import InsulationsTable from "./InsulationsTable";
import { useInsulationService } from "../../services/insulations-service";

const InsulationsList: React.FC = () => {
  const { fetchInsulations } = useInsulationService();
  const [filterValue, setFilterValue] = useState<string>('');

  const {
    data: insulations,
    isPending,
    isError,
    error,
  } = useQuery<IInsulation[], ErrorResponse>({
    queryKey: [QueryKey.insulations],
    queryFn: fetchInsulations,
    staleTime: 5000,
  });

  if (isError) {
    return <ErrorsAlert errors={error.messages} />;
  }

  if (isPending) {
    return <Spinner />;
  }

  if (!insulations || insulations.length === 0) {
    return <p>Ei eristeitä</p>;
  }

  const filteredInsulations = insulations.filter(
    (insulation) =>
      insulation.unitName?.toLowerCase().includes(filterValue)
  );

  return (
    <>
    <Input
      inputName="insulationsFilter"
      type={EInputType.text}
      placeholder="Suodata tunnuksella"
      value={filterValue}
      onChange={(value) => setFilterValue(value.toString().toLowerCase())}
      autoFocus={true}
    />
    <InsulationsTable insulations={filteredInsulations}/>
    </>
  );
};

export default InsulationsList;
