import { useQuery } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import { ErrorResponse } from "../../custom-fetch";
import { useOfferService } from "../../services/offers-service";
import { QueryKey } from "../../services/query-keys";
import IOffer from "../../shared/IOffer";
import { IOffersSearch } from "./OffersSearch";
import { storage } from "../../utils/localstorage-utils";

export const useOffersSearch = (customerId?: string | null) => {
  const searchJson = useMemo(() => storage.getOffersSearch(), []);
  const initialSearch = useMemo(() => searchJson ? JSON.parse(searchJson ?? "") : null, [searchJson]);

  const [search, setSearch] = useState<IOffersSearch | null>({ ...initialSearch, customerId: customerId ?? initialSearch?.customerId } ?? { customerId });
  const { fetchOffers } = useOfferService();

  const {
    data: offers,
    isPending,
    isRefetching,
    isError,
    error,
    refetch,
  } = useQuery<IOffer[], ErrorResponse>({
    queryKey: [QueryKey.offers],
    queryFn: ({ signal }) => fetchOffers({ signal, search }),
    staleTime: 5000,
  });

  return {
    offers,
    isLoading: isPending || isRefetching,
    isError,
    error,
    search,
    setSearch,
    refetch,
  };
};
