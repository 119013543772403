import { useNavigate } from "react-router-dom";

import { Route } from "../../routes";
import ErrorsAlert from "../ErrorsAlert/ErrorsAlert";
import Spinner from "../ui/Spinner/Spinner";
import Table from "../ui/Table/Table";
import { useOfferService } from "../../services/offers-service";
import { useQuery } from "@tanstack/react-query";
import IOffer from "../../shared/IOffer";
import { ErrorResponse } from "../../custom-fetch";
import { QueryKey } from "../../services/query-keys";

interface IProps {
  organizationId?: string;
 }

const OffersListCustomer: React.FC<IProps> = ({ organizationId }) => {
  const navigate = useNavigate();

  const { fetchOffersCustomer } = useOfferService();

  const {
    data: offers,
    isPending,
    isError,
    error,
  } = useQuery<IOffer[], ErrorResponse>({
    queryKey: [QueryKey.customerOffers, organizationId],
    queryFn: ({ signal }) => fetchOffersCustomer({ signal, search:{customerId: organizationId} }),
    staleTime: 5000,
  });

  if (isError && error) {
    return <ErrorsAlert errors={error.messages} />;
  }

  if (isPending) {
    return <Spinner />;
  }

  if (!offers || offers.length === 0) {
    return <p>Ei tarjouksia</p>;
  }

  const openHandler = (id: string) => {
    navigate(Route.offer(id));
  };

  const openPrintHandler = (id: string, order: boolean, event: React.MouseEvent) => {
    event.stopPropagation();
    if (order) {
      //console.log("order")
      navigate(Route.orderPrint(id));
    } else {
      //console.log("offer")
      navigate(Route.offerPrint(id));
    }
  };

  return (
    <>
      <Table hover>
        <thead>
          <tr>
            <th>Tarjouksen numero</th>
            <th>Pvm</th>
            <th>Voimassaolo</th>
            <th>Kohteen nimi</th>
            <th>Osoite</th>
            <th>Pdf / Tulostus</th>
          </tr>
        </thead>
        <tbody>
          {offers.map((offer) => (
            <tr key={offer.id} onClick={() => openHandler(offer.id)}>
              <td>{offer.projectNumber && offer.projectNumber + " / "}{offer.offerNumber}</td>
              <td>{offer.offerDate}</td>
              <td>{offer.expireInDays}</td>
              <td>{offer.targetName}</td>
              <td>{offer.deliveryStreetAddress}</td>
              <td>
                <p>
                  <span style={{textDecoration: "underline"}} onClick={(event)=> openPrintHandler(offer.id, false, event)}>Tarjous</span> {" / "}
                  <span style={{textDecoration: "underline"}} onClick={(event)=> openPrintHandler(offer.id, true, event)}>Tilausvahvistus</span>
                </p>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default OffersListCustomer;
