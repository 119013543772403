import { Location, Navigate, matchRoutes, useLocation } from 'react-router-dom';

import { useAuthContext } from '../context/auth-context';
import { ERoute, routes } from '../routes';

type Props = {
  route: React.FC;
};

const GuardedRoute: React.FC<Props> = ({ route }) => {
  const location = useLocation();
  const path = usePath(location);
  const roles = routes[path].roles;
  const { isAuth, user } = useAuthContext();
  if (isAuth) {
    if (roles && !roles.includes(user!.role)) {
      return <p>Not allowed path (TODO)</p>;
    }
    const Route = route;
    return <Route />;
  }
  return <Navigate to={`/auth${location.pathname.length > 0 ? `?continue=${location.pathname}` : ''}`} replace />;
};

const usePath = (location: Location<any>) => {
  
  const routes = matchRoutes(Object.values(ERoute).map(path => ({ path })), location);
  if (routes == null || routes.length === 0) return "";
  return routes[0].route.path;
}


export default GuardedRoute;
