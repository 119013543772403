import { useCallback } from 'react';
import { ISignal, useCustomFetch } from "../custom-fetch";
import ITransportationCost from '../shared/ITransportationCost';

interface IGetTransportationCost extends ISignal {
  id: string;
}

export const useTransportationCostService = () => {
  const customFetch = useCustomFetch();

  const fetchTransportationCosts = useCallback(async ({ signal }: ISignal, archived?: boolean) => {
    const [transportationCosts] = await customFetch<ITransportationCost[]>(`/api/transportation-costs/list${archived !== undefined ? `?archived=${archived}`:""}`, { signal });
    return transportationCosts;
  }, [customFetch]);
  
  const getTransportationCost = useCallback(async ({ signal, id }: IGetTransportationCost) => {
    const [transportationCost] = await customFetch<ITransportationCost>("/api/transportation-costs/get/" + id, { signal });
    return transportationCost;
  }, [customFetch]);
  
  const saveTransportationCost = useCallback(async (data: ITransportationCost) => {
    const [transportationCost] = await customFetch<ITransportationCost>("/api/transportation-costs/add", {
      method: "POST",
      body: JSON.stringify(data),
    });
    return transportationCost;
  }, [customFetch]);
  
  const updateTransportationCost = useCallback(async (id: string, data: ITransportationCost) => {
    const [transportationCost] = await customFetch<ITransportationCost>("/api/transportation-costs/update/" + id, {
      method: "PUT",
      body: JSON.stringify(data),
    });
    return transportationCost;
  }, [customFetch]);
  
  const deleteTransportationCost = useCallback(async (id: string) => {
    await customFetch<ITransportationCost>("/api/transportation-costs/delete/" + id, {
      method: "DELETE"
    });
    return true;
  }, [customFetch]);
  
  return { fetchTransportationCosts, getTransportationCost, saveTransportationCost, updateTransportationCost, deleteTransportationCost};
}