import { faChevronUp, faCopy, faTrash } from "@fortawesome/free-solid-svg-icons";
import { forwardRef, useImperativeHandle, useMemo, useRef, useState } from "react";
import { useProductGroupOptions } from "../../../hooks/useProductGroupOptions";
import { useProductTypeOptions } from "../../../hooks/useProductTypeOptions";
import { FactoryOptions } from "../../../shared/FactoryOptions";
import IOfferRow, { UNIT_OPTIONS } from "../../../shared/IOfferRow";
import { HOLLOWCORE_PRODUCT_GROUP_NAMES, WALL_PRODUCT_GROUP_NAMES } from "../../../shared/IProductGroup";
import { RefHandle } from "../../../shared/RefHandle";
import Button, { EButtonColor, EButtonSize } from "../../ui/Button/Button";
import { ECommonValue, EInputType, IInputField } from "../../ui/Input/Input";
import { EInputUpdateAction } from "../../ui/Input/input-context";
import { useInputs } from "../../ui/Input/useInputs";
import OfferRowCalculation from "../OfferRowCalculation/OfferRowCalculation";
import { IOfferRowCalculationConcrete } from "../OfferRowCalculation/OfferRowCalculationConcretes/OfferRowCalculationConcrete";
import { IOfferRowCalculationHollowcore } from "../OfferRowCalculation/OfferRowCalculationHollowcores/OfferRowCalculationHollowcore";
import { IOfferRowCalculationInsulation } from "../OfferRowCalculation/OfferRowCalculationInsulations/OfferRowCalculationInsulations";
import { IOfferRowCalculationMesh } from "../OfferRowCalculation/OfferRowCalculationMeshes/OfferRowCalculationMeshes";
import { IOfferRowCalculationSteelPart } from "../OfferRowCalculation/OfferRowCalculationSteelParts/OfferRowCalculationSteelParts";
import { IOfferRowCalculationSteel } from "../OfferRowCalculation/OfferRowCalculationSteels/OfferRowCalculationSteels";
import { IOfferRowCalculationSteelTendon } from "../OfferRowCalculation/OfferRowCalculationSteels/OfferRowCalculationSteelTendon";
import { IOfferRowCalculationWork } from "../OfferRowCalculation/OfferRowCalculationWork/OfferRowCalculationWork";
import OfferSwatchbook from "../OfferSwatchbook/OfferSwatchbook";
import classes from "./OfferProduct.module.scss";

export enum EInputs {
  factory = "factory",
  productGroupId = "productGroupId",
  productTypeId = "productTypeId",
  description = "description",
  hidden = "hidden",
  bold = "bold",
  unitQuantity = "unitQuantity",
  count = "count",
  unit = "unit",
  unitPrice = "unitPrice",
  linealMeters = "linealMeters",
  grossArea = "grossArea",
  netArea = "netArea",
  additionalInformation = "additionalInformation",
}

interface IProps {
  row: IOfferRow;
  onDelete: () => void;
  selected?: boolean;
  onClick: () => void;
  onCopy: () => void;
  onRemoveFocus: () => void;
  onSwatchbookChange: (value: number) => void;
  striped?: boolean;
  showControls: boolean;
}

export type ProductRef = RefHandle<{
  offerRowCalculationConcrete: IOfferRowCalculationConcrete;
  offerRowCalculationHollowcore: IOfferRowCalculationHollowcore;
  offerRowCalculationInsulation: IOfferRowCalculationInsulation;
  offerRowCalculationMesh: IOfferRowCalculationMesh;
  offerRowCalculationSteel: IOfferRowCalculationSteel;
  offerRowCalculationSteelTendon: IOfferRowCalculationSteelTendon;
  offerRowCalculationSteelPart: IOfferRowCalculationSteelPart;
  offerRowCalculationWork: IOfferRowCalculationWork;
}>;

const OfferProduct: React.ForwardRefRenderFunction<RefHandle<IOfferRow>, IProps> = ({ row, onDelete, selected, onClick, onCopy, onRemoveFocus, onSwatchbookChange, striped, showControls }, ref) => {
  const { createInput, submit } = useProductInputs(row, showControls);
  const { options: productGroupOptions, loading: productGroupsloading } = useProductGroupOptions();
  const { options: productTypeOptions, loading: productTypesloading } = useProductTypeOptions(true, row.productGroupId);

  const isHollowcore = useMemo(() => HOLLOWCORE_PRODUCT_GROUP_NAMES.includes(productGroupOptions?.find((p) => p.value === row.productGroupId)?.label ?? ""), [productGroupOptions, row.productGroupId]);
  const isWall = useMemo(() => WALL_PRODUCT_GROUP_NAMES.includes(productGroupOptions?.find((p) => p.value === row.productGroupId)?.label ?? ""), [productGroupOptions, row.productGroupId]);
  const tableRowClassNames = [classes.TableRow];

  switch (row.swatchbook) {
    case 1:
      tableRowClassNames.push(classes.Green);
      break;
    case 2:
      tableRowClassNames.push(classes.Orange);
      break;
    default:
      break;
  }

  const rowActions = useMemo(() => (
      <td className={classes.Actions}>
        {selected && (
            <Button onClick={(e) => { e.stopPropagation(); onRemoveFocus(); }} size={EButtonSize.SMALL} color={EButtonColor.DEFAULT} icon={faChevronUp} title="Sulje" />
        )}
        {showControls && (
          <>
            <Button onClick={(e) => { e.stopPropagation(); onDelete(); }} size={EButtonSize.SMALL} color={EButtonColor.DANGER} icon={faTrash} title="Poista" style={{ marginLeft: "auto" }} />
            <Button onClick={(e) => { e.stopPropagation(); onCopy(); }} size={EButtonSize.SMALL} icon={faCopy} title="Kopioi" />
          </>
        )}
      </td>
    ), [onCopy, onDelete, onRemoveFocus, selected, showControls]);

    const productRef = useRef<ProductRef>(null);

    useImperativeHandle(ref, () => ({
      getData: async () => {
        const newData = await submit();
        if (!row) return null;
        return { ...row, ...newData, ...await productRef.current?.getData() };
      },
    }), [row, submit]);

  return selected ? (
    <>
      <tr className={tableRowClassNames.join(" ")} style={striped ? { background: "white" } : { background: "#f5f5f5" }} onClick={onClick}>
        <td>{createInput(EInputs.hidden)}</td>
        <td><OfferSwatchbook value={row.swatchbook} onChange={onSwatchbookChange} /></td>
        <td>{createInput(EInputs.factory)}</td>
        <td>{createInput(EInputs.productGroupId, { options: productGroupOptions, loading: productGroupsloading })}</td>
        <td>{createInput(EInputs.productTypeId, { options: productTypeOptions, loading: productTypesloading })}</td>
        <td>{createInput(EInputs.description)}</td>
        <td>{createInput(EInputs.count)}</td>
        <td>{createInput(EInputs.linealMeters)}</td>
        <td>{createInput(EInputs.netArea)}</td>
        <td>{createInput(EInputs.grossArea)}</td>
        <td>{row.cubicMeters}</td>
        <td>{createInput(EInputs.unitQuantity)}</td>
        <td>{createInput(EInputs.unit)}</td>
        <td>{createInput(EInputs.unitPrice)}</td>
        <td>{row.rowPrice}</td>
        <td>{row.cubicMeterPrice}</td>
        {rowActions}
      </tr>
      <tr style={striped ? { background: "white" } : { background: "#f5f5f5" }}>
        <td style={{ borderTop: 0, width: "1rem" }}></td>
        <td colSpan={18} style={{ borderTop: 0 }}>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: "1rem" }}>
            <OfferRowCalculation offerRow={row} isHollowcore={isHollowcore} isWall={isWall} ref={productRef} />
            {createInput(EInputs.additionalInformation, { containerStyles: { maxWidth: "500px", width: "100%", alignSelf: "flex-start" }})}
          </div>
        </td>
      </tr>
    </>
  ) : (
    <>
      <tr className={tableRowClassNames.join(" ")} onClick={onClick} style={striped && !selected ? { background: "white" } : { background: "#f5f5f5" }}>
        <td>{createInput(EInputs.hidden)}</td>
        <td><OfferSwatchbook value={row.swatchbook} onChange={onSwatchbookChange} /></td>
        <td>{row.factory}</td>
        <td>{row.productGroupName}</td>
        <td>{row.productTypeName}</td>
        <td>{row.description}</td>
        <td title={row.additionalInformation && "Rivin lisätiedot-kentässä on sisältöä."}>
          {row.additionalInformation && <b>! </b>}
          {row.count || ""}
        </td>
        <td>{row.linealMeters || ""}</td>
        <td>{row.netArea || ""}</td>
        <td>{row.grossArea || ""}</td>
        <td>{row.cubicMeters || ""}</td>
        <td style={{ fontWeight: "bold", fontSize: "1.25rem" }}>{row.unitQuantity || ""}</td>
        <td>{row.unit}</td>
        <td style={{ fontWeight: "bold", fontSize: "1.25rem" }}>{row.unitPrice || ""}</td>
        <td>{row.rowPrice || ""}</td>
        <td>{row.cubicMeterPrice || ""}</td>
        {rowActions}
      </tr>
    </>
  );
};

const useProductInputs = (data?: IOfferRow, showControls?: boolean) => {
  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.factory]: {
      type: EInputType.reactSelect,
      options: FactoryOptions,
      value: "",
      hideControls: true,
      menuPosition: "fixed",
      placeholder: "",
    },
    [EInputs.productGroupId]: {
      type: EInputType.reactSelect,
      value: "",
      hideControls: true,
      menuPosition: "fixed",
      placeholder: "",
    },
    [EInputs.productTypeId]: {
      type: EInputType.reactSelect,
      value: "",
      hideControls: true,
      menuPosition: "fixed",
      placeholder: "",
    },
    [EInputs.description]: {
      type: EInputType.text,
      value: "",
    },
    [EInputs.hidden]: {
      type: EInputType.checkbox,
      options: [{ value: ECommonValue.YES }],
      value: [],
    },
    [EInputs.unitQuantity]: {
      type: EInputType.number,
      value: "",
      boldContent: true
    },
    [EInputs.count]: {
      type: EInputType.number,
      value: "",
    },
    [EInputs.unit]: {
      type: EInputType.reactSelect,
      value: "",
      hideControls: true,
      menuPosition: "fixed",
      placeholder: "",
      options: UNIT_OPTIONS,
    },
    [EInputs.unitPrice]: {
      type: EInputType.number,
      value: "",
      boldContent: true
    },
    [EInputs.linealMeters]: {
      type: EInputType.number,
      value: "",
    },
    [EInputs.grossArea]: {
      type: EInputType.number,
      value: "",
    },
    [EInputs.netArea]: {
      type: EInputType.number,
      value: "",
    },
    [EInputs.additionalInformation]: {
      type: EInputType.textarea,
      label: "Rivin lisätiedot",
      placeholder: "Rivin lisätiedot",
      rows: 8,
      value: "",
    }
  });

  const { createInput, submit } = useInputs({ data, inputs, setInputs, updateAction: EInputUpdateAction.OFFER_ROW, disabled: !showControls });

  return { createInput, submit };
};

export default forwardRef(OfferProduct);
