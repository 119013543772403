import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import ErrorsAlert, { combineErrors } from "../components/ErrorsAlert/ErrorsAlert";
import Project from "../components/Projects/Project/Project";
import Spinner from "../components/ui/Spinner/Spinner";
import { ErrorResponse } from "../custom-fetch";
import { useProjectsService } from "../services/projects-service";
import { QueryKey } from "../services/query-keys";
import IProject from "../shared/IProject";
import { useWeeklyProductionRowsService } from "../services/weeklyProductionRows-service";
import IWeeklyProductionRow from "../shared/IWeeklyProductionRow";
import IAdditionalInvoice from "../shared/IAdditionalInvoice";
import { useAdditionalInvoicesService } from "../services/additionalInvoices-service";
import { useElementsService } from "../services/elements-service";
import IElement from "../shared/IElement";

const ProjectPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const isEdit = useMemo(() => id !== "add", [id]);
  
  const { project, isPending, isError, error } = useGetProject(id!, isEdit);
  const { weeklyProductionRows, isFetchingRows, isFetchRowsError, fetchRowsError } = useFetchWeeklyProductionRows(id!);
  const { additionalInvoices, isFetchingInvoices, isFetchInvoicesError, fetchInvoicesError } = useFetchAdditionalInvoices(id!);
  const { elements, isFetchingElements, isFetchElementsError, fetchElementsError } = useFetchElements(id!);

  const errorMessages = combineErrors(
    { isError, error },
    { isError: isFetchRowsError, error: fetchRowsError },
    { isError: isFetchInvoicesError, error: fetchInvoicesError },
    { isError: isFetchElementsError, error: fetchElementsError },

  );

  const isLoading = useMemo(() => isPending || isFetchingRows || isFetchingInvoices || isFetchingElements, [isFetchingElements, isFetchingInvoices, isFetchingRows, isPending]);

  if (errorMessages.length > 0) {
    return <ErrorsAlert errors={errorMessages} />;
  }

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <Project
        id={id!}
        project={project!}
        isPending={isPending}
        isError={isError}
        error={error}
        isEdit={isEdit}
        initialWeeklyProductionRows={weeklyProductionRows ?? []}
        initialAdditionalInvoices={additionalInvoices ?? []}
        initialElements={elements ?? []}
      />
    </>
  );
};

const useGetProject = (id: string, isEdit: boolean) => {
  const { getProject } = useProjectsService();

  const {
    data: project,
    isPending,
    isError,
    error,
  } = useQuery<IProject, ErrorResponse>({
    queryKey: [QueryKey.projects, id],
    queryFn: ({ signal }) => getProject({ signal, id: id! }),
    enabled: isEdit,
  });

  return { project, isPending, isError, error };
}

const useFetchWeeklyProductionRows = (projectId: string) => {
  const { fetchWeeklyProductionRows } = useWeeklyProductionRowsService();

  const {
    data: weeklyProductionRows,
    isPending: isFetchingRows,
    isError: isFetchRowsError,
    error: fetchRowsError,
  } = useQuery<IWeeklyProductionRow[], ErrorResponse>({
    queryKey: [QueryKey.weeklyProductionRows, projectId],
    queryFn: ({ signal }) => fetchWeeklyProductionRows({ signal, projectId }),
    staleTime: 5000,
  });

  return { weeklyProductionRows, isFetchingRows, isFetchRowsError, fetchRowsError };
};

const useFetchAdditionalInvoices = (projectId: string) => {
  const { fetchAdditionalInvoices } = useAdditionalInvoicesService();

  const {
    data: additionalInvoices,
    isPending: isFetchingInvoices,
    isError: isFetchInvoicesError,
    error: fetchInvoicesError,
  } = useQuery<IAdditionalInvoice[], ErrorResponse>({
    queryKey: [QueryKey.additionalInvoices, projectId],
    queryFn: ({ signal }) => fetchAdditionalInvoices({ signal, projectId }),
    staleTime: 5000,
  });

  return { additionalInvoices, isFetchingInvoices, isFetchInvoicesError, fetchInvoicesError };
};

const useFetchElements = (projectId: string) => {
  const { fetchElements } = useElementsService();

  const {
    data: elements,
    isPending: isFetchingElements,
    isError: isFetchElementsError,
    error: fetchElementsError,
  } = useQuery<IElement[], ErrorResponse>({
    queryKey: [QueryKey.elements, projectId],
    queryFn: ({ signal }) => fetchElements({ signal, projectId }),
    staleTime: 5000,
  });

  return { elements, isFetchingElements, isFetchElementsError, fetchElementsError };
};

export default ProjectPage;
