import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useRef } from 'react';

import classes from './CheckboxOption.module.scss';

interface IProps {
  value: string;
  label?: string;
  info?: string;
  isActive: boolean;
  onClick: (value: string[]) => void;
  name: string;
  disabled?: boolean;
  invalid?: boolean;
  containerRef: React.RefObject<HTMLDivElement>;
}

const CheckboxOption: React.FC<IProps> = ({
  value,
  label,
  info,
  isActive,
  onClick,
  name,
  disabled,
  invalid,
  containerRef
}) => {
  const ref = useRef<HTMLInputElement>(null);
  const clickHandler = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.stopPropagation();
    if (disabled || !containerRef.current) return;

    if (ref.current) {
      ref.current.click();
    }

    const inputs = containerRef.current.querySelectorAll<HTMLInputElement>(`input[name=${name}]:checked`);
    const values: string[] = [];
    inputs.forEach(input => {
      values.push(input.value);
    });
    onClick(values);
  };

  const classNames = [classes.FakeInput];
  if (isActive) {
    classNames.push(classes.IsActive);
  }
  if (disabled) {
    classNames.push(classes.Disabled);
  }

  const containerClassNames = [classes.Container]
  if(invalid) {
    containerClassNames.push(classes.Invalid);
  }

  return (
    <div className={containerClassNames.join(' ')}>
      <div className={classes.CheckboxContainer}>
        <input
          className={classes.Input}
          name={name}
          type="checkbox"
          value={value}
          checked={isActive}
          ref={ref}
          readOnly
        />
        <span className={classNames.join(" ")} onClick={clickHandler} />
      </div>
      {(label && label.length) && (
        <label className={classes.Label} onClick={clickHandler}>{label}</label>
      )}
      {(info && info.length > 0) && (
        <FontAwesomeIcon className={classes.Info} icon={faInfoCircle} title={info} />
      )}
    </div>
  );
};

export default CheckboxOption;
