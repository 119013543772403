import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { queryClient } from '../../App';
import { ErrorResponse } from '../../custom-fetch';
import { Route } from '../../routes';
import { QueryKey } from '../../services/query-keys';
import { useSteelPartsService } from '../../services/steelParts-service';
import ISteelParts from '../../shared/ISteelPart';
import ErrorsAlert, { combineErrors } from '../ErrorsAlert/ErrorsAlert';
import FormButtons from '../ui/FormButtons/FormButtons';
import { ECommonValue, EInputType, IInputField } from '../ui/Input/Input';
import { useInputs } from '../ui/Input/useInputs';

interface IProps {
  id: string;
  steelParts?: ISteelParts;
}

enum EInputs {
  unitNumber = "unitNumber",
	unitName = "unitName",
	netPrice = "netPrice",
	description = "description",
	additionalInfo = "additionalInfo",
  supplierId = "supplierId",
  supplierName = "supplierName",
  group = "group",
	deleted = "deleted",
	archived = "archived",
}

const SteelPartsEdit: React.FC<IProps> = ({ id, steelParts }) => {
  const isEdit = id !== "add";

  const { deleteMutate, isDeleting, isDeletingError, deletingError } = useDelete(id);

  const { saveOrUpdate, error, isError, isPending } = useSaveOrUpdate(id, isEdit);

  const { createInput, submit } = useSteelPartInputs(steelParts);

  const submitHandler = async () => {
    const data = await submit();
    if (data) {
      saveOrUpdate(data);
    }
  };

  const errorMessages = combineErrors({ isError, error}, { isError: isDeletingError, error: deletingError })

  return (
    <>
      {createInput(EInputs.unitNumber)}
      {createInput(EInputs.unitName)}
      {createInput(EInputs.netPrice)}
      {createInput(EInputs.description)}
      {createInput(EInputs.additionalInfo)}
      {createInput(EInputs.supplierId)}
      {createInput(EInputs.supplierName)}
      {createInput(EInputs.group)}
      {createInput(EInputs.archived)}
      {errorMessages.length > 0 && <ErrorsAlert errors={errorMessages} /> }
      <FormButtons
        onSubmit={submitHandler}
        isLoading={isPending}
        onDelete={isEdit ? deleteMutate : undefined}
        isDeleting={isDeleting}
        deleteConfirmMessage="Haluatko varmasti poistaa teräsosan?"
      />
    </>
  );
};


const useSteelPartInputs = (data?: ISteelParts) => {
  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.unitNumber]: {
      type: EInputType.text,
      label: "Nimikenumero",
      value: "",
      validation: {required: true},
      autoFocus: true
    },
    [EInputs.unitName]: {
      type: EInputType.text,
      label: "Nimike",
      value: "",
      validation: {required: true}
    },
    [EInputs.netPrice]: {
      type: EInputType.number,
      label: "Nettohinta",
      value: "",
      validation: {required: true}
    },
    [EInputs.description]: {
      type: EInputType.text,
      label: "Selite",
      value: "",
    },
    [EInputs.additionalInfo]: {
      type: EInputType.text,
      label: "Lisätiedot",
      value: "",
    },
    [EInputs.supplierId]: {
      type: EInputType.text,
      label: "Toimittajan id",
      value: "",
    },
    [EInputs.supplierName]: {
      type: EInputType.text,
      label: "Toimittajan nimi",
      value: "",
    },
    [EInputs.group]: {
      type: EInputType.text,
      label: "Ryhmä",
      value: "",
    },
    [EInputs.archived]: {
      type: EInputType.checkbox,
      label: "Arkistoitu",
      options: [{ value: ECommonValue.YES }],
      value: [],
    },
  });

  const { createInput, submit } = useInputs({ data, inputs, setInputs });

  return { createInput, submit };
};


const useSaveOrUpdate = (id: string, isEdit: boolean) => {
  
  const { updateSteelPart, saveSteelPart } = useSteelPartsService();

  const navigate = useNavigate();

  const mutationFn = (data: ISteelParts) => {
    return isEdit ? updateSteelPart(id, data) : saveSteelPart(data);
  };

  const { mutate: saveOrUpdate, isPending, isError, error } = useMutation<
    ISteelParts,
    ErrorResponse,
    ISteelParts
  >({
    mutationFn,
    onSuccess: () => {
      queryClient.removeQueries({ queryKey: [QueryKey.steelParts, id] });
      queryClient.invalidateQueries({ queryKey: [QueryKey.steelParts] });
      queryClient.invalidateQueries({ queryKey: [QueryKey.steelPartOptions] });
      navigate(Route.steelParts);
    },
  });

  return { saveOrUpdate, isPending, isError, error };
}


const useDelete = (id: string) => {
  const { deleteSteelPart } = useSteelPartsService();
  const navigate = useNavigate();

  const {
    mutate: deleteMutate,
    isPending: isDeleting,
    isError: isDeletingError,
    error: deletingError,
  } = useMutation<boolean, ErrorResponse>({
    mutationFn: () => deleteSteelPart(id),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.steelParts],
        refetchType: "none",
      });
      queryClient.invalidateQueries({
        queryKey: [QueryKey.steelPartOptions],
        refetchType: "none",
      });
      navigate(Route.steelParts);
    },
  });

  return { deleteMutate, isDeleting, isDeletingError, deletingError };
}

export default SteelPartsEdit;
