import { useCallback } from "react";
import IProvision from "../../shared/IProvision";
import {
  EModalId,
  EModalSize,
  useModalContext,
} from "../ui/Modal/modal-context";
import ProvisionsEditModal from "./ProvisionsEditModal";

export const useProvisionsEditModal = (provisions: IProvision[]) => {
  const { addModal, closeModal, updateModal } = useModalContext();

  const closeModalHandler = useCallback(
    () => closeModal(EModalId.PROVISIONS_EDIT),
    [closeModal]
  );

  const handler = (): Promise<IProvision[] | null> => {
    let title = "Muokkaa varauksia";
    return new Promise((resolve) => {
      addModal({
        id: EModalId.PROVISIONS_EDIT,
        isOpen: true,
        size: EModalSize.MEDIUM,
        title,
        backdropNotClose: true,
        content: (
          <ProvisionsEditModal
            onAccept={async (data) => {
              try {
                resolve(data);
              } catch (e) {
                console.error(e);
              }
            }}
            onCancel={() => {
              updateModal(EModalId.PROVISIONS_EDIT, {
                loading: false,
                error: null,
              });
              closeModalHandler();
              resolve(null);
            }}
            initialProvisions={provisions}
          />
        ),
      });
    });
  };

  return handler;
};
