import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { queryClient } from "../../App";
import { ErrorResponse } from "../../custom-fetch";
import { Route } from "../../routes";
import { QueryKey } from "../../services/query-keys";
import ErrorsAlert, { combineErrors } from "../ErrorsAlert/ErrorsAlert";
import FormButtons from "../ui/FormButtons/FormButtons";
import { ECommonValue, EInputType, IInputField } from "../ui/Input/Input";
import { useInputs } from "../ui/Input/useInputs";
import { useSlabTypeService } from "../../services/slabTypes-service";
import ISlabType from "../../shared/ISlabType";

interface IProps {
  id: string;
  slabType?: ISlabType;
}

enum EInputs {
  typeName = "typeName",
  netWeight = "netWeight",
  meterWeight = "meterWeight",
  thickness = "thickness",
  squarePrice = "squarePrice",
  archived = "archived"
}

const SlabTypeEdit: React.FC<IProps> = ({ id, slabType }) => {
  const isEdit = id !== "add";
  
  const navigate = useNavigate();

  const redirectHandler = () => {
    return navigate(Route.slabTypes);
  };

  const { saveOrUpdate, error, isError, isPending } = useSaveOrUpdate(id, isEdit, redirectHandler);
  const { deleteMutate, isDeleting, isDeletingError, deletingError } = useDelete(id, redirectHandler);
  const { createInput, submit } = useSlabTypeInputs(slabType);

  const submitHandler = async () => {
    const data = await submit();
    if (data) {
      saveOrUpdate(data);
    }
  };

  const errorMessages = combineErrors({ isError, error }, { isError: isDeletingError, error: deletingError });

  return (
    <>
      {createInput(EInputs.typeName)}
      {createInput(EInputs.netWeight)}
      {createInput(EInputs.meterWeight)}
      {createInput(EInputs.thickness)}
      {createInput(EInputs.squarePrice)}
      {createInput(EInputs.archived)}
      {errorMessages.length > 0 && <ErrorsAlert errors={errorMessages} />}
      <FormButtons
        onSubmit={submitHandler}
        isLoading={isPending}
        onDelete={isEdit ? deleteMutate : undefined}
        isDeleting={isDeleting}
        deleteConfirmMessage="Haluatko varmasti poistaa laatan?"
      />
    </>
  );
};

const useSlabTypeInputs = (data?: ISlabType) => {
  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.typeName]: {
      type: EInputType.text,
      label: "Nimi",
      value: "",
      validation: {
        required: true,
      },
      autoFocus: true
    },
    [EInputs.netWeight]: {
      type: EInputType.number,
      label: "Neliöpaino",
      value: "",
      validation: {
        required: true,
      },
    },
    [EInputs.meterWeight]: {
      type: EInputType.number,
      label: "Juoksumetripaino",
      value: "",
      validation: {
        required: true,
      },
    },
    [EInputs.thickness]: {
      type: EInputType.number,
      label: "Paksuus",
      value: "",
      validation: {
        required: true,
      },
    },
    [EInputs.squarePrice]: {
      type: EInputType.number,
      label: "Neliöhinta",
      value: "",
      validation: {
        required: true,
      },
    },
    [EInputs.archived]: {
      type: EInputType.checkbox,
      label: "Arkistoitu",
      options: [{ value: ECommonValue.YES }],
      value: [],
    },
  });

  const { createInput, submit } = useInputs({ data, inputs, setInputs });

  return { createInput, submit };
};

const useSaveOrUpdate = (id: string, isEdit: boolean, redirectHandler: () => void) => {
  const { updateSlabType, saveSlabType } = useSlabTypeService();

  const mutationFn = (data: ISlabType) => {
    return isEdit ? updateSlabType(id, data) : saveSlabType(data);
  };

  const {
    mutate: saveOrUpdate,
    isPending,
    isError,
    error,
  } = useMutation<ISlabType, ErrorResponse, ISlabType>({
    mutationFn,
    onSuccess: () => {
      queryClient.removeQueries({ queryKey: [QueryKey.slabTypes, id] });
      queryClient.invalidateQueries({ queryKey: [QueryKey.slabTypes] });
      queryClient.invalidateQueries({ queryKey: [QueryKey.slabTypeOptions] });
      redirectHandler();
    },
  });

  return { saveOrUpdate, isPending, isError, error };
};

const useDelete = (id: string, redirectHandler: () => void) => {
  const { deleteSlabType } = useSlabTypeService();

  const {
    mutate: deleteMutate,
    isPending: isDeleting,
    isError: isDeletingError,
    error: deletingError,
  } = useMutation<boolean, ErrorResponse>({
    mutationFn: () => deleteSlabType(id),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.slabTypes],
        refetchType: "none",
      });
      queryClient.invalidateQueries({
        queryKey: [QueryKey.slabTypeOptions],
        refetchType: "none",
      });
      redirectHandler();
    },
  });

  return { deleteMutate, isDeleting, isDeletingError, deletingError };
};

export default SlabTypeEdit;
