import { useCallback } from 'react';
import { ISignal, useCustomFetch } from "../custom-fetch";
import IElement from '../shared/IElement';
import IWeeklyProductionRow from '../shared/IWeeklyProductionRow';
import { IElementsSearch } from '../components/Elements/ElementsSearch';

interface IFetchElements extends ISignal {
  projectId?: string;
  search?: IElementsSearch | null;
}

interface IGetElement extends ISignal {
  id: string;
}

export const useElementsService = () => {
  const customFetch = useCustomFetch();

  const fetchElements = useCallback(
    async ({ signal, projectId, search }: IFetchElements) => {
      let url = "/api/elements/list";
      if (projectId) url += "/" + projectId;
      const [elements] = await customFetch<IElement[]>(url, {
        signal,
        method: "POST",
        body: JSON.stringify(search),
      });
      return elements;
    },
    [customFetch]
  );

  const saveElement = useCallback(async (id: string, data: IElement) => {
    const [elements] = await customFetch<IElement>("/api/elements/add/" + id, {
      method: "POST",
      body: JSON.stringify(data),
    });
    return elements;
  }, [customFetch]);
 
  const updateElements = useCallback(async (data: IElement[]) => {
    const [elements] = await customFetch<IElement[]>("/api/elements/update", {
      method: "PUT",
      body: JSON.stringify(data),
    });
    return elements;
  }, [customFetch]);

  const updateProductionLineElements = useCallback(async (productionLineId: string, data: IElement[]) => {
    const [elements] = await customFetch<IElement[]>("/api/elements/update-production-line/" + productionLineId, {
      method: "PUT",
      body: JSON.stringify(data),
    });
    return elements;
  }, [customFetch]);

  const deleteElement = useCallback(async (id: string) => {
    await customFetch<IElement>("/api/elements/delete/" + id, {
      method: "DELETE"
    });
    return true;
  }, [customFetch]);

  const copyElement = useCallback(async (data: IElement) => {
    const [element] = await customFetch<IElement>("/api/elements/copy", {
      method: "POST",
      body: JSON.stringify(data),
    });
    return element;
  }, [customFetch]);

  const createProjectElements = useCallback(async (id: string, data: IWeeklyProductionRow[]) => {
    const [elements] = await customFetch<IElement[]>("/api/elements/create/" + id, {
      method: "POST",
      body: JSON.stringify(data),
    });
    return elements;
  }, [customFetch]);

  const getElementByUuid = useCallback(async ({ signal, id }: IGetElement) => {
    const [element] = await customFetch<IElement>("/api/elements/get-uuid/" + id, { signal });
    return element;
  }, [customFetch]);

  return { fetchElements, saveElement, updateElements, updateProductionLineElements, deleteElement, copyElement, createProjectElements, getElementByUuid };
}
