import { useParams } from "react-router-dom";
import { useElementsService } from "../services/elements-service";
import IElement from "../shared/IElement";
import { useQuery } from "@tanstack/react-query";
import { ErrorResponse } from "../custom-fetch";
import { QueryKey } from "../services/query-keys";
import ErrorsAlert from "../components/ErrorsAlert/ErrorsAlert";
import Spinner from "../components/ui/Spinner/Spinner";
import ElementPrintExample from "../components/Elements/ElementPrintExample";
import ElementPrint from "../components/Elements/ElementPrint";

const ElementQRCodePage: React.FC = () => {
  const { uuid } = useParams();
  const { getElementByUuid } = useElementsService();

  const {
    data: element,
    isLoading,
    isError,
    error,
  } = useQuery<IElement, ErrorResponse>({
    queryKey: [QueryKey.elements, uuid],
    queryFn: ({ signal }) => getElementByUuid({ signal, id: uuid! }),
    enabled: uuid !== "example1" && uuid !== "example2",
    staleTime: 5000,
  });

  return (
    <>
      {isError ? (
          <ErrorsAlert errors={error.messages} />
        ) : isLoading ? (
          <Spinner />
        ) : element ? (
          <>
            <div style={{width: "100%", display: "flex"}}>
              <ElementPrint element={element}/>
              <ElementPrint element={element}/>
              <ElementPrint element={element}/>
            </div>
          </>
        ) : uuid === "example1" ? (
          <>
            <div style={{width: "100%", display: "flex"}}>
              <ElementPrintExample showCe={true}/>
              <ElementPrintExample showCe={true}/>
              <ElementPrintExample showCe={true}/>
            </div>
          </>
        ) : uuid === "example2" && (
          <>
            <div style={{width: "100%", display: "flex"}}>
              <ElementPrintExample/>
              <ElementPrintExample/>
              <ElementPrintExample/>
            </div>
          </>
        )
        }
    </>
  );
};

export default ElementQRCodePage;
