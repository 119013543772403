import React, { useEffect } from "react";
import { useDropzone, Accept } from "react-dropzone";
import classes from "./Dropzone.module.scss";

interface IProps {
  onDrop: (acceptedFiles: File[]) => void;
  className?: string;
  text?: string;
  canPaste?: boolean;
  multiple?: boolean;
  disabled?: boolean;
  accept?: Accept;
}

const Dropzone: React.FC<IProps> = ({
  onDrop,
  className,
  text,
  canPaste,
  multiple,
  disabled,
  accept,
}) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple,
    disabled,
    accept,
  });

  useEffect(() => {
    if (canPaste) {
      const listener = (ev: ClipboardEvent) => {
        const items = ev.clipboardData ? ev.clipboardData.items : null;
        if (items) {
          const item = items[0];
          if (item.kind === "file") {
            const file = item.getAsFile();
            if (file) {
              onDrop([file]);
            }
          }
        }
      };
      document.addEventListener("paste", listener);
      return () => {
        document.removeEventListener("paste", listener);
      };
    }
  }, [canPaste, onDrop]);

  const classNames = [classes.Container];

  if (className) {
    classNames.push(className);
  }

  return (
    <div className={classNames.join(" ")} {...getRootProps()}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <>
          <p>Raahaa tiedosto.</p>
        </>
      ) : (
        <>
          <p>{text || "Paina valitaksesi tai raahaa tiedosto"}</p>
        </>
      )}
    </div>
  );
};

export default Dropzone;
