import { useCallback, useMemo, useState } from "react";
import { Blocker } from "react-router-dom";
import NavigationBlockerModal from "../components/NavigationBlockerModal/NavigationBlockerModal";
import {
  EModalId,
  EModalSize,
  useModalContext,
} from "../components/ui/Modal/modal-context";

enum Action {
  reset,
  proceed,
}

export const useNavigationBlocker = (blocker: Blocker) => {
  const { addModal, closeModal } = useModalContext();
  const [isOpen, setIsOpen] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  const closeNavigationBlockerModal = useCallback((blocker: Blocker, action = Action.reset) => {
    if (action === Action.reset && blocker.reset) blocker.reset();
    if (action === Action.proceed && blocker.proceed) blocker.proceed();
    closeModal(EModalId.NAVIGATION_BLOCKER);
    setIsOpen(false);
  }, [closeModal]);

  const showNavigationBlockerModal = useCallback(
    (onAccept: () => Promise<boolean>): Promise<boolean> => {
      setIsOpen(true);
      return new Promise((resolve) => {
        if (!isDirty) {
          blocker.proceed && blocker.proceed();
          setIsOpen(false);
          setIsDirty(false);
          return;
        }
        addModal({
          id: EModalId.NAVIGATION_BLOCKER,
          isOpen: true,
          size: EModalSize.SMALL,
          title: "Poistu tarjoukselta",
          onModalClose: () => closeNavigationBlockerModal(blocker),
          content: (
            <NavigationBlockerModal
              onAccept={async () => {
                const isSuccess = await onAccept();
                if (isSuccess) {
                  closeNavigationBlockerModal(blocker, Action.proceed);
                  resolve(true);
                } else {
                  closeNavigationBlockerModal(blocker);
                  resolve(false);
                }
              }}
              onNavigate={() => {
                closeNavigationBlockerModal(blocker, Action.proceed);
                setIsDirty(false);
                resolve(false);
              }}
              onCancel={() => {
                closeNavigationBlockerModal(blocker);
                resolve(false);
              }}
            />
          ),
        });
      });
    },
    [addModal, closeNavigationBlockerModal, blocker, isDirty]
  );

  const showBlocker = useMemo(
    () => blocker.state === "blocked" && !isOpen,
    [blocker.state, isOpen]
  );

  return { showNavigationBlockerModal, showBlocker, setIsDirty };
};
