import Container from '../components/ui/Container/Container';
import Button, { EButtonSize } from '../components/ui/Button/Button';
import { faFile, faPlus } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { Route } from '../routes';
import MeshList from '../components/Meshes/MeshList';


const MeshesPage: React.FC = () => {
  const navigate = useNavigate();
  return (
    <>
    <h1 style={{ margin: "1rem", display: "flex", gap: "1rem", alignItems: "center",}}>
      Verkot{" "}
      <Button
        size={EButtonSize.SMALL}
        onClick={() => navigate(Route.mesh("add"))}
        icon={faPlus}
        style={{ fontSize: "initial" }}
      >
        Lisää uusi
      </Button>
      <Button
        size={EButtonSize.SMALL}
        onClick={() => navigate(Route.meshesImport)}
        icon={faFile}
        style={{ fontSize: "initial" }}
      >
        Päivitä tiedostosta
      </Button>
    </h1>
    <Container>
      <MeshList />
    </Container>
    </>
  )
}

export default MeshesPage;