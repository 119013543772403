import QRCode from 'react-qr-code';
import { adminUiUrl } from '../../config';
import { useElementOptions } from '../../hooks/useElementOptions';
import { EFactory } from '../../shared/IOfferRow';
import IWaybill from '../../shared/IWaybill';
import Table from '../ui/Table/Table';
import classes from './WaybillPrint.module.scss';
import WaybillPrintHeader from './WaybillPrintHeader';
import { formatDate } from '../../utils/date-utils';
import { EProvisionType } from '../../shared/IProvision';
import { useProjectsService } from '../../services/projects-service';
import { QueryKey } from '../../services/query-keys';
import IProject from '../../shared/IProject';
import { useQuery } from '@tanstack/react-query';
import { ErrorResponse } from '../../custom-fetch';

interface IProps {
  waybill: IWaybill;
}
const WaybillPrint: React.FC<IProps> = ({ waybill }) => {

    const { getProject } = useProjectsService();
    const { elements } = useElementOptions(waybill.projectId, waybill.factory as EFactory);

    const {
        data: project,
        //isLoading,
    } = useQuery<IProject, ErrorResponse>({
        queryKey: [QueryKey.projects, waybill.projectId],
        queryFn: ({ signal }) => getProject({ signal, id: waybill.projectId! }),
        enabled: !!waybill.projectId,
    });

    const getLabel = (index: number) => {
        if (index % 3 === 1) {
            return (Math.floor(index / 3) + 1).toString();
        }
        const letterCode = index - Math.ceil(index / 3);
        return String.fromCharCode('A'.charCodeAt(0) + letterCode);
    };

    const getData = (slot: string) => {
        return waybill.waybillRows?.filter((row) => row.slot === slot).reverse();
    };

    let firstSixWeight = 0;
    let lastNineWeight = 0;
    let totalWeight = 0;
    let firstSixElementCount = 0;
    let lastNineElementCount = 0;
    let totalElementCount = 0;

    const firstSixItems: JSX.Element[] = [];
    const lastNineItems: JSX.Element[] = [];

    [...Array(15)].forEach((_, index) => {
        const label = getLabel(index);
        const data = getData(label);

        const totalWeightTons = data?.reduce((sum, row) => {
            const element = elements?.find(e => e.id === row.elementId);
            const weightTons = parseFloat(element?.weightTons ?? "0");
            return sum + weightTons;
        }, 0) ?? 0;

        const gridItem = (
            <div key={index} className={classes.gridItem}>
                {data && data.length > 0 ? (
                    <Table>
                        <thead>
                            <tr>
                                <th>{label}</th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody style={{ fontSize: "0.8rem" }}>
                            {data.map((row) => {
                                const element = elements?.find((e) => e.id === row.elementId);
                                return (
                                    <tr key={row.id}>
                                        <td>{row.order}</td>
                                        <td>{element?.projectNumber}{element?.phaseName ? '/' + element?.phaseName : ""}</td>
                                        <td>{element?.name}</td>
                                        <td>{element?.weightTons} t, {element?.length}</td>
                                        <td>{element?.provisions?.some(provision => provision.type === EProvisionType.split) ? "H" : ""}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                        <tfoot>
                            <tr>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th>{totalWeightTons.toFixed(2)} t</th>
                                <th></th>
                            </tr>
                        </tfoot>
                    </Table>
                ) : (
                    <div />
                )}
            </div>
        );

        if (index < 6) {
            firstSixItems.push(gridItem);
            firstSixWeight += totalWeightTons;
            firstSixElementCount += data?.length ?? 0;
        } else {
            lastNineItems.push(gridItem);
            lastNineWeight += totalWeightTons;
            lastNineElementCount += data?.length ?? 0;
        }
        totalWeight += totalWeightTons;
        totalElementCount += data?.length ?? 0;
    });

  return (
    <div className={classes.container}>
        <WaybillPrintHeader waybill={waybill} />
        <div className={classes.formRow}>
            <div className={classes.column}>
                {project?.offer.targetName}
            </div>
            <div className={classes.column}>
                {project?.offer.customer?.name}
            </div>
            <div className={classes.column}>
                {project?.offer.customer?.number}
            </div>
            <div className={classes.column}>

            </div>
        </div>
        <div className={classes.formRow}>
            <div className={classes.column}>
                <label>Pvm / Aika:</label>
                <div className={classes.field}>{formatDate(waybill.deliveryDate)} / {waybill.deliveryTime}</div>
            </div>
            <div className={classes.column}>
                <label>Kuljetusliike:</label>
                <div className={classes.field}>{waybill.driverOrganization}</div>
            </div>
            <div className={classes.column}>
                <label>Auto / Kuski:</label>
                <div className={classes.field}>{waybill.car} / {waybill.driver}</div>
            </div>
            <div className={classes.column}>
                <label>Kilometrit / Nakki:</label>
                <div className={classes.field}>{waybill.kilometers} {waybill.nakki ? "Nakki" : ""}</div>
            </div>
        </div>
        <div className={classes.formRow}>
            <div className={classes.doubleColumn}>
                <label>Huomautukset:</label>
                <div className={classes.field}>{waybill.notes}</div>
            </div>
            <div className={classes.doubleColumn}>
                <label>Lisä huomatukset:</label>
                <div className={classes.field}>{waybill.additionalNotes}</div>
            </div>
        </div>
        

        <div className={classes.numberedGrid}>
            {firstSixItems}
        </div>

        <div className={classes.numberedGrid}>
            {lastNineItems}
        </div>        

        <div className={classes.footer}>
            <div className={classes.formContainer}>
                <div className={classes.printableForm}>
                    <div className={classes.formRow}>
                        <div className={classes.doubleColumn}>
                            {firstSixElementCount &&
                            <div className={classes.totalWeight}>
                                Vetoauto: {firstSixWeight.toFixed(2)} t, {firstSixElementCount} kpl
                            </div>
                            }

                            {lastNineElementCount &&
                            <div className={classes.totalWeight}>
                                Perävaunu: {lastNineWeight.toFixed(2)} t, {lastNineElementCount} kpl
                            </div>
                            }

                            {totalElementCount &&
                            <div className={classes.totalWeight}>
                                Kuorma yhteensä: {totalWeight.toFixed(2)} t, {totalElementCount} kpl
                            </div>
                            }
                            <div className={classes.signing}>
                                <p>VASTAANOTETTU ja</p>
                                <p>Odotusaika</p>
                                <p>Hyväksytty:</p>
                            </div>
                        </div>
                        <div className={classes.column}>
                            <label>Lastaus päättynyt:</label>
                            <div className={classes.fieldBlank}></div>
                            <label>Saapunut klo:</label>
                            <div className={classes.fieldBlank}></div>
                            <label>Purku alkanut:</label>
                            <div className={classes.fieldBlank}></div>
                            <label>Lähtenyt klo:</label>
                            <div className={classes.fieldBlank}></div>
                            <label>Odotus aika h:</label>
                            <div className={classes.fieldBlank}></div>
                        </div>
                        <div className={classes.column}>
                            <QRCode style={{height: "128px", width: "128px", marginBottom: "22px", marginLeft: "10px"}} value={`${adminUiUrl}/qr/waybill/${waybill.uuid}`}></QRCode><br/>
                            <label>Kilometrit:</label>
                            <div className={classes.fieldBlank}></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default WaybillPrint;