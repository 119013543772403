import { useParams } from "react-router-dom";
import WaybillPrint from "../components/Waybills/WaybillPrint";
import { useWaybillsService } from "../services/waybills-service";
import IWaybill from "../shared/IWaybill";
import { ErrorResponse } from "../custom-fetch";
import { useQuery } from "@tanstack/react-query";
import { QueryKey } from "../services/query-keys";
import ErrorsAlert from "../components/ErrorsAlert/ErrorsAlert";
import Spinner from "../components/ui/Spinner/Spinner";

type Params = {
  id: string;
};

const WaybillPrintPage: React.FC = () => {
  const { id } = useParams<Params>();
  const { getWaybill } = useWaybillsService();
  
  const {
    data: waybill,
    isLoading,
    isError,
    error,
  } = useQuery<IWaybill, ErrorResponse>({
    queryKey: [QueryKey.waybills, id],
    queryFn: ({ signal }) => getWaybill({ signal, id: id! }),
  });
  return (
    <>
      {isError ? (
          <ErrorsAlert errors={error.messages} />
        ) : isLoading ? (
          <Spinner />
        ) : waybill && (
          <WaybillPrint waybill={waybill} />
        )}
    </>
  )
}
export default WaybillPrintPage;