import Container from '../components/ui/Container/Container';
import SteelPartsList from '../components/SteelParts/SteelPartsList';
import Button, { EButtonSize } from '../components/ui/Button/Button';
import { faFile, faPlus } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { Route } from '../routes';


const SteelPartsPage: React.FC = () => {
  const navigate = useNavigate();
  return (
    <>
    <h1 style={{ margin: "1rem", display: "flex", gap: "1rem", alignItems: "center",}}>
      Teräsosat{" "}
      <Button
        size={EButtonSize.SMALL}
        onClick={() => navigate(Route.steelPart("add"))}
        icon={faPlus}
        style={{ fontSize: "initial" }}
      >
        Lisää uusi
      </Button>
      <Button
        size={EButtonSize.SMALL}
        onClick={() => navigate(Route.steelPartsImport)}
        icon={faFile}
        style={{ fontSize: "initial" }}
      >
        Päivitä tiedostosta
      </Button>
    </h1>
    <Container>
      <SteelPartsList />
    </Container>
    </>
  )
}

export default SteelPartsPage;