import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import ErrorsAlert from "../components/ErrorsAlert/ErrorsAlert";
import Offer from "../components/Offers/Offer/Offer";
import Container from "../components/ui/Container/Container";
import Spinner from "../components/ui/Spinner/Spinner";
import { ErrorResponse } from "../custom-fetch";
import { useConfirmBeforeUnload } from "../hooks/useConfirmBeforeUnload";
import { useOfferService } from "../services/offers-service";
import { QueryKey } from "../services/query-keys";
import IOffer from "../shared/IOffer";

type Params = {
  id: string;
};

const OfferCopyPage: React.FC = () => {
  useConfirmBeforeUnload();
  const { id } = useParams<Params>();
  const { copyOffer } = useOfferService();

  const {
    data: offer,
    isLoading,
    isError,
    error,
  } = useQuery<IOffer, ErrorResponse>({
    queryKey: [QueryKey.offers, id],
    queryFn: ({ signal }) => copyOffer({ signal, id: id! }),
  });

  return (
    <>
      {(isError || isLoading) && (
        <>
          <h1 style={{ margin: "1rem" }}>Tarjous kopio</h1>
          <Container>
            {isError ? (
              <ErrorsAlert errors={error.messages} />
            ) : isLoading ? (
              <Spinner />
            ) : null}
          </Container>
        </>
      )}
      {!isError && !isLoading && (
        <Offer
          id={id!}
          offer={offer}
          copy={true}
        />
      )}
    </>
  );
};

export default OfferCopyPage;
