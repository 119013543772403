
import * as dateFns from 'date-fns';

export const dateIsFuture = (date?: string) => {
  if (!date) return false;
  return !dateIsPast(date);
}

export const dateIsPast = (date?: string) => {
  if (!date) return false;
  return dateFns.differenceInDays(dateFns.parseISO(date), dateFns.startOfDay(new Date())) < 0;
}

export const formatDate = (date?: string) => {
  if (!date) return "";
  return dateFns.format(dateFns.parseISO(date), 'dd.MM.yyyy');
}

export const formatDateTime = (date?: string) => {
  if (!date) return "";
  return dateFns.format(dateFns.parseISO(date), 'dd.MM.yyyy HH:mm');
}

export const formatDateRange = (startDate: string, endDate: string) => {
  const sDate = dateFns.parseISO(startDate);
  const eDate = dateFns.parseISO(endDate);
  if (dateFns.isSameDay(sDate, eDate)) {
    return dateFns.format(sDate, 'dd.MM.yyyy');
  } else if (dateFns.isSameYear(sDate, eDate)) {
    return dateFns.format(sDate, 'dd.MM') + " - " + dateFns.format(eDate, 'dd.MM.yyyy');
  } else {
    const dateArray = [];
    if (dateFns.isValid(sDate)) {
      dateArray.push(dateFns.format(sDate, 'dd.MM.yyyy'));
    }
    if (dateFns.isValid(eDate)) {
      dateArray.push(dateFns.format(eDate, 'dd.MM.yyyy'));
    }
    return dateArray.join(' - ');
  }
}