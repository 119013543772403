import { useNavigate } from "react-router-dom";

import { Route } from "../../routes";
import ErrorsAlert from "../ErrorsAlert/ErrorsAlert";
import Spinner from "../ui/Spinner/Spinner";
import Table from "../ui/Table/Table";
import { useOffersSearch } from "./useOffersSearch";

interface IProps { }

const OffersList: React.FC<IProps> = () => {
  const { isError, error, isLoading, offers } = useOffersSearch();

  const navigate = useNavigate();

  if (isError && error) {
    return <ErrorsAlert errors={error.messages} />;
  }

  if (isLoading) {
    return <Spinner />;
  }

  if (!offers || offers.length === 0) {
    return <p>Ei tarjouksia</p>;
  }

  const openHandler = (id: string) => {
    navigate(Route.offer(id));
  };

  return (
    <>
      <Table hover>
        <thead>
          <tr>
            <th>Tarjouksen numero</th>
            <th>Pvm</th>
            <th>Kohteen nimi</th>
            <th>Asiakas</th>
            <th>Myyjä</th>
          </tr>
        </thead>
        <tbody>
          {offers.map((offer) => (
            <tr key={offer.id} onClick={() => openHandler(offer.id)}>
              <td>{offer.projectNumber && offer.projectNumber + " / "}{offer.offerNumber}</td>
              <td>{offer.offerDate}</td>
              <td>{offer.targetName}</td>
              <td>{offer.customer?.name}</td>
              <td>{offer.seller?.name}</td>
              
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default OffersList;
