import { useCallback, useMemo, useState } from "react";
import IOfferRow from "../../../shared/IOfferRow";
import OfferProducts from "../../Offers/OfferProducts/OfferProducts";
import OfferProductsContextProvider from "../../Offers/OfferProducts/offer-products-context";
import Button, { EButtonColor, EButtonSize } from "../../ui/Button/Button";
import { IOption } from "../../ui/Input/Input";
import { useProjectEditContext } from "../ProjectEdit/project-edit-context";
import WeeklyProductionRows from "../WeeklyProductionRows/WeeklyProductionRows";
import ClaimsList from "../../Claims/ClaimsList";
import WaybillsList from "../../Waybills/WaybillsList";
import AdditionalInvoices from "../AdditionalInvoices/AdditionalInvoices";
import Elements from "../Elements/Elements";
import { IProjectPhase } from "../../../shared/IProject";
import { useNavigate } from "react-router-dom";
import { Route } from "../../../routes";

enum ETab {
  production,
  elements,
  order,
  delivery,
  claims,
  additionalInvoices,
}

interface ITab {
  id: ETab;
  name: string;
}

const tabs: ITab[] = [
  { id: ETab.production, name: "Tuotanto" },
  { id: ETab.elements, name: "Elementit" },
  { id: ETab.order, name: "Tilaus" },
  { id: ETab.delivery, name: "Kuljetus" },
  { id: ETab.claims, name: "Reklamaatiot" },
  { id: ETab.additionalInvoices, name: "Lisätyöt" },
];

interface IProps {
  phaseOptions: IOption[];
  phases: IProjectPhase[];
  selectedPhase?: string;
}

const ProjectTabs: React.FC<IProps> = ({ phaseOptions, phases, selectedPhase }) => {
  const defaultTab = useMemo(() => ETab.production, []);
  const [currentTab, setCurrentTab] = useState<ETab>(defaultTab);
  const navigate = useNavigate();

  const { project, weeklyProductionRowsRef, additionalInvoicesRef, elementsRef } = useProjectEditContext();
  const [currentOfferRow, setCurrentOfferRow] = useState<IOfferRow | null>(null);

  const offerProducts = useMemo(() => (
    <OfferProductsContextProvider
      value={{
        offerRows: project.offer?.rows ?? [],
        setOfferRows: () => {},
        currentOfferRow,
        setCurrentOfferRow,
        updateCurrentRow: () => Promise.reject(),
        updateConditionalTerms: () => Promise.reject(),
        currentDeliveryPriceForTon: "",
        summary: {} as any,
        setSummary: () => {},
        installations: {} as any,
        setInstallations: () => {},
        offerKilometers: [],
        overwriteDescription: true,
        setOverwriteDescription: () => {},
        showControls: false,
      }}
    >
      <OfferProducts />
    </OfferProductsContextProvider>
  ), [currentOfferRow, project.offer?.rows]);

  // const getTabStyle = useCallback((id: ETab): React.CSSProperties => currentTab === id ? {} : { display: "none" }, [currentTab]);

  const currentPhase = useMemo(() => phases.find((phase) => phase.phaseId === selectedPhase), [phases, selectedPhase]);

  const getCurrentTab = useCallback((currentTab: ETab) => {
    switch (currentTab) {
      case ETab.production:
        return (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <WeeklyProductionRows selectedPhase={currentPhase} phaseOptions={phaseOptions} ref={weeklyProductionRowsRef} />
            <div style={{ flexBasis: "min-content" }}>{offerProducts}</div>
          </div>
        );
      case ETab.elements: return <Elements phase={currentPhase} phaseOptions={phaseOptions} ref={elementsRef} />;
      case ETab.order: return offerProducts;
      case ETab.delivery:
        return (
          <div>
            <Button onClick={() => navigate(Route.waybill(`add?projectId=${project.id}`))} size={EButtonSize.SMALL}>Lisää rahtikirja</Button>
            <WaybillsList projectId={project.id} />
          </div>
        );
      case ETab.claims:
        return (
          <div>
            <Button onClick={() => navigate(Route.claim(`add?projectId=${project.id}`))} size={EButtonSize.SMALL}>Lisää reklamaatio</Button>
            <ClaimsList projectId={project.id} />
          </div>
        );
      case ETab.additionalInvoices: return <AdditionalInvoices ref={additionalInvoicesRef} />;
    }
  }, [additionalInvoicesRef, currentPhase, elementsRef, navigate, offerProducts, phaseOptions, project.id, weeklyProductionRowsRef]);

  return (
    <div style={{ display: "initial" }}>
      <div style={{ display: "flex", paddingBottom: "1rem" }}>
        {tabs.map((tab) => (
          <Button key={tab.id} onClick={() => setCurrentTab(tab.id)} color={EButtonColor.SECONDARY} size={EButtonSize.SMALL} style={tab.id === currentTab ? { background: "#f8f9fa", color: "black" } : {}}>
            {tab.name}
          </Button>
        ))}
      </div>
      {getCurrentTab(currentTab)}
    </div>
  );
};

export default ProjectTabs;
