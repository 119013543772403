import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { queryClient } from "../../App";
import { ErrorResponse } from "../../custom-fetch";
import { Route } from "../../routes";
import { QueryKey } from "../../services/query-keys";
import ErrorsAlert, { combineErrors } from "../ErrorsAlert/ErrorsAlert";
import FormButtons from "../ui/FormButtons/FormButtons";
import { ECommonValue, EInputType, IInputField } from "../ui/Input/Input";
import { useInputs } from "../ui/Input/useInputs";
import { useProductTypeService } from "../../services/productTypes-service";
import IProductType from "../../shared/IProductType";
import { useProductGroupOptions } from "../../hooks/useProductGroupOptions";
import { UNIT_OPTIONS } from "../../shared/IOfferRow";

interface IProps {
  id: string;
  productType?: IProductType;
}

enum EInputs {
  name = "name",
  productGroup = "productGroup",
  price = "price",
  unit = "unit",
  archived = "archived"
}

const ProductTypeEdit: React.FC<IProps> = ({ id, productType }) => {
  const isEdit = id !== "add";
  
  const navigate = useNavigate();

  const redirectHandler = () => {
    return navigate(Route.productTypes);
  };

  const { saveOrUpdate, error, isError, isPending } = useSaveOrUpdate(id, isEdit, redirectHandler);
  const { deleteMutate, isDeleting, isDeletingError, deletingError } = useDelete(id, redirectHandler);
  const { createInput, submit } = useProductTypeInputs(productType);
  const { loading, options, productGroups } = useProductGroupOptions();

  const submitHandler = async () => {
    const data = await submit();
    if (data) {
      const selectedProductGroup = productGroups?.find(
        (p) => p.id === data.productGroup as any
      );
      if (!selectedProductGroup) return;
      data.productGroup = selectedProductGroup;
      saveOrUpdate(data);
    }
  };

  const errorMessages = combineErrors({ isError, error }, { isError: isDeletingError, error: deletingError });

  return (
    <>
      {createInput(EInputs.name)}
      {createInput(EInputs.productGroup, {options, loading})}
      {createInput(EInputs.price)}
      {createInput(EInputs.unit)}
      {createInput(EInputs.archived)}
      {errorMessages.length > 0 && <ErrorsAlert errors={errorMessages} />}
      <FormButtons
        onSubmit={submitHandler}
        isLoading={isPending}
        onDelete={isEdit ? deleteMutate : undefined}
        isDeleting={isDeleting}
        deleteConfirmMessage="Haluatko varmasti poistaa tuotteen?"
      />
    </>
  );
};

const useProductTypeInputs = (data?: IProductType) => {
  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.name]: {
      type: EInputType.text,
      label: "Nimi",
      value: "",
      validation: {
        required: true,
      },
      autoFocus: true
    },
    [EInputs.productGroup]: {
      type: EInputType.reactSelect,
      label: "Tuoteryhmä",
      value: "",
      validation: {
        required: true,
      },
    },
    [EInputs.price]: {
      type: EInputType.number,
      label: "Hinta",
      value: "",
    },
    [EInputs.unit]: {
      type: EInputType.reactSelect,
      label: "Yksikkö",
      value: "",
      options: UNIT_OPTIONS
    },
    [EInputs.archived]: {
      type: EInputType.checkbox,
      label: "Arkistoitu",
      options: [{ value: ECommonValue.YES }],
      value: [],
    },
  });

  const { createInput, submit } = useInputs({ data, inputs, setInputs });

  return { createInput, submit };
};

const useSaveOrUpdate = (id: string, isEdit: boolean, redirectHandler: () => void) => {
  const { updateProductType, saveProductType } = useProductTypeService();

  const mutationFn = (data: IProductType) => {
    return isEdit ? updateProductType(id, data) : saveProductType(data);
  };

  const {
    mutate: saveOrUpdate,
    isPending,
    isError,
    error,
  } = useMutation<IProductType, ErrorResponse, IProductType>({
    mutationFn,
    onSuccess: () => {
      queryClient.removeQueries({ queryKey: [QueryKey.productTypes, id] });
      queryClient.invalidateQueries({ queryKey: [QueryKey.productTypes] });
      queryClient.invalidateQueries({ queryKey: [QueryKey.productTypeOptions] });
      redirectHandler();
    },
  });

  return { saveOrUpdate, isPending, isError, error };
};

const useDelete = (id: string, redirectHandler: () => void) => {
  const { deleteProductType } = useProductTypeService();

  const {
    mutate: deleteMutate,
    isPending: isDeleting,
    isError: isDeletingError,
    error: deletingError,
  } = useMutation<boolean, ErrorResponse>({
    mutationFn: () => deleteProductType(id),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.productTypes],
        refetchType: "none",
      });
      queryClient.invalidateQueries({
        queryKey: [QueryKey.productTypeOptions],
        refetchType: "none",
      });
      redirectHandler();
    },
  });

  return { deleteMutate, isDeleting, isDeletingError, deletingError };
};

export default ProductTypeEdit;
