import { useCallback, useState } from "react";
import IOfferTerm from "../../../shared/IOfferTerm";
import Button, { EButtonColor, EButtonSize } from "../../ui/Button/Button";
import OfferTextTabOfferTerms from "./OfferTextTabOfferTerms/OfferTextTabOfferTerms";
import OfferTextTabPlanning from "./OfferTextTabPlanning/OfferTextTabPlanning";

enum EOfferTab {
  offerTerms,
  planning,
  considerations,
}

interface IOfferTab {
  id: EOfferTab;
  name: string;
}

const offerTabs: IOfferTab[] = [
  { id: EOfferTab.planning, name: "Suunnittelu" },
  { id: EOfferTab.offerTerms, name: "Ehdot ja tekstit" },
  //{ id: EOfferTab.considerations, name: "Huomiot" },
];

interface IProps {
  offerTerms: IOfferTerm[];
  setOfferTerms: React.Dispatch<React.SetStateAction<IOfferTerm[]>>;
  planningNotes: string;
  setPlanningNotes: React.Dispatch<React.SetStateAction<string>>;
  /*considerations: IConsiderations;
  setConsiderations: React.Dispatch<React.SetStateAction<IConsiderations>>;*/
  showOrderFields: boolean;
}

const OfferTextTabs: React.FC<IProps> = ({ offerTerms, setOfferTerms, planningNotes, setPlanningNotes, /*considerations, setConsiderations, showOrderFields*/ }) => {
  const [currentTab, setCurrentTab] = useState<EOfferTab>(EOfferTab.planning);

  const getCurrentTab = useCallback((id: EOfferTab) => {
    switch (id) {
      case EOfferTab.offerTerms:
        return <OfferTextTabOfferTerms offerTerms={offerTerms} setOfferTerms={setOfferTerms}/>;
      case EOfferTab.planning:
        return <OfferTextTabPlanning planningNotes={planningNotes} setPlanningNotes={setPlanningNotes} />;
      /*case EOfferTab.considerations:
        return <OfferTextTabConsiderations considerations={considerations} setConsiderations={setConsiderations} showOrderFields={showOrderFields} />;*/
    }
  }, [offerTerms, setOfferTerms, planningNotes, setPlanningNotes]);

  return (
    <div style={{paddingTop: "1rem", borderTop: "thin solid gray", marginTop: "1rem"}}>
      <div style={{ display: "flex", paddingBottom: "1rem" }}>
        {offerTabs.map((tab) => (
          <Button
            key={tab.id}
            onClick={() => setCurrentTab(tab.id)}
            color={EButtonColor.SECONDARY}
            size={EButtonSize.SMALL}
            style={
              tab.id === currentTab ? { background: "#f8f9fa", color: "black" } : {}
            }
          >
            {tab.name}
          </Button>
        ))}
      </div>
      {getCurrentTab(currentTab)}
    </div>
  );
};

export default OfferTextTabs;
