import { useMutation } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { queryClient } from "../../App";
import { ErrorResponse } from "../../custom-fetch";
import { Route } from "../../routes";
import { QueryKey } from "../../services/query-keys";
import ErrorsAlert, { combineErrors } from "../ErrorsAlert/ErrorsAlert";
import FormButtons from "../ui/FormButtons/FormButtons";
import { ECommonValue, EInputType, IInputField, TInputValue } from "../ui/Input/Input";
import { useInputs } from "../ui/Input/useInputs";
import IWaybill, { IWaybillRow } from "../../shared/IWaybill";
import { useWaybillsService } from "../../services/waybills-service";
import { FactoryOptions } from "../../shared/FactoryOptions";
import { useProjectOptions } from "../../hooks/useProjectOptions";
import Table from "../ui/Table/Table";
import WaybillRow from "./WaybillRow";
import { v4 as uuid } from "uuid";
import InputContextProvider, { EInputUpdateAction } from "../ui/Input/input-context";
import Button from "../ui/Button/Button";
import { formatInputValue } from "../ui/Input/input-utils";
import { useElementOptions } from "../../hooks/useElementOptions";
import { EFactory } from "../../shared/IOfferRow";

interface IProps {
  id: string;
  waybill?: IWaybill;
  projectId?: string | null;
}

enum EInputs {
  projectId = "projectId",
  projectNumber = "projectNumber",
  destination = "destination",
  driverOrganization = "driverOrganization",
  driver = "driver",
  car = "car",
  deliveryDate = "deliveryDate",
  deliveryTime = "deliveryTime",
  deliveryPrice = "deliveryPrice",
  waitingTime = "waitingTime",
  waitingPrice = "waitingPrice",
  waitingReason = "waitingReason",
  kilometers = "kilometers",
  accepted = "accepted",
  price = "price",
  tons = "tons",
  tonsInvoice = "tonsInvoice",
  count = "count",
  notes = "notes",
  additionalNotes = "additionalNotes",
  factory = "factory",
  orderNumber = "orderNumber",
  nakki = "nakki",
}

const WaybillEdit: React.FC<IProps> = ({ id, waybill, projectId: paramProjectId }) => {
  const isEdit = id !== "add";
  
  const navigate = useNavigate();

  const redirectHandler = () => {
    return navigate(Route.waybills);
  };

  const { saveOrUpdate, error, isError, isPending } = useSaveOrUpdate(id, isEdit);
  const { deleteMutate, isDeleting, isDeletingError, deletingError } = useDelete(id, redirectHandler);
  const { createInput, submit, inputs, setInputs } = useWaybillInputs(waybill);

  const [waybillRows, setWaybillRows] = useState<IWaybillRow[]>(waybill?.waybillRows ?? []);
  const { addHandler, deleteHandler, updateHandler } = useHandlers(waybillRows, setWaybillRows);

  const { projects, options: projectOptions, loading: projectOptionsLoading } = useProjectOptions();

  const projectId = paramProjectId ?? inputs[EInputs.projectId].value as string;
  const factory = inputs[EInputs.factory].value as string;

  const project = useMemo(() => projects?.find((p) => p.id.toString() === projectId.toString()), [projectId, projects]);

  const projectNumber = project?.offer?.projectNumber;
  const { elements, options: elementOptions/*, loading: elementOptionsLoading*/ } = useElementOptions(projectId, factory as EFactory, true, waybill?.waybillId);

  const tons = useMemo(() => waybillRows.reduce((sum, row) => {
      const element = elements?.find(e => e.id === row.elementId);
      const weightTons = parseFloat(element?.weightTons ?? "0");
      return sum + weightTons;
  }, 0), [elements, waybillRows]);

  const count = waybillRows.length;

  const kilometerCalculation = project?.offer?.kilometerCalculations?.find((kc) => kc.factory === factory);
  const deliveryPrice = +(kilometerCalculation?.offerFreightPrice ?? 0);
  const price = (deliveryPrice * tons).toFixed(2);
  const kilometers = kilometerCalculation?.freightKilometers;

  const orderNumber = project?.offer.customerReferenceNumber??"";

  const destination = [
    project?.offer.deliveryStreetAddress, 
    project?.offer.deliveryZip, 
    project?.offer.deliveryCity
  ].filter(Boolean).join(", ");

  useEffect(() => {
    if (paramProjectId && project) {
      setInputs((inputs) => {
        const newInputs = {...inputs};
        newInputs[EInputs.projectId].value = paramProjectId;
        newInputs[EInputs.destination].value = destination;
        newInputs[EInputs.orderNumber].value = orderNumber;
        return newInputs;
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramProjectId, project]);

  useEffect(() => {
    if (projectId && waybill?.projectId !== projectId) {
      setInputs((inputs) => {
        const newInputs = {...inputs};
        newInputs[EInputs.destination].value = destination;
        newInputs[EInputs.orderNumber].value = orderNumber;
        return newInputs;
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  const handleSubmitData = async (redirectHandler?: () => void) => {
    const data = await submit();
    if (data) {
      data.kilometers = kilometers ?? "0";
      data.projectNumber = projectNumber ?? "";
      data.deliveryPrice = deliveryPrice.toString();
      data.price = price;
      data.waybillRows = waybillRows;
      data.count = count.toString();
      data.tons = tons.toString();
      saveOrUpdate(data, { onSuccess: redirectHandler });
    }
  };

  const submitHandler = async () => {
    await handleSubmitData();
  };

  const waybillPrintHandler = async () => {
    await handleSubmitData(() => {
      navigate(Route.waybillPrint(id));
    });
  };

  const waybillPrintElementsHandler = async () => {
    await handleSubmitData(() => {
      navigate(Route.waybillPrintElements(id))
    });
  };

  const waybillElementIds = useMemo(() => [...waybillRows].filter(waybill => !!waybill.elementId).map(waybill => waybill.elementId!) ?? [], [waybillRows]);

  const errorMessages = combineErrors({ isError, error }, { isError: isDeletingError, error: deletingError });

  return (
    <>
      <div style={{ display: "flex", width: "1500px" }}>
        <div style={{ width: "500px", paddingRight: "20px" }}>
          {createInput(EInputs.projectId, {options: projectOptions, loading: projectOptionsLoading})}
          {createInput(EInputs.factory)}
          {createInput(EInputs.driverOrganization)}
          {createInput(EInputs.waitingTime)}
          {createInput(EInputs.tons)}
          <div style={{marginTop: "2rem", marginBottom: "2rem"}}>
            <span>Paino: {tons.toFixed(2)} t</span><br/>
            <span>Kappaleet: {count}</span><br/>
            <span>Kilometrit: {kilometers} km</span><br/>
            <span>Kuljetus hinta: {deliveryPrice} €/ton</span><br/>
            <span>Hinta: {price} €</span>
          </div>
          {createInput(EInputs.accepted)}
          {createInput(EInputs.nakki)}
        </div>
        <div style={{ width: "500px"}}>
          {createInput(EInputs.destination)}
          {createInput(EInputs.deliveryDate)}
          {createInput(EInputs.driver)}
          {createInput(EInputs.waitingReason)}
          {createInput(EInputs.tonsInvoice)}
          {createInput(EInputs.notes)}
        </div>
        <div style={{ width: "500px", paddingLeft: "20px" }}>
          {createInput(EInputs.orderNumber)}
          {createInput(EInputs.deliveryTime)}
          {createInput(EInputs.car)}
          {createInput(EInputs.waitingPrice)}
          {createInput(EInputs.count)}
          {createInput(EInputs.additionalNotes)}
        </div>
      </div>

      <div style={{ width: "1000px" }}>
      <Table>
        <thead>
          <tr>
            <th>Paikka</th>
            <th>Järjestys</th>
            <th>Elementti</th>
            <th>Paino</th>
            <th>Pituus</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <InputContextProvider initDone onAutoUpdate={updateHandler}>
            {waybillRows.map((row, index) => (
              <WaybillRow key={index} row={row} onDelete={() => deleteHandler(row.id)} elements={elements} elementOptions={elementOptions} waybillElementIds={waybillElementIds} />
            ))}
          </InputContextProvider>
        </tbody>
        <tfoot>
          <tr>
            <th colSpan={6}><Button onClick={addHandler}>Uusi rivi</Button></th>
          </tr>
      </tfoot>
      </Table>
      </div>

      {errorMessages.length > 0 && <ErrorsAlert errors={errorMessages} />}
      <FormButtons
        onSubmit={submitHandler}
        isLoading={isPending}
        onDelete={isEdit ? deleteMutate : undefined}
        isDeleting={isDeleting}
        deleteConfirmMessage="Haluatko varmasti poistaa rahtikirjan?"
        style={{ marginTop: "1rem" }}
      >
        {isEdit && 
        <>
          <Button loading={isPending} onClick={waybillPrintHandler}>Rahtikirja</Button>
          <Button loading={isPending} onClick={waybillPrintElementsHandler}>Lähetyslista</Button>
        </>
        }
      </FormButtons>
    </>
  );
};

const useWaybillInputs = (data?: IWaybill) => {
  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.projectId]: {
      type: EInputType.reactSelect,
      label: "Työmaa",
      value: "",
    },
    [EInputs.projectNumber]: {
      type: EInputType.text,
      label: "Työmaanumero",
      value: "",
    },
    [EInputs.destination]: {
      type: EInputType.text,
      label: "Sijainti",
      value: "",
    },
    [EInputs.driverOrganization]: {
      type: EInputType.text,
      label: "Kuljettaja liike",
      value: "",
    },
    [EInputs.driver]: {
      type: EInputType.text,
      label: "Kuski",
      value: "",
    },
    [EInputs.car]: {
      type: EInputType.text,
      label: "Auto",
      value: "",
    },
    [EInputs.deliveryDate]: {
      type: EInputType.date,
      label: "Pvm",
      value: "",
      validation: {
        required: true,
      },
    },
    [EInputs.deliveryTime]: {
      type: EInputType.time,
      label: "Aika",
      value: "",
    },
    [EInputs.waitingTime]: {
      type: EInputType.number,
      label: "Odotukset, tuntia",
      value: "",
    },
    [EInputs.waitingPrice]: {
      type: EInputType.number,
      label: "Odotuksen hinta € /h",
      value: "",
    },
    [EInputs.waitingReason]: {
      type: EInputType.text,
      label: "Odotuksen syy",
      value: "",
    },
    [EInputs.accepted]: {
      type: EInputType.checkbox,
      label: "Hyväksytty",
      options: [{ value: ECommonValue.YES }],
      value: [],
    },
    [EInputs.tonsInvoice]: {
      type: EInputType.number,
      label: "Tonnit laskulta",
      value: "",
    },
    [EInputs.notes]: {
      type: EInputType.textarea,
      label: "Huomautukset",
      value: "",
      rows: 4,
    },
    [EInputs.additionalNotes]: {
      type: EInputType.textarea,
      label: "Lisä huom",
      value: "",
      rows: 4,
    },
    [EInputs.factory]: {
      type: EInputType.reactSelect,
      label: "Tehdas",
      value: "",
      options: FactoryOptions,
    },
    [EInputs.orderNumber]: {
      type: EInputType.text,
      label: "Tilausnumero",
      value: "",
    },
    [EInputs.nakki]: {
      type: EInputType.checkbox,
      label: "nakki",
      options: [{ value: ECommonValue.YES }],
      value: [],
    },
  });

  const { createInput, submit } = useInputs({ data, inputs, setInputs });

  return { createInput, submit, inputs, setInputs };
};

const useSaveOrUpdate = (id: string, isEdit: boolean) => {
  const { updateWaybill, saveWaybill } = useWaybillsService();
  const navigate = useNavigate();

  const mutationFn = (data: IWaybill) => {
    return isEdit ? updateWaybill(id, data) : saveWaybill(data);
  };

  const {
    mutate: saveOrUpdate,
    isPending,
    isError,
    error,
  } = useMutation<IWaybill, ErrorResponse, IWaybill>({
    mutationFn,
    onSuccess: (data) => {
      console.log(data)
      queryClient.removeQueries({ queryKey: [QueryKey.waybills, id] });
      queryClient.invalidateQueries({ queryKey: [QueryKey.waybills] });
      if (!isEdit) navigate(Route.waybill(data.id));
    },
  });

  return { saveOrUpdate, isPending, isError, error };
};

const useDelete = (id: string, redirectHandler: () => void) => {
  const { deleteWaybill } = useWaybillsService();

  const {
    mutate: deleteMutate,
    isPending: isDeleting,
    isError: isDeletingError,
    error: deletingError,
  } = useMutation<boolean, ErrorResponse>({
    mutationFn: () => deleteWaybill(id),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.waybills],
        refetchType: "none",
      });
      redirectHandler();
    },
  });

  return { deleteMutate, isDeleting, isDeletingError, deletingError };
};

const useHandlers = (
  rows: IWaybillRow[],
  setWaybillRows: React.Dispatch<React.SetStateAction<IWaybillRow[]>>,
) => {

  const addHandler = useCallback(() => {
    const newRow = {id: uuid()};
    setWaybillRows((rows) => [...rows, newRow]);
  }, [setWaybillRows]);

  const deleteHandler = useCallback(
    async (id: string) => {
      const waybillRows = rows.filter((row) => row.id !== id)
      setWaybillRows([...waybillRows]);
    },
    [rows, setWaybillRows]
  );

  const updateHandler = useCallback(
    async (inputName: string, value: TInputValue, action: string, _: any) => {
      if (action === EInputUpdateAction.WAYBILL_ROW) {
        
        const data = _ as IWaybillRow;

        const index = rows.findIndex((r) => r.id === data.id);
        if (index > -1) {
          rows[index] = {
            ...data,
            [inputName]: formatInputValue(value),
          };
        }

        setWaybillRows([...rows]);
      }
      return Promise.resolve(true);
    },
    [rows, setWaybillRows]
  );

  return { addHandler, deleteHandler, updateHandler };
};

export default WaybillEdit;
