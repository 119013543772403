import { useQuery } from "@tanstack/react-query";

import { ErrorResponse } from "../../custom-fetch";
import { QueryKey } from "../../services/query-keys";
import ITransportationCost from "../../shared/ITransportationCost";
import ErrorsAlert from "../ErrorsAlert/ErrorsAlert";
import Spinner from "../ui/Spinner/Spinner";
import { useState } from "react";
import Input, { EInputType } from "../ui/Input/Input";
import { useTransportationCostService } from "../../services/transportationCosts-service";
import Table from "../ui/Table/Table";
import { Route } from "../../routes";
import { useNavigate } from "react-router-dom";

const TransportationCostsList: React.FC = () => {
  const { fetchTransportationCosts } = useTransportationCostService();
  const [filterValue, setFilterValue] = useState<string>('');
  const navigate = useNavigate();

  const openHandler = (id: string) => {
    navigate(Route.transportationCost(id));
  };

  const {
    data: transportationCosts,
    isPending,
    isError,
    error,
  } = useQuery<ITransportationCost[], ErrorResponse>({
    queryKey: [QueryKey.transportationCosts],
    queryFn: fetchTransportationCosts,
    staleTime: 5000,
  });

  if (isError) {
    return <ErrorsAlert errors={error.messages} />;
  }

  if (isPending) {
    return <Spinner />;
  }

  if (!transportationCosts || transportationCosts.length === 0) {
    return <p>Ei kuljetushintoja</p>;
  }

  const filteredTransportationCosts = transportationCosts.filter(
    (transportationCost) =>
      transportationCost.factoriesString?.toLowerCase().includes(filterValue)
  );

  return (
    <>
      <Input
        inputName="transportationCostsFilter"
        type={EInputType.text}
        placeholder="Suodata tehtaalla"
        value={filterValue}
        onChange={(value) => setFilterValue(value.toString().toLowerCase())}
        autoFocus={true}
      />
      <Table hover>
        <thead>
          <tr>
            <th>Tehtaat</th>
            <th>Alku</th>
            <th>Loppu</th>
            <th>Hinta</th>
            <th>Päivitetty</th>
            <th>Arkistoitu</th>
          </tr>
        </thead>
        <tbody>
          {filteredTransportationCosts.map((transportationCost) => (
            <tr
              key={transportationCost.id}
              onClick={() => openHandler(transportationCost.id)}
              style={{
                color: transportationCost.archived ? "gray" : "inherit",
                fontStyle: transportationCost.archived ? "italic" : "inherit",
              }}
            >
              <td>{transportationCost.factoriesString}</td>
              <td>{transportationCost.kilometerStart} km</td>
              <td>{transportationCost.kilometerEnd} km</td>
              <td>{transportationCost.price} €</td>
              <td>{transportationCost.updatedString}</td>
              <td>{transportationCost.archived && "arkistoitu"}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default TransportationCostsList;
