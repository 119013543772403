import React, {
  CSSProperties,
  ReactNode,
  useCallback,
  useEffect,
  useState
} from "react";

import { Accept } from "react-dropzone";
import TextareaAutosize from 'react-textarea-autosize';
import Dropzone from "../../Dropzone/Dropzone";
import AsyncSelect from "../AsyncSelect/AsyncSelect";
import Button, { EButtonSize } from "../Button/Button";
import Checkbox from "../Checkbox/Checkbox";
import RadioButton from "../RadioButton/RadioButton";
import Select, { TMenuPosition } from "../Select/Select";
import Spinner, { ESpinnerSize } from "../Spinner/Spinner";
import classes from "./Input.module.scss";
import { useInputContext } from "./input-context";


export type TFetchOption = (
  searchTerm: string,
  signal?: AbortSignal
) => Promise<IOption[]>;

type TOptionGroup = {
  options: IOption[];
  label: string;
}

export type TOptions = IOption[] | TOptionGroup[];

export interface IOptionable {
  id: string | number;
  name: string;
}

export enum ECommonValue {
  ALL = "ALL",
  YES = "Y",
  NO = "N",
  MALE = "MALE",
  FEMALE = "FEMALE",
}

export enum EInfoColor {
  DANGER,
}

export interface IOption {
  value: string;
  label?: string;
  info?: string;
}

export enum EInputType {
  static = "static",
  text = "text",
  number = "number",
  radio = "radio",
  date = "date",
  time = "time",
  email = "email",
  tel = "tel",
  select = "select",
  checkbox = "checkbox",
  textarea = "textarea",
  reactSelect = "reactSelect",
  reactAsyncSelect = "reactAsyncSelect",
  wysiwyg = "wysiwyg",
  dropzone = "dropzone",
}

export type TOptionsDirection = "horizontal" | "vertical";

export interface IValidSamples {
  [key: string]: boolean;
}

export interface IValidationResult {
  isValid: boolean;
  message: string | null;
}

export interface IInputValidation {
  required?: boolean;
  requiredMessage?: string;
  requiredCompareValue?: string;
  requiredIf?: string;
  requiredIfValue?: string | string[];
  requiredIfNot?: string;
  dependencies?: string[];
  minLength?: number;
  minLengthMessage?: string;
  maxLength?: number;
  maxLengthMessage?: string;
  minAmount?: number;
  minAmountMessage?: string;
  maxAmount?: number;
  maxAmountMessage?: string;
}

export interface IInputFieldItem {
  type: EInputType;
  label?: string;
  value: TInputValue;
  options?: TOptions;
  placeholder?: string;
  disabled?: boolean;
  validation?: IInputValidation;
  validationResult?: IValidationResult;
  max?: string;
  min?: string;
  maxLength?: number;
  multiple?: boolean;
  rows?: number;
  menuPosition?: TMenuPosition;
  optionsDirection?: TOptionsDirection;
  disableInit?: boolean;
  hideControls?: boolean;
  autoFocus?: boolean;
  isCreatable?: boolean;
  pre?: string;
  post?: string;
  disallowDecimals?: boolean;
  boldContent?: boolean;
}

export interface IInputField {
  [key: string]: IInputFieldItem;
}

export type TInputValue = string | string[] | IOption | IOption[] | boolean;

export interface IInputOptions {
  updateAction?: string;
  weight?: number;
  info?: string;
  topInfo?: string | JSX.Element;
  infoColor?: EInfoColor;
  data?: Object;
  disabled?: boolean;
  max?: string;
  min?: string;
  maxLength?: number;
  menuPosition?: TMenuPosition;
  optionsDirection?: TOptionsDirection;
  showLabel?: boolean;
  labelStyles?: CSSProperties;
  containerStyles?: CSSProperties;
  showValidation?: boolean;
  onBlurModifyValue?: (value: TInputValue) => TInputValue;
  onBlur?: (value: TInputValue, event?: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (value: TInputValue) => void;
  onClick?: (event: React.MouseEvent<HTMLInputElement | HTMLSelectElement, MouseEvent>) => void;
  hideUnselected?: boolean;
  pre?: string;
  post?: string;
  options?: TOptions;
  fetchOptions?: TFetchOption;
  multiple?: boolean;
  style?: CSSProperties;
  label?: string;
  placeholder?: string;
  postButtonText?: ReactNode;
  onPostButtonClick?: () => void;
  onAdd?: (value: string) => Promise<IOption>;
  confirmValueChange?: (value: TInputValue) => Promise<boolean>;
  loading?: boolean;
  validationResult?: IValidationResult;
  onDrop?: (inputName: string, acceptedFiles: File[]) => void;
  accept?: Accept;
  hideControls?: boolean;
  isClearable?: boolean;
  autoFocus?: boolean;
  isCreatable?: boolean;
  title?: string;
  disallowDecimals?: boolean;
  boldContent?: boolean;
}

interface IProps extends IInputOptions {
  value: TInputValue;
  type: EInputType;
  onChange: (value: TInputValue) => void;
  inputName: string;
  options?: TOptions;
  placeholder?: string;
  rows?: number;
}

export const isInputDisabled = (
  disableFields: boolean,
  item: IInputFieldItem,
  options?: IInputOptions
) => {
  return disableFields || item.disabled || (options && options.disabled);
};

export const inputsToObject = function <T>(inputs: IInputField): T {
  const result: { [key: string]: TInputValue } = {};

  for (let key in inputs) {
    result[key] = inputs[key].value;
  }

  return (result as unknown) as T;
};

const Input: React.FC<IProps> = ({
  label,
  value,
  onChange,
  type,
  options,
  weight,
  inputName,
  info,
  topInfo,
  infoColor,
  placeholder,
  validationResult = { isValid: true, message: "" },
  data,
  disabled,
  max,
  min,
  maxLength,
  showLabel,
  updateAction,
  containerStyles,
  showValidation,
  pre,
  post,
  onClick,
  hideUnselected,
  onBlurModifyValue,
  onBlur,
  onFocus,
  fetchOptions,
  multiple,
  style,
  postButtonText,
  onPostButtonClick,
  onAdd,
  labelStyles,
  confirmValueChange, 
  rows,
  loading,
  menuPosition,
  optionsDirection,
  onDrop,
  accept,
  hideControls,
  isClearable,
  autoFocus,
  isCreatable,
  title,
  disallowDecimals,
  boldContent
}) => {
  const { onAutoUpdate, initDone } = useInputContext();

  const [initValue, setInitValue] = useState(value);
  const [focusValue, setFocusValue] = useState<TInputValue>();

  useEffect(() => {
    if (initDone) {
      setInitValue(value);
    }
    // eslint-disable-next-line
  }, [initDone]);

  const autoUpdateHandler = (value: TInputValue, event?: React.FocusEvent<HTMLInputElement>, skipInitValueCheck?: boolean) => {
    if (onBlur) {
      onBlur(value, event);
    }

    if (onBlurModifyValue) {
      value = onBlurModifyValue(value);
    }
    if (initValue !== value || skipInitValueCheck || (initValue === value && value === "")) {
      if (updateAction && onAutoUpdate) {
        onAutoUpdate(inputName, value, updateAction, data); // Maybe needs some checks here if context missing.
      }
      setInitValue(value);
    }
  };
  
  const canUpdateValue = async (value: TInputValue) => {
    let updateValue = true;
    if (confirmValueChange) {
      updateValue = await confirmValueChange(value);
    }
    return updateValue;
  }

  const singleChangeHandler = async (value: TInputValue) => {
    if (await canUpdateValue(value)) {
      onChange(value);
      autoUpdateHandler(value, undefined, true);
    }
  };


  const inputBlurHandler = async (ev: React.FocusEvent<HTMLInputElement>) => {
    if (await canUpdateValue(value)) {
      autoUpdateHandler(value, ev)
    } else {
      onChange(focusValue ?? '');
    }
  }

  const inputFocusHandler = () => {
    setFocusValue(value);
    if (onFocus) {
      onFocus(value);
    }
  }

  const infoClasses = [classes.Info];

  switch (infoColor) {
    case EInfoColor.DANGER:
      infoClasses.push(classes.InfoDanger);
      break;
    default:
  }

  const getLabel = useCallback((label?: string, showLabel?: boolean) => {
    if (!label && !showLabel) return null;

    return (
      <label className={classes.Label} style={labelStyles}>
        {label} {showLabel && <>&nbsp;</>}
      </label>
    );
  }, [labelStyles]);

  const invalid = showValidation && !validationResult.isValid && !disabled;

  const invalidMessage =
    showValidation && !validationResult.isValid && validationResult.message;

  const classNames = [classes.Container];

  if (invalid) {
    classNames.push(classes.Invalid);
  }

  if (boldContent && value) {
    classNames.push(classes.BoldContent);
  }

  const inputGroupClassNames = [classes.InputGroup];

  if (pre) {
    inputGroupClassNames.push(classes.HasPre);
  }

  if (post || (postButtonText && onPostButtonClick)) {
    inputGroupClassNames.push(classes.HasPost);
  }

  const disallowDecimalsHandler = useCallback((event: React.KeyboardEvent) => {
    if (event.key === "." || event.key === ",") {
      event.preventDefault();
    }
  }, []);

  return (
    <div
      className={classNames.join(" ")}
      style={{ flexGrow: weight, ...containerStyles }}
    >
      {getLabel(label, showLabel)}

      {topInfo && <div className={classes.TopInfo}>{topInfo}</div>}

      <div className={inputGroupClassNames.join(" ")}>
        {pre && <div className={classes.Pre}>{pre}</div>}

        {(() => {
          switch (type) {
            case EInputType.text:
            case EInputType.number:
            case EInputType.date:
            case EInputType.time:
            case EInputType.email:
            case EInputType.tel:
              if (loading) {
                return <div className={[classes.Input, classes.InputHeight].join(' ')}><Spinner size={ESpinnerSize.SMALL} /> </div>
              }
              return (
                <input
                  className={[classes.Input, classes.InputHeight].join(' ')}
                  disabled={disabled}
                  type={type}
                  value={value as string}
                  placeholder={placeholder !== undefined ? placeholder : label}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    let value = event.target.value;
                    if (type === EInputType.number && disallowDecimals) {
                      value = value.replaceAll(/[^0-9]*/g, "");
                    }
                    onChange(value);
                  }}
                  onBlur={inputBlurHandler}
                  onFocus={inputFocusHandler}
                  onClick={(ev) => {
                    // breaks set focus on offer tab
                    // ev.stopPropagation();
                    if (onClick) {
                      onClick(ev);
                    }
                  }}
                  max={max}
                  min={min}
                  style={style}
                  maxLength={maxLength}
                  autoFocus={autoFocus}
                  onWheel={(e) => e.currentTarget.blur()}
                  title={title}
                  onKeyDown={disallowDecimals ? disallowDecimalsHandler : undefined}
                />
              );

            case EInputType.select:
              return (
                <select
                  disabled={disabled}
                  className={classes.Input}
                  onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                    singleChangeHandler(event.target.value)
                  }
                  value={value as string}
                  onClick={onClick}
                  style={style}
                >
                  {options &&
                    (options as IOption[]).map((option) => (
                      <option key={option.value} value={option.value}>
                        {" "}
                        {option.label}
                      </option>
                    ))}
                </select>
              );
            case EInputType.reactSelect:
              return (
                <Select 
                  name={inputName}
                  onChange={singleChangeHandler}
                  value={value as string | string[]}
                  options={options}
                  multiple={multiple}
                  placeholder={placeholder}
                  loading={loading}
                  disabled={disabled}
                  menuPosition={menuPosition}
                  hideControls={hideControls}
                  isClearable={isClearable !== undefined ? isClearable : true}
                  autoFocus={autoFocus}
                  isCreatable={isCreatable}
                />
              );
            case EInputType.reactAsyncSelect:
              return (
                <AsyncSelect
                  onChange={singleChangeHandler}
                  value={value as string | string[]}
                  multiple={multiple}
                  placeholder={placeholder}
                  fetchOptions={fetchOptions as TFetchOption}
                  disabled={disabled}
                  options={options as IOption[]}
                  menuPosition={menuPosition}
                />
              );
            case EInputType.radio:
              return (
                <RadioButton
                  value={value as string}
                  options={options as IOption[]}
                  onChange={singleChangeHandler}
                  disabled={disabled}
                  invalid={invalid}
                  hideUnselected={hideUnselected}
                />
              );

            case EInputType.checkbox:
              return (
                <Checkbox
                  name={inputName}
                  value={value as string[]}
                  options={options as IOption[]}
                  onChange={singleChangeHandler}
                  disabled={disabled}
                  invalid={invalid}
                  optionsDirection={optionsDirection}
                />
              );

            case EInputType.textarea:
              return (
                <TextareaAutosize
                  className={classes.Input}
                  value={value as string}
                  placeholder={placeholder ? placeholder : label}
                  onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
                    onChange(event.target.value)
                  }
                  onClick={(ev) => ev.stopPropagation()}
                  onBlur={() => autoUpdateHandler(value)}
                  disabled={disabled}
                  minRows={rows}
                  maxLength={maxLength}
                  autoFocus={autoFocus}
                  onWheel={(e) => e.currentTarget.blur()}
                  title={title}
                  onKeyDown={disallowDecimals ? disallowDecimalsHandler : undefined}
                />
              );
            case EInputType.static:
              return <p style={{ margin: 0 }}>{value as string}</p>;
            case EInputType.dropzone:
              return (
                onDrop && (
                  <Dropzone
                    onDrop={(acceptedFiles) => onDrop(inputName, acceptedFiles)}
                    multiple={multiple}
                    disabled={disabled}
                    accept={accept}
                  />
                )
              );
          }
        })()}

        {post && <div className={classes.Post}>{post}</div>}
        {onPostButtonClick && postButtonText && !disabled && (
          <Button onClick={onPostButtonClick} className={classes.PostButton} size={EButtonSize.X_SMALL}>
            {postButtonText}
          </Button>
        )}
      </div>

      {info && <div className={infoClasses.join(" ")}>{info}</div>}

      {invalidMessage && (
        <div className={[classes.Info, classes.InfoDanger].join(" ")}>
          {invalidMessage}
        </div>
      )}
    </div>
  );
};

export default React.memo(Input, (prevProps, nextProps) => {
  return (
    prevProps.value === nextProps.value &&
    prevProps.validationResult === nextProps.validationResult &&
    prevProps.disabled === nextProps.disabled &&
    prevProps.showValidation === nextProps.showValidation &&
    prevProps.options === nextProps.options &&
    prevProps.min === nextProps.min &&
    prevProps.info === nextProps.info &&
    prevProps.topInfo === nextProps.topInfo &&
    prevProps.pre === nextProps.pre &&
    prevProps.post === nextProps.post &&
    prevProps.onClick === nextProps.onClick &&
    prevProps.label === nextProps.label && 
    prevProps.placeholder === nextProps.placeholder &&
    prevProps.loading === nextProps.loading &&
    prevProps.title === nextProps.title
  );
});
