import { Outlet, useBlocker } from 'react-router-dom';

import Header from '../components/Header/Header';
import AuthContextProvider from '../context/auth-context';
import NavigationBlockerContextProvider from '../context/navigation-blocker-context';
import { useNavigationBlocker } from '../hooks/useNavigationBlocker';

const RootLayout: React.FC = () => {
  const blocker = useBlocker(({ currentLocation }) =>
    currentLocation.pathname.startsWith("/offers")
  );
  const { showNavigationBlockerModal, showBlocker, setIsDirty } = useNavigationBlocker(blocker);
  return (
    <NavigationBlockerContextProvider value={{ showNavigationBlockerModal, showBlocker, setIsDirty }}>
      <AuthContextProvider>
      <Header />
      <main>
        <Outlet />
      </main>
    </AuthContextProvider>
    </NavigationBlockerContextProvider>
  );
};

/*
export class Routes {

  public static withParams(route: ERoute, params: IParams): string {
    let routeWithParams: string = route;
    for(let key in params) {
      routeWithParams = routeWithParams.replace(`:${key}`, params[key]);
    }
    return routeWithParams;
  }
}
*/

/*
const RootLayout: React.FC = () => {
  const { isAuth, logout, isLoggingOut, user } = useAuthContext();

  const isVisible = (userRoles: UserRole[]) =>
    userRoles.length === 0 || (user && userRoles.includes(user.role));

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  
  return (
    <>
      <Navbar isBordered isBlurred={false} onMenuOpenChange={setIsMenuOpen}>
        <NavbarContent>
          <NavbarMenuToggle
            aria-label={isMenuOpen ? "Close menu" : "Open menu"}
            className="sm:hidden"
          />
          <NavbarBrand>
            <p className="font-bold text-inherit">Pielisen Betoni</p>
          </NavbarBrand>
        </NavbarContent>

        <NavbarContent className="hidden sm:flex gap-4" justify="center">
          {Object.entries(routes).map(([to, value]) =>
            value.navigationTitle && isVisible(value.roles) ? (
              <NavbarItem isActive>
                <Link color="foreground" key={to} to={to}>
                  {value.navigationTitle}
                </Link>
              </NavbarItem>
            ) : null
          )}
        </NavbarContent>
        <NavbarContent justify="end">
          <NavbarItem className="hidden lg:flex">
            <Link to="/auth">Login</Link>
          </NavbarItem>
          <NavbarItem>
            <Button as={Link} color="primary" href="#" variant="flat">
              Sign Up
            </Button>
          </NavbarItem>
        </NavbarContent>
        <NavbarMenu>
        {Object.entries(routes).map(([to, value]) =>
            value.navigationTitle && isVisible(value.roles) ? (
              <NavbarMenuItem key={to}>
                <Link color="foreground" className="w-full" key={to} to={to}>
                  {value.navigationTitle}
                </Link>
              </NavbarMenuItem>
            ) : null
          )}
        </NavbarMenu>
      </Navbar>
      <main>
        <Outlet />
      </main>
    </>
  );
};
*/

export default RootLayout;
