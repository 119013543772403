import { useMutation } from "@tanstack/react-query";
import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState
} from "react";
import { v4 as uuid } from "uuid";
import { queryClient } from "../../../App";
import { ErrorResponse } from "../../../custom-fetch";
import { useElementsService } from "../../../services/elements-service";
import { useProductionLinesService } from "../../../services/productionLines-service";
import { QueryKey } from "../../../services/query-keys";
import IElement from "../../../shared/IElement";
import { IProjectPhase } from "../../../shared/IProject";
import IWeeklyProductionRow from "../../../shared/IWeeklyProductionRow";
import { RefHandle } from "../../../shared/RefHandle";
import ErrorsAlert, { combineErrors } from "../../ErrorsAlert/ErrorsAlert";
import Button from "../../ui/Button/Button";
import { IOption, TInputValue } from "../../ui/Input/Input";
import InputContextProvider, { EInputUpdateAction } from "../../ui/Input/input-context";
import { formatInputValue } from "../../ui/Input/input-utils";
import { useConfirmModal } from "../../ui/Modal/useConfirmModal";
import Table from "../../ui/Table/Table";
import { useProjectEditContext } from "../ProjectEdit/project-edit-context";
import Element from "./Element";

interface IProps {
  phase?: IProjectPhase;
  phaseOptions: IOption[];
}

const createElement = (projectId: string, phase?: IProjectPhase): IElement => {
  const elementId = uuid();
  return {
    id: "",
    elementId,
    length: "",
    width: "",
    height: "",
    weightTons: "",
    name: "",
    productType: undefined,
    productionStatus: "",
    measurements: "",
    provisions: [],
    finishing: "",
    projectId,
    phaseId: phase?.phaseId ?? undefined,
    factory: undefined,
  };
};

const Elements: React.ForwardRefRenderFunction<RefHandle<IElement[]>, IProps> = ({ phase, phaseOptions }, ref) => {
  const { id: projectId, weeklyProductionRows, elements, setElements } = useProjectEditContext();

  const { saveMutate, isSaving, isSaveError, saveError } = useSave(projectId);
  const { deleteMutate, isDeleting, isDeletingError, deletingError } = useDelete(projectId);
  const { copyMutate, isCopying, isCopyError, copyError } = useCopy(projectId);
  const { createMutate, isCreating, isCreateError, createError } = useCreateProjectElements(projectId);
  const { createProductionLinesMutate, isCreatingProductionLine, isCreateProductionLineError, createProductionLineError } = useCreateProjectProductionLines(projectId);

  const [deletingId, setDeletingId] = useState<string | null>(null);
  const [copyingId, setCopyingId] = useState<string | null>(null);

  const openConfirmModal = useConfirmModal();

  const saveHandler = useCallback(async () => {
    const newElement = await saveMutate(createElement(projectId, phase));
    if (newElement) {
      setElements(elements => [...elements, newElement]);
    }
  }, [saveMutate, projectId, phase, setElements]);

  const createElementsHandler = useCallback(async () => {
    const isConfirm = await openConfirmModal("Oletko varma, että haluat luoda elementit?");
    if (isConfirm) {
      if (weeklyProductionRows.length > 0) {
        try {
          const newElements = await createMutate(weeklyProductionRows);
          if (newElements) {
            setElements([...newElements]);
          }
        } catch (e) {
          console.error(e);
        }
      }
    }
  }, [openConfirmModal, weeklyProductionRows, createMutate, setElements]);

  const deleteHandler = useCallback(async (id: string) => {
    const isConfirm = await openConfirmModal("Oletko varma, että haluat poistaa rivin?");
    if (isConfirm) {
      setDeletingId(id);
      const deleteSuccess = await deleteMutate(id);
      if (deleteSuccess) {
        setElements(elements => elements.filter(element => element.id !== id));
      }
      setDeletingId(null);
    }
  }, [deleteMutate, openConfirmModal, setElements]);

  const copyHandler = useCallback(async (element: IElement) => {
    setCopyingId(element.id);
    const newElement = await copyMutate(element);
    if (newElement) {
      setElements(elements => [...elements, newElement]);
    }
    setCopyingId(null);
  }, [copyMutate, setElements]);

  const updateHandler = useCallback(async (inputName: string, value: TInputValue, action: string, _: any) => {
    if (action === EInputUpdateAction.ELEMENTS) {
      const data = _ as IElement;
      const newElements = [...elements];
      const index = newElements.findIndex(r => r.id === data.id);
      if (index > -1) {
        newElements[index] = {
          ...data,
          [inputName]: formatInputValue(value),
        };
      }
      setElements(newElements);
    }
    return Promise.resolve(true);
  }, [elements, setElements]);

  const updateElementHandler = useCallback((newElement: IElement) => {
    setElements(elements => elements.map(element => element.id === newElement.id ? newElement : element))
  }, [setElements]);

  const elementRowsRef = useRef<RefHandle<IElement>[]>([]);

  const getData = useCallback(async () => {
    const rows = elementRowsRef.current;
    const newRows: IElement[] = [];
    for (let i = 0; i < rows.length; i++) {
      const row = rows[i];
      if (row) {
        const rowData = await row.getData();
        if (rowData) {
          newRows.push(rowData);
        }
      }
    }
    return newRows;
  }, []);

  const createProductionLineHandler = useCallback(async () => {
    const isConfirm = await openConfirmModal("Oletko varma, että haluat luoda pedit?");
    if (isConfirm) {
      const data = await getData();
      if (data) {
        const productionLineElements = await createProductionLinesMutate(data);
        if (productionLineElements) {
          setElements(elements => elements.map(element => productionLineElements.find(productionLineElement => productionLineElement.id === element.id) ?? element));
        }
      }
    }
  }, [openConfirmModal, getData, createProductionLinesMutate, setElements]);

  useImperativeHandle(ref, () => ({ getData }), [getData]);

  const errorMessages = combineErrors(
    { isError: isSaveError, error: saveError },
    { isError: isDeletingError, error: deletingError },
    { isError: isCopyError, error: copyError },
    { isError: isCreateError, error: createError },
    { isError: isCreateProductionLineError, error: createProductionLineError },
  );

  return (
    <>
      <Table>
        {elements && elements.length > 0 && (
          <thead>
            <tr>
              <th style={{ width: "80px" }}>Kerros / lohko</th>
              <th style={{ width: "125px" }}>Tyyppi</th>
              <th style={{ width: "125px" }}>Tunnus</th>
              <th style={{ width: "80px" }}>P</th>
              <th style={{ width: "80px" }}>O</th>
              <th style={{ width: "125px" }}>Pituus</th>
              <th style={{ width: "125px" }}>Halkaisija</th>
              <th style={{ width: "125px" }}>Korkeus</th>
              <th style={{ width: "80px" }}>Paino ton</th>
              <th style={{ width: "125px" }}>Varaukset</th>
              <th style={{ width: "125px" }}></th>
              <th style={{ width: "150px" }}>Karkea kuorma Vko</th>
              <th>→</th>
              <th style={{ width: "100px" }}>Toim</th>
              <th style={{ width: "80px" }}>Petinro</th>
              <th title="Pedin valu pvm" style={{ width: "80px" }}>Valu pvm</th>
              <th title="Pedin tehdas" style={{ width: "80px" }}>Tehdas</th>
              <th title="Varastopaikka" style={{ width: "100px" }}>Varasto</th>
              <th title="Rahtikirjan numero" style={{ width: "100px" }}>Rahtikirja</th>
              <th title="Toimituspäivä" style={{ width: "100px" }}>Toimitus</th>
              <th></th>
            </tr>
          </thead>
        )}
        <tbody>
          <InputContextProvider initDone onAutoUpdate={updateHandler}>
            {elements?.map((element, i) => (
              <Element
                key={element.id}
                element={element}
                ref={(rowHandle) => (elementRowsRef.current[i] = rowHandle!)}
                hidden={phase?.phaseId ? phase.phaseId !== element.phaseId : false}
                phaseOptions={phaseOptions}
                onDelete={() => deleteHandler(element.id)}
                isDeleting={isDeleting && deletingId === element.id}
                onCopy={() => copyHandler(element)}
                isCopying={isCopying && copyingId === element.id}
                onUpdate={updateElementHandler}
              />
            ))}
          </InputContextProvider>
          {/* <tr>
            <td style={{ borderTop: 0, width: "1rem" }}></td>
            <td colSpan={9}>
              <ProjectTabs phaseOptions={phaseOptions} />
            </td>
          </tr> */}
          <tr>
            <td colSpan={21}>
              <Button onClick={saveHandler} loading={isSaving}>Lisää uusi elementti</Button>
              {" "}
              {!elements?.length && <Button onClick={createElementsHandler} loading={isCreating}>Luo elementit tuotannon riveistä</Button>}
              {" "}
              {elements && elements.length > 0 && !elements.some(element => element.productionLineId) && <Button onClick={createProductionLineHandler} loading={isCreatingProductionLine}>Luo pedit elementeistä</Button>}
            </td>
          </tr>
        </tbody>
      </Table>
      {errorMessages.length > 0 && <ErrorsAlert errors={errorMessages} />}
    </>
  );
};

const useSave = (projectId: string) => {
  const { saveElement } = useElementsService();

  const {
    mutateAsync: saveMutate,
    isPending: isSaving,
    isError: isSaveError,
    error: saveError,
  } = useMutation<IElement, ErrorResponse, IElement>({
    mutationFn: (data) => saveElement(projectId, data),
    onSuccess: (data) => {
      const oldData = queryClient.getQueryData<IElement[]>([QueryKey.elements, projectId]) ?? [];
      queryClient.setQueryData([QueryKey.elements, projectId], [
        ...oldData,
        data,
      ]);
    },
  });

  return { saveMutate, isSaving, isSaveError, saveError };
};

const useDelete = (projectId: string) => {
  const { deleteElement } = useElementsService();

  const {
    mutateAsync: deleteMutate,
    isPending: isDeleting,
    isError: isDeletingError,
    error: deletingError,
  } = useMutation<boolean, ErrorResponse, string>({
    mutationFn: (id) => deleteElement(id),
    onSuccess: (_, id) => {
      const oldData = queryClient.getQueryData<IElement[]>([QueryKey.elements, projectId]) ?? [];
      queryClient.setQueryData([QueryKey.elements, projectId], oldData.filter(row => row.id !== id));
    },
  });

  return { deleteMutate, isDeleting, isDeletingError, deletingError };
};

const useCopy = (projectId: string) => {
  const { copyElement } = useElementsService();

  const {
    mutateAsync: copyMutate,
    isPending: isCopying,
    isError: isCopyError,
    error: copyError,
  } = useMutation<IElement, ErrorResponse, IElement>({
    mutationFn: (data) => copyElement(data),
    onSuccess: (data) => {
      const oldData = queryClient.getQueryData<IElement[]>([QueryKey.elements, projectId]) ?? [];
      queryClient.setQueryData([QueryKey.elements, projectId], [
        ...oldData,
        data,
      ]);
    },
  });

  return { copyMutate, isCopying, isCopyError, copyError };
};

const useCreateProjectElements = (projectId: string) => {
  const { createProjectElements } = useElementsService();

  const {
    mutateAsync: createMutate,
    isPending: isCreating,
    isError: isCreateError,
    error: createError,
  } = useMutation<IElement[], ErrorResponse, IWeeklyProductionRow[]>({
    mutationFn: (data) => createProjectElements(projectId, data),
    onSuccess: (data) => {
      const oldData = queryClient.getQueryData<IElement[]>([QueryKey.elements, projectId]) ?? [];
      queryClient.setQueryData([QueryKey.elements, projectId], [
        ...oldData,
        ...data,
      ]);
    },
  });

  return { createMutate, isCreating, isCreateError, createError };
};

const useCreateProjectProductionLines = (projectId: string) => {
  const { createProductionLines } = useProductionLinesService();

  const {
    mutateAsync: createProductionLinesMutate,
    isPending: isCreatingProductionLine,
    isError: isCreateProductionLineError,
    error: createProductionLineError,
  } = useMutation<IElement[], ErrorResponse, IElement[]>({
    mutationFn: (data) => createProductionLines(projectId, data),
    onSuccess: (elements) => {
      // const oldData = queryClient.getQueryData<IElement[]>([QueryKey.elements, projectId]) ?? [];
      // queryClient.setQueryData([QueryKey.elements, projectId], [...oldData.map(element => ({...element, productionLineId }))]);
      // queryClient.invalidateQueries({ queryKey: [QueryKey.elements, projectId] });
      queryClient.setQueryData([QueryKey.elements, projectId], elements);
    },
  });

  return { createProductionLinesMutate, isCreatingProductionLine, isCreateProductionLineError, createProductionLineError };
};

export default forwardRef(Elements);
