import Container from "../components/ui/Container/Container";
import { useNavigate } from "react-router-dom";
import { Route } from "../routes";
import PageHeading from "../components/ui/PageHeading/PageHeading";
import SlabTypesList from "../components/SlabTypes/SlabTypesList";

const SlabTypesPage: React.FC = () => {
  const navigate = useNavigate();

  const addHandler = () => {
    navigate(Route.slabType("add"));
  }

  return (
    <>
      <PageHeading onAdd={addHandler}>Laatat</PageHeading>
      <Container>
        <SlabTypesList />
      </Container>
    </>
  );
};

export default SlabTypesPage;
