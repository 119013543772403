import { useCallback, useMemo, useState } from "react";
import IProject, { IProjectPhase } from "../../../shared/IProject";
import { useProjectPhaseEditModal } from "../../ProjectPhaseEditModal/useProjectPhaseEditModal";
import { IOption } from "../../ui/Input/Input";
import { EModalId, useModalContext } from "../../ui/Modal/modal-context";
import PageHeading from "../../ui/PageHeading/PageHeading";
import RadioButton from "../../ui/RadioButton/RadioButton";
import ProjectTabs from "../ProjectTabs/ProjectTabs";
import { queryClient } from "../../../App";
import { QueryKey } from "../../../services/query-keys";

interface IProps {
  id: string;
  initialPhases: IProjectPhase[];
}

const allPhaseOption: IOption = { value: "", label: "Kaikki" };

const ProjectPhases: React.FC<IProps> = ({ id, initialPhases }) => {
  const [phases, setPhases] = useState<IProjectPhase[]>(initialPhases ?? []);
  const [selectedPhase, setSelectedPhase] = useState<string>("");

  const { closeModal } = useModalContext();
  const openEditModal = useProjectPhaseEditModal(id);

  const editHandler = useCallback(async () => {
    const data = await openEditModal();
    if (data) {
      setPhases((phases) => [...phases, data]);
      // FIXME: query does not update automatically when saving
      const oldData = queryClient.getQueryData<IProject>([QueryKey.projects, id]);
      queryClient.setQueryData([QueryKey.projects, id], { ...oldData, phases: [...oldData?.phases ?? [], data] });
    }
    closeModal(EModalId.PROJECT_PHASE_EDIT);
  }, [openEditModal, closeModal, id]);

  let phaseOptions: IOption[] = useMemo(
    () =>
      phases?.map((phase) => ({
        value: phase.phaseId,
        label: phase.name,
      })) ?? [],
    [phases]
  );

  return (
    <>
      <PageHeading onAdd={editHandler} variant="h3" style={{ marginLeft: "0" }}>
        Kerrokset ja lohkot
      </PageHeading>
      <RadioButton
        onChange={(value) => setSelectedPhase(value)}
        options={[allPhaseOption, ...phaseOptions]}
        value={selectedPhase}
      />
      <hr />
      <ProjectTabs
        phaseOptions={phaseOptions}
        phases={phases}
        selectedPhase={selectedPhase}
      />
    </>
  );
};

export default ProjectPhases;
