import { useCallback } from 'react';
import { ISignal, useCustomFetch } from "../custom-fetch";
import ISlabType from '../shared/ISlabType';

interface IGetSlabType extends ISignal {
  id: string;
}

export const useSlabTypeService = () => {
  const customFetch = useCustomFetch();

  const fetchSlabTypes = useCallback(async ({ signal }: ISignal, archived?: boolean) => {
    const [slabTypes] = await customFetch<ISlabType[]>(`/api/slab-types/list${archived !== undefined ? `?archived=${archived}`:""}`, { signal });
    return slabTypes;
  }, [customFetch]);
  
  const getSlabType = useCallback(async ({ signal, id }: IGetSlabType) => {
    const [slabType] = await customFetch<ISlabType>("/api/slab-types/get/" + id, { signal });
    return slabType;
  }, [customFetch]);
  
  const saveSlabType = useCallback(async (data: ISlabType) => {
    const [slabType] = await customFetch<ISlabType>("/api/slab-types/add", {
      method: "POST",
      body: JSON.stringify(data),
    });
    return slabType;
  }, [customFetch]);
  
  const updateSlabType = useCallback(async (id: string, data: ISlabType) => {
    const [slabType] = await customFetch<ISlabType>("/api/slab-types/update/" + id, {
      method: "PUT",
      body: JSON.stringify(data),
    });
    return slabType;
  }, [customFetch]);
  
  const deleteSlabType = useCallback(async (id: string) => {
    await customFetch<ISlabType>("/api/slab-types/delete/" + id, {
      method: "DELETE"
    });
    return true;
  }, [customFetch]);
  
  return { fetchSlabTypes, getSlabType, saveSlabType, updateSlabType, deleteSlabType};
}