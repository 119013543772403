import { useCallback } from 'react';
import { ISignal, useCustomFetch } from "../custom-fetch";
import ISteelPart from '../shared/ISteelPart';

interface IGetSteelPart extends ISignal {
  id: string;
}

export const useSteelPartsService = () => {
  const customFetch = useCustomFetch();

  const fetchSteelParts = useCallback(async ({ signal }: ISignal, archived?: boolean) => {
    const [steelParts] = await customFetch<ISteelPart[]>(`/api/steel-parts/list${archived !== undefined ? `?archived=${archived}`:""}`, { signal });
    return steelParts;
  }, [customFetch]);
  
  const getSteelPart = useCallback(async ({ signal, id }: IGetSteelPart) => {
    const [steelPart] = await customFetch<ISteelPart>("/api/steel-parts/get/" + id, { signal });
    return steelPart;
  }, [customFetch]);
  
  const saveSteelPart = useCallback(async (data: ISteelPart) => {
    const [steelPart] = await customFetch<ISteelPart>("/api/steel-parts/add", {
      method: "POST",
      body: JSON.stringify(data),
    });
    return steelPart;
  }, [customFetch]);
  
  const updateSteelPart = useCallback(async (id: string, data: ISteelPart) => {
    const [steelPart] = await customFetch<ISteelPart>("/api/steel-parts/update/" + id, {
      method: "PUT",
      body: JSON.stringify(data),
    });
    return steelPart;
  }, [customFetch]);
  
  const deleteSteelPart = useCallback(async (id: string) => {
    await customFetch<ISteelPart>("/api/steel-parts/delete/" + id, {
      method: "DELETE"
    });
    return true;
  }, [customFetch]);
  
  return { fetchSteelParts, getSteelPart, saveSteelPart, updateSteelPart, deleteSteelPart };
}
