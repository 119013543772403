import { useQuery } from "@tanstack/react-query";

import { ErrorResponse } from "../../custom-fetch";
import { QueryKey } from "../../services/query-keys";
import IProductType from "../../shared/IProductType";
import ErrorsAlert from "../ErrorsAlert/ErrorsAlert";
import Spinner from "../ui/Spinner/Spinner";
import { useState } from "react";
import Input, { EInputType } from "../ui/Input/Input";
import { useProductTypeService } from "../../services/productTypes-service";
import Table from "../ui/Table/Table";
import { Route } from "../../routes";
import { useNavigate } from "react-router-dom";

const ProductTypesList: React.FC = () => {
  const { fetchProductTypes } = useProductTypeService();
  const [filterValue, setFilterValue] = useState<string>('');
  const navigate = useNavigate();

  const openHandler = (id: string) => {
    navigate(Route.productType(id));
  };

  const {
    data: productTypes,
    isPending,
    isError,
    error,
  } = useQuery<IProductType[], ErrorResponse>({
    queryKey: [QueryKey.productTypes],
    queryFn: fetchProductTypes,
    staleTime: 5000,
  });

  if (isError) {
    return <ErrorsAlert errors={error.messages} />;
  }

  if (isPending) {
    return <Spinner />;
  }

  if (!productTypes || productTypes.length === 0) {
    return <p>Ei tuotteita</p>;
  }

  const filteredProductTypes = productTypes.filter(
    (productType) =>
      productType.name?.toLowerCase().includes(filterValue) || 
      productType.productGroup?.name.toLowerCase().includes(filterValue)
  );

  return (
    <>
      <Input
        inputName="productTypesFilter"
        type={EInputType.text}
        placeholder="Suodata nimellä tai tuoteryhmällä"
        value={filterValue}
        onChange={(value) => setFilterValue(value.toString().toLowerCase())}
        autoFocus={true}
      />
      <Table hover>
        <thead>
          <tr>
            <th>Nimi</th>
            <th>Tuoteryhmä</th>
            <th>Hinta</th>
            <th>Arkistoitu</th>
          </tr>
        </thead>
        <tbody>
          {filteredProductTypes.map((productType) => (
            <tr
              key={productType.id}
              onClick={() => openHandler(productType.id)}
              style={{
                color: productType.archived ? "gray" : "inherit",
                fontStyle: productType.archived ? "italic" : "inherit",
              }}
            >
              <td>{productType.name}</td>
              <td>{productType.productGroup.name}</td>
              <td>{productType.price ? `${productType.price} € ` : ''} {productType.unitString ? `/ ${productType.unitString}` : ''}</td>
              <td>{productType.archived && "arkistoitu"}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default ProductTypesList;
