import { useQuery } from "@tanstack/react-query";

import { ErrorResponse } from "../../custom-fetch";
import { QueryKey } from "../../services/query-keys";
import IConcreteType from "../../shared/IConcreteType";
import ErrorsAlert from "../ErrorsAlert/ErrorsAlert";
import Spinner from "../ui/Spinner/Spinner";
import { useState } from "react";
import Input, { EInputType } from "../ui/Input/Input";
import ConcreteTypesTable from "./ConcreteTypesTable";
import { useConcreteTypeService } from "../../services/concreteTypes-service";

const ConcreteTypesList: React.FC = () => {
  const { fetchConcreteTypes } = useConcreteTypeService();
  const [filterValue, setFilterValue] = useState<string>('');

  const {
    data: concreteTypes,
    isPending,
    isError,
    error,
  } = useQuery<IConcreteType[], ErrorResponse>({
    queryKey: [QueryKey.concreteTypes],
    queryFn: fetchConcreteTypes,
    staleTime: 5000,
  });

  if (isError) {
    return <ErrorsAlert errors={error.messages} />;
  }

  if (isPending) {
    return <Spinner />;
  }

  if (!concreteTypes || concreteTypes.length === 0) {
    return <p>Ei massatyyppejä</p>;
  }

  const filteredConcreteTypes = concreteTypes.filter(
    (concreteType) =>
      concreteType.unitName.toLowerCase().includes(filterValue)
  );

  return (
    <>
    <Input
      inputName="concreteTypesFilter"
      type={EInputType.text}
      placeholder="Suodata tunnuksella"
      value={filterValue}
      onChange={(value) => setFilterValue(value.toString().toLowerCase())}
      autoFocus={true}
    />
    <ConcreteTypesTable concreteTypes={filteredConcreteTypes}/>
    </>
  );
};

export default ConcreteTypesList;
